<div class="cont">

    <h1> {{title}} </h1>


    <mat-card class="panierverif" *ngIf="commande">
        <mat-card-header>
            <mat-card-title>Votre commande N° {{commande.id}} <br> </mat-card-title>
            <mat-card-subtitle>Les prix des produits pesés seront ajustés à la preparation de la commande.
            </mat-card-subtitle>
        </mat-card-header>
        <mat-card-content><div class="etat">État : {{etat(commande.etat!).message}}  <mat-icon>{{etat(commande.etat!).matIcon}}</mat-icon></div>
            <table mat-table *ngIf="panier.produits.length" [dataSource]="panier.produits"
                class="mat-elevation-z3 panier-table">
                <ng-container matColumnDef="ref">
                    <th mat-header-cell *matHeaderCellDef> Ref. </th>
                    <td mat-cell *matCellDef="let element"> {{element.ref}} </td>

                </ng-container>

                <ng-container matColumnDef="produit">
                    <th mat-header-cell *matHeaderCellDef> Produit </th>
                    <td mat-cell *matCellDef="let element"> <a
                            [routerLink]="['/',config.lienSpec.CATALOGUE, 'fiche',element.ref,element.url]">{{element.nom}}
                        </a></td>

                </ng-container>



                <ng-container matColumnDef="puttc">
                    <th mat-header-cell *matHeaderCellDef> P.U </th>
                    <td mat-cell *matCellDef="let element"> {{element.prixTTC |currency}} </td>

                </ng-container>
                <ng-container matColumnDef="attributs">
                    <th mat-header-cell *matHeaderCellDef> Choix </th>
                    <td mat-cell *matCellDef="let element">
                        <div *ngFor="let attribut of element.attributs">{{attribut.choix}}<span class="plusvalue"
                                *ngIf="attribut.plusvalue>0">+ {{attribut.plusvalue |currency}}</span></div>
                    </td>

                </ng-container>
                <ng-container matColumnDef="nbr">
                    <th mat-header-cell *matHeaderCellDef> Nbr. </th>
                    <td mat-cell *matCellDef="let element">
                        <div>{{element.nbr}} <span class="unite">({{element.unite}})</span></div>
                    </td>

                </ng-container>
                <ng-container matColumnDef="pttc">
                    <th mat-header-cell *matHeaderCellDef> Total </th>
                    <td mat-cell *matCellDef="let element"> {{element.prixTotalTTC |currency}} </td>

                </ng-container>
                <ng-container matColumnDef="disclaimer">
                    <td mat-footer-cell *matFooterCellDef colspan="6" class="total">
                        <div [class.barre]="panier.codepromo?.valeurPanier"> Total Commande: {{getTotalCost() |
                            currency}}</div>
                        <div *ngIf="panier.codepromo?.valeurPanier">Code Promo "{{panier.codepromo?.code!}}"
                            <span class="mintxt">{{panier.codepromo?.description!}}</span>
                            (-{{panier.codepromo?.valeurPanier |currency}})
                            Total Commande : {{getTotalCost()-panier.codepromo?.valeurPanier! | currency}}
                        </div>
                        <div *ngIf="panier.fidelite?.valeur">Solde Fidélité
                            (-{{panier.fidelite?.valeur |currency}})
                             Total Commande : {{panier.codepromo?.valeurPanier?getTotalCost()-panier.codepromo?.valeurPanier!-panier.fidelite?.valeur!:getTotalCost()-panier.fidelite?.valeur! | currency}}</div>
                    </td>
                    <td mat-footer-cell *matFooterCellDef> </td>
                </ng-container>


                <tr mat-header-row *matHeaderRowDef="displayedColumns" class="monheader"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;" class="mestd"></tr>
                <tr mat-footer-row *matFooterRowDef="['disclaimer']"></tr>
            </table>

        </mat-card-content>
        <mat-card-actions><button mat-raised-button color="accent" [routerLink]="['/',config.lienSpec.CATALOGUE,'client','moncompte']">Retour</button></mat-card-actions>

    </mat-card>


</div>