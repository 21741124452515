/* export const ROUTES: string[] = [
  '/',
  '/page/:url?',
  '/infos/:url?',
  '/articles',
  '/article/:url?',
  '/date/:date/:titre?',
  'reservation',
  '/admin/:action?/:id?',
  '/login',
  '/explore',
]; */
const DOMAINE = 'saveursdenosterroirs.fr';
export const CONFIG: Config = {
  DOMAINE: DOMAINE,
  applicationUrl: `https://www.${DOMAINE}` /* `http://${DOMAINE}:4200` */,
  port: 8010,
  urlApi: `https://www.${DOMAINE}/d4api` /* `http://${DOMAINE}:3000` */,
  imageUrl: `https://www.${DOMAINE}/images/logo.png` /*'/images/logo.png'  */,
  tailleImages_max: { catalogue: 800, pages: 450, blog: 450 },
  headerUrl: '/media/files/header.jpg',
  headerMobUrl: '/media/files/headermob.png',
  title: 'Saveurs de nos Terroirs',
  description: "Depuis 1993, les produits régionaux sont mis à l'honneur dans chacun de nos rayons",
  og: {
    type: 'website',
    locale: 'fr_FR',
  },
  limites: {
    sections: 100,
    carroussel: 12,
    articleHome: 5 /** Nombre d'articles sur la page d'accueil */,
    articleJournal: 20 /** Nombre d'articles sur la page journal (par tranches de ) */,
    expireLoginClient:3600*24,/** Temps en secondes du maintient de connexion client */
  },
  lienSpec: {
    HOME: 'HOME',
    PAGE: 'PAGE',
    CONTACT: 'CONTACT',
    AGENDA: 'agenda',
    BLOG: 'articles',
    CATALOGUE: 'catalogue',
    FORMULAIRE: '',
    SPEC: '',
  },
  GTAGKEY: 'G-VWKNNLES47', //
  PASSPHR:'d4propulse123456',//16 caracteres
  modules: ['journal', 'catalogue', 'emails', 'promos', 'clients', 'commandes'],
  LISTROLES:["d4","admin","user"],
  DROITS: {
    d4: [
      'dashboard',
      'config',
      'pages',
      'journal',
      'agenda',
      'catalogue',
      'emails',
      'promos',
      'utilisateurs',
      'clients',
      'commandes',
    ],
    admin: [
      'dashboard',
      'config',
      'pages',
      'journal',
      'agenda',
      'catalogue',
      'emails',
      'promos',
      'utilisateurs',
      'clients',
      'commandes',
    ],
    user: ['journal'],
  },
  MAILVARIABLES: [
    {
      variable: '!!NOM!!',
      def: 'Nom du Client',
      exemple: 'Dupont',
    },
    {
      variable: '!!NUMCOMMANDE!!',
      def: 'Numéro de la commande',
      exemple: '123456-78',
    },
    {
      variable: '!!PRIX!!',
      def: 'Prix de la commande',
      exemple: '25,80',
    },
    {
      variable: '!!NUMCASIER!!',
      def: 'Numéro de(s) Casier(s)',
      exemple: '314',
    },
    {
      variable: '!!DATEDEPOT!!',
      def: 'Date de mise à disposition',
      exemple: 'Le 18/12/2021 à 18h00',
    },
    {
      variable: '!!LIENPASS!!',
      def: 'Lien de modification du mot de passe Client',
      exemple:
        '<a href="https://www.saveursdenosterroirs.fr/clients/recuppass/6sd6sg23n0gvtprg2foqzs">Modifiez votre mot de passe</a>',
    },
  ],
  MAILPRESUJET:'Saveurs de nos terroirs - ',
  MAILFROM:'noreply@server.digitalatelier.fr',
  refresh:(60*1000),
};
export interface Config {
  [key: string]: any;
  DOMAINE: string;
  applicationUrl: string;
  port: number;
  urlApi: string;
  imageUrl?: string;
  tailleImages_max: TailleImage_Max;
  headerUrl?: string;
  headerMobUrl?: string;
  title: string;
  description: string;
  og: Og;
  limites: Limites;
  lienSpec: LienSpec;
  GTAGKEY: string;
  PASSPHR:string;
  modules: Array<string>;
  LISTROLES: Array<string>;
  DROITS: DROITS;
  MAILVARIABLES: MAILVARIABLES[];
  MAILPRESUJET:string;
  MAILFROM:string;//adresse email utilisée pour envoyer les mails
  refresh:number;
}
interface Og {
  type: string;
  locale: string;
}
interface Limites {
  sections: number;
  carroussel: number;
  articleHome: number;
  articleJournal: number;
  expireLoginClient: number;
}
interface LienSpec {
  [key: string]: any;
  HOME: string;
  PAGE: string;
  CONTACT: string;
  AGENDA: string;
  BLOG: string;
  MENUS?: string;
  CATALOGUE: string;
  FORMULAIRE: string;
  SPEC: string;
}
interface DROITS {
  [key: string]: any;
  d4: Array<string>;
  admin: Array<string>;
  user: Array<string>;
}
interface TailleImage_Max {
  [key: string]: any;
}
export interface MAILVARIABLES {
  [key: string]: any;
  variable: string;
  def: string;
  exemple: string;
}
