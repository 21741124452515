<div class="cont"  *ngIf="page?.html">

  <h1>{{page?.h1}}</h1>

  <div class="dyn" [innerHtml]="page?.html | unsafe:'html'"> </div>

  <!-- ICI CONTACT FORM -->
  <div *ngIf="page?.type=='CONTACT'" class="contact" fxLayout="row wrap" fxLayout.xs="column" fxLayoutWrap fxLayoutGap="10px"
  fxLayoutAlign="center">
    <form [formGroup]="mailForm" (ngSubmit)="envoiMail();">

      <mat-form-field class="full-width" appearance="outline">
        <mat-label for="mail">Votre Adresse Email</mat-label>
        <input id="mail" matInput type="mail" formControlName="mail" placeholder="Adresse Email">
      </mat-form-field>
      <mat-form-field class="full-width" appearance="outline">
        <mat-label for="message">Votre Message</mat-label>
        <textarea rows="5" cols="30" id="message" matInput type="textarea" formControlName="message"
          placeholder="Votre Message ... Les caractères spéciaux ne sont pas acceptés."></textarea>
      </mat-form-field>
      <div style="text-align:end">
        <div *ngIf="!mailForm.valid" class="text-danger"
          style="font-size:small;white-space:normal;width:100%;text-align:center">Vérifiez
          que vous avez bien saisi votre adresse Email et que votre message ne contient pas
          de caractères spéciaux !</div>
        <button *ngIf="mailForm.valid" mat-raised-button [disabled]="!mailForm.valid" type="submit">Envoyer</button>
      </div>

    </form>


  </div>

</div>