import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import {
  AbstractControl,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { AuthenticationService } from '../../../services';
import { Title } from '@angular/platform-browser';
import { User, Client } from 'src/api/models';
import { HttpHeaders } from '@angular/common/http';
import {
  ClientControllerService,
  MailControllerService,
  MailTemplateControllerService,
} from 'src/api/services';
import { CONFIG, Config } from 'config';
import { MustMatch } from 'src/app/shared/must-match.validator';
import * as _ from 'lodash-es';
import * as moment from 'moment';
import { MatDialog } from '@angular/material/dialog';
import { DialogRecupPassComponent } from './dialog-recup-pass.component';
import { filter } from 'compression';
import * as CryptoJS from 'crypto-js';
import { MailTemplate } from 'src/api/models';
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['../../styles.scss', './login.component.scss'],
})
export class CLientLoginComponent implements OnInit {
  config: Config = CONFIG;
  loginForm!: FormGroup;
  signinForm!: FormGroup;
  error: string = '';
  errorSignin: string = '';
  loading = false;
  returnUrl: string = '';
  hide = true;
  pageTitre: string = 'Espace Client ' + this.config.title;
  tokenFromUI: string = this.config.PASSPHR;
  CGV = new FormControl(false);
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private formBuilder: FormBuilder,
    private authenticationService: AuthenticationService,
    private clientsService: ClientControllerService,
    private mailService: MailControllerService,
    private mailTemplateService: MailTemplateControllerService,
    private titre: Title,
    public dialog: MatDialog
  ) {}

  ngOnInit(): void {
    //this.pageTitre="";
    this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';
    this.loginForm = this.formBuilder.group({
      email: ['', [Validators.required, Validators.email]],
      password: ['', [Validators.required, Validators.minLength(8)]],
    });
    this.signinForm = this.formBuilder.group(
      {
        adresse: ['', Validators.required],
        adresse2: [''],
        cp: [
          '',
          [
            Validators.required,
            Validators.pattern(/^(?:[0-8]\d|9[0-8])\d{3}$/),
          ],
        ],
        emailsign: [
          '',
          Validators.compose([Validators.required, Validators.email]),
        ],
        passwordsign: [
          '',
          Validators.compose([Validators.required, Validators.minLength(8)]),
        ],
        password2: ['', Validators.required],
        nom: ['', Validators.required],
        prenom: ['', Validators.required],
        tel: [
          '',
          [
            Validators.required,
            Validators.pattern(
              /^(?:(?:\+|00)33[\s.-]{0,3}(?:\(0\)[\s.-]{0,3})?|0)[1-9](?:(?:[\s.-]?\d{2}){4}|\d{2}(?:[\s.-]?\d{3}){2})$/
            ),
          ],
        ],
        ville: [],
      },
      {
        validator: MustMatch('passwordsign', 'password2'),
      }
    );
    this.error = '';
    this.titre.setTitle(this.pageTitre);
  }
  getErrorMessage(formControl: string) {
    let message = '';
    switch (formControl) {
      case 'email':
        if (this.loginForm.controls.email.hasError('required')) {
          message =
            "Vous devez saisir l'adresse Email avec laquelle vous vous êtes inscrit";
        }
        if (this.loginForm.controls.email.hasError('email')) {
          message = 'Veuillez saisir une adresse Email valide';
        }
        return message;
      case 'password':
        if (this.loginForm.controls.password.hasError('minlength')) {
          message = 'Votre mot de passe doit contenir au moins 8 caractères';
        }
        if (this.loginForm.controls.password.hasError('required')) {
          message = 'Saisissez votre mot de passe';
        }
        return message;
      default:
        return message;
    }
  }
  login() {
    if (this.loginForm.valid) {
      this.authenticationService
        .loginClient(this.loginForm.value.email, this.loginForm.value.password)
        .subscribe(
          (user) => {
            if (user && user.token) {
              localStorage.setItem('clientUser', JSON.stringify(user));
              this.router.navigate([this.returnUrl]);
            } else {
              this.error =
                "Connexion Impossible : Vérifiez votre Nom d'utilisateur et votre Mot de Passe !";
            }
          },
          (error) => {
            this.error =
              "Connexion Impossible : Vérifiez votre Nom d'utilisateur et votre Mot de Passe !";
          }
        );
    }
  }
  razmdp() {
    const dialogRef = this.dialog.open(DialogRecupPassComponent, {
      width: '350px',
      data: { mail: '' },
    });

    dialogRef.afterClosed().subscribe((result) => {
      const emailRegex = new RegExp(
        `([a-zA-Z0-9_.]{1}[a-zA-Z0-9_.]*)((@[a-zA-Z0-9-]{2}[a-zA-Z0-9-]*)[\\\.]([a-zA-Z]{2}|[a-zA-Z]{3}))`
      );
      if (emailRegex.test(result)) {
        this.clientsService
          .recupMail({ email: `${result}` })
          .subscribe((code: string | null) => {
            let strreplace = '!!LIENPASS!!';
            if (code !== null) {
              this.mailTemplateService
                .find({
                  filter: { filter: `{"where":{"identifiant":"1333"}}` },
                })
                .subscribe((mailT: MailTemplate[]) => {
                  code = encodeURIComponent(this.encryptUsingAES256(code!));
                  let html = mailT[0].html.replace(
                    strreplace,
                    `<a href="${this.config.applicationUrl}/${this.config.lienSpec.CATALOGUE}/client/recuppass/${code}">Modifiez votre mot de passe</a>`
                  );

                  this.mailService.mail({
                    body: {
                      to: result,
                      from: this.config.MAILFROM,
                      html: html,
                      subject: `Recupération de votre mot de passe sur ${this.config.DOMAINE}`,
                    },
                  }).subscribe(obs=>{
                    console.log(obs)
                  });
                });
              // dans la page modPass, this.decryptUsingAES256(decodeURIComponent(code));
            }
          });
      }
    });
  }

  getErrorSigninMessage(formControl: string) {
    let message = '';
    switch (formControl) {
      case 'email':
        if (this.signinForm.controls.emailsign.hasError('required')) {
          message = 'Vous devez saisir une adresse Email';
        }
        if (this.signinForm.controls.emailsign.hasError('email')) {
          message = 'Veuillez saisir une adresse Email valide';
        }
        return message;
      case 'passwordsign':
        if (this.signinForm.controls.passwordsign.hasError('minlength')) {
          message = 'Votre mot de passe doit contenir au moins 8 caractères';
        }
        if (this.signinForm.controls.passwordsign.hasError('required')) {
          message = 'Saisissez votre mot de passe';
        }
        return message;
      case 'nom':
        if (this.signinForm.controls.nom.hasError('required')) {
          message = 'Vous devez saisir votre nom';
        }
        return message;
      case 'prenom':
        if (this.signinForm.controls.prenom.hasError('required')) {
          message = 'Vous devez saisir votre prénom';
        }
        return message;
      case 'adresse':
        if (this.signinForm.controls.adresse.hasError('required')) {
          message = 'Vous devez saisir votre adresse';
        }
        return message;
      case 'cp':
        if (this.signinForm.controls.cp.hasError('required')) {
          message = 'Vous devez saisir votre code postal';
        }
        if (this.signinForm.controls.cp.hasError('pattern')) {
          message = 'Le code postal saisi ne semble pas valide. Ex:62121';
        }
        return message;
      case 'ville':
        if (this.signinForm.controls.ville.hasError('required')) {
          message = 'Vous devez saisir votre ville';
        }
        return message;
      case 'tel':
        if (this.signinForm.controls.tel.hasError('required')) {
          message = 'Vous devez saisir un numéro de téléphone';
        }
        if (this.signinForm.controls.tel.hasError('pattern')) {
          message = 'Le numéro saisi ne semble pas valide. Ex:0312345678';
        }
        return message;
      default:
        return message;
    }
  }
  newClient() {
    if (this.signinForm.valid) {
      this.clientsService
        .create({
          body: {
            email: this.signinForm.value.emailsign,
            password: this.signinForm.value.passwordsign,
            nom: this.signinForm.value.nom,
            prenom: this.signinForm.value.prenom,
            adresse: this.signinForm.value.adresse,
            adresse2: this.signinForm.value.adresse2,
            cp: this.signinForm.value.cp.toString(),
            ville: this.signinForm.value.ville,
            tel: this.signinForm.value.tel,
            recupCode: '',
            date:moment().utc().format()
          },
        })
        .subscribe((out) => {
          if (out.id) {
            this.authenticationService
              .loginClient(
                this.signinForm.value.emailsign,
                this.signinForm.value.passwordsign
              )
              .subscribe(
                (user) => {
                  console.log(user);
                  if (user && user.token) {
                    localStorage.setItem('clientUser', JSON.stringify(user));
                    this.router.navigate([this.returnUrl]);
                  } else {
                    this.errorSignin =
                      "Connexion Impossible : Vérifiez votre Nom d'utilisateur et votre Mot de Passe !";
                  }
                },
                (error) => {
                  this.errorSignin =
                    "Connexion Impossible : Vérifiez votre Nom d'utilisateur et votre Mot de Passe !";
                }
              );
          }
          //login
        });
      //_.omit()
    }
  }
  encryptUsingAES256(chaine: string) {
    let _key = CryptoJS.enc.Utf8.parse(this.tokenFromUI);
    let _iv = CryptoJS.enc.Utf8.parse(this.tokenFromUI);
    let encrypted = CryptoJS.AES.encrypt(JSON.stringify(chaine), _key, {
      keySize: this.tokenFromUI.length,
      iv: _iv,
      mode: CryptoJS.mode.ECB,
      padding: CryptoJS.pad.Pkcs7,
    });
    return encrypted.toString();
  }
}
