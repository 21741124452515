import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { CatalogueComponent } from './pages/catalogue/catalogue.component';
import { HomeComponent } from './pages/home.component';
import { ArticleComponent } from './pages/journal/article.component';
import { JournalComponent } from './pages/journal/journal.component';
import { PageItemViewComponent } from './pages/page-item-view.component';
import { PageSpecComponent } from './pages/page-spec.component';
import { PagesComponent } from './pages/pages.component';
import { Config, CONFIG } from '../../config';
import { LoginComponent } from './login/login.component';
import { AuthGuard, ClientGuard } from './guards';
import { ExploreComponent } from './+admin/explore.component';
import { ProduitComponent } from './pages/catalogue/produit.component';
import { CategorieComponent } from './pages/catalogue/categorie.component';
import { PanierComponent } from './pages/catalogue/panier.component';
import { VerifComponent } from './pages/catalogue/commandes/verif.component';
import { CLientLoginComponent } from './pages/catalogue/clients/login.component';
import { RecupPassComponent } from './pages/catalogue/clients/recup-pass.component';
import { MoncompteComponent } from './pages/catalogue/clients/moncompte.component';
import { ModComponent } from './pages/catalogue/clients/mod.component';
import { ValidComponent } from './pages/catalogue/commandes/valid.component';
import { ConsultComponent } from './pages/catalogue/commandes/consult.component';
const config: Config = CONFIG;

const routes: Routes = [
  {
    path: '',
    component: PagesComponent,
    children: [
      { path: 'page/:url', component: PageItemViewComponent },
      { path: '', component: HomeComponent, pathMatch: 'full' },
      { path: 'infos/:url', component: PageSpecComponent },
      { path: 'articles', component: JournalComponent, pathMatch: 'full' },
      { path: 'article/:url', component: ArticleComponent },
      {
        path: config.lienSpec.CATALOGUE,
        component: CatalogueComponent,
        pathMatch: 'full',
      },{
        path: config.lienSpec.CATALOGUE+ '/page/:nbr',
        component: CatalogueComponent,
        pathMatch: 'full',
      },
      {
        path: config.lienSpec.CATALOGUE + '/fiche/:ref/:url',
        component: ProduitComponent,
        pathMatch: 'full',
      },
      {
        path: config.lienSpec.CATALOGUE + '/categorie/:menu',
        component: CategorieComponent,
        pathMatch: 'full',
      },
      {
        path: config.lienSpec.CATALOGUE + '/panier',
        component: PanierComponent,
        pathMatch: 'full',
      },
      {
        path: config.lienSpec.CATALOGUE + '/commande',
        children: [
          {
            path: 'verif',
            component: VerifComponent,
            pathMatch: 'full',
          },
          {
            path: 'valid/:idcommande',
            component: ValidComponent,
            pathMatch: 'full',
          },
          {
            path: 'consult/:idcommande',
            component: ConsultComponent,
            pathMatch: 'full',
          },

        ],
        canActivate: [ClientGuard],
      },
      {
        path: config.lienSpec.CATALOGUE + '/client/login',
        component: CLientLoginComponent,
      },
      {
        path: config.lienSpec.CATALOGUE + '/client/recuppass/:code',
        component: RecupPassComponent,
      },
      {
        path: config.lienSpec.CATALOGUE + '/client',
        children: [
          {
            path: 'moncompte',
            component: MoncompteComponent,
          },
          {
            path: 'mod',
            component: ModComponent,
          },
        ],
        canActivate: [ClientGuard],
      },

      /*  { path: 'date/:date/:titre', component: AgendaComponent }, */
    ],
  },
  { path: 'login', component: LoginComponent },

  {
    path: 'admin',
    loadChildren: () =>
      import('./+admin/admin.module').then((m) => m.AdminModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'explore/:cont',
    component: ExploreComponent,
    canActivate: [AuthGuard],
  },
  { path: '**', redirectTo: '/' },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      initialNavigation: 'enabled',
      scrollPositionRestoration: 'top',
      anchorScrolling: 'enabled',
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
