export * from './authentication.service';

import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { ApiInterceptor, CacheInterceptor } from './ApiRequest.service';
export const httpInterceptorProviders = [
  { provide: HTTP_INTERCEPTORS, useClass: ApiInterceptor, multi: true },
];
export const httpCacheInterceptorProviders = [
  { provide: HTTP_INTERCEPTORS, useClass: CacheInterceptor, multi: true },
];
export * from './upload.service';