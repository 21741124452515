import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { Config, CONFIG } from 'config';
import * as CryptoJS from 'crypto-js';
import { Subscription } from 'rxjs';
import { ClientControllerService } from 'src/api/services';
import { AuthenticationService } from 'src/app/services';
import { MustMatch } from 'src/app/shared/must-match.validator';
import {
  MatSnackBar,
  MatSnackBarHorizontalPosition,
  MatSnackBarVerticalPosition,
} from '@angular/material/snack-bar';
@Component({
  selector: 'app-recup-pass',
  templateUrl: './recup-pass.component.html',
  styleUrls: ['../../styles.scss', './login.component.scss'],
})
export class RecupPassComponent implements OnInit {
  config: Config = CONFIG;
  tokenFromUI: string = this.config.PASSPHR;
  subUrl: Subscription = new Subscription();
  passForm!: FormGroup;
  clientRecupPass!: { id: string; email: string };
  hide = true;
  error: string = '';
  title: string = '';
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private clientsService: ClientControllerService,
    private authenticationService: AuthenticationService,
    private formBuilder: FormBuilder,
    private titre: Title,
    private _snackBar: MatSnackBar
  ) {}

  ngOnInit(): void {
    this.passForm = this.formBuilder.group(
      {
        password: [
          '',
          Validators.compose([Validators.required, Validators.minLength(8)]),
        ],
        password2: ['', Validators.required],
      },
      {
        validator: MustMatch('password', 'password2'),
      }
    );
    this.subUrl = this.route.params.subscribe((params) => {
      try {
        let code = this.decryptUsingAES256(params.code);
        //this.decryptUsingAES256(params.code.toString())
        this.clientsService.verifCode({ code: code }).subscribe((client) => {
          if (client) {
            this.clientRecupPass = client;
            this.title = 'Modifier mon mot de passe (' + client.email + ')';
            this.titre.setTitle(this.title + this.config.title);
            //ok on propose le form modif mail
           
          } else {
            this.router.navigate(['']);
          }
        });
      } catch (e) {
        this.router.navigate(['']);
      }
    });
  }
  majPass() {
    //modif du pass puis login
    if (this.passForm.valid) {
      this.clientsService
        .modPasse({
          id: this.clientRecupPass!.id,
          body: {
            email: this.clientRecupPass.email,
            password: this.passForm.value.password,
          },
        })
        .subscribe(() => {
          this.authenticationService
            .loginClient(
              this.clientRecupPass.email,
              this.passForm.value.password
            )
            .subscribe((client) => {
              if (client && client.token) {
                localStorage.setItem('clientUser', JSON.stringify(client));
                this._snackBar.open(
                  'Votre mot de passe a bien été modifié, vous allez être redirigé vers la gestion de votre compte.',
                  '',
                  {
                    horizontalPosition: 'center',
                    verticalPosition: 'bottom',
                    duration:5000,
                    panelClass: 'SnackBarAdddPanier',
                  }
                );
                setTimeout(this.versMoncompte.bind(this), 5000);
              }
            });
        });
    }
  }
  versMoncompte() {
    this.router.navigate([
      '/',
      this.config.lienSpec.CATALOGUE,
      'client',
      'moncompte',
    ]);
  }
  encryptUsingAES256(chaine: string) {
    let _key = CryptoJS.enc.Utf8.parse(this.tokenFromUI);
    let _iv = CryptoJS.enc.Utf8.parse(this.tokenFromUI);
    let encrypted = CryptoJS.AES.encrypt(JSON.stringify(chaine), _key, {
      keySize: this.tokenFromUI.length,
      iv: _iv,
      mode: CryptoJS.mode.ECB,
      padding: CryptoJS.pad.Pkcs7,
    });
    return encrypted.toString();
  }
  decryptUsingAES256(chaine: string) {
    let _key = CryptoJS.enc.Utf8.parse(this.tokenFromUI);
    let _iv = CryptoJS.enc.Utf8.parse(this.tokenFromUI);

    return CryptoJS.AES.decrypt(chaine, _key, {
      keySize: 16,
      iv: _iv,
      mode: CryptoJS.mode.ECB,
      padding: CryptoJS.pad.Pkcs7,
    })
      .toString(CryptoJS.enc.Utf8)
      .replace(/"/g, '');
  }
}
