import {
  Component,
  Inject,
  OnDestroy,
  OnInit,
  PLATFORM_ID,
  ViewChild,
} from '@angular/core';

import { Subscription } from 'rxjs';
import {
  CommandeWithRelations,
  ConfigBoutique,
  ConfigPartial as ConfigSite,
  PageWithRelations,
} from 'src/api/models';
import {
  completeClient,
  panierCookies,
  ProduitAff,
  produitCookies,
  tokenClient,
} from 'src/models';
import { CONFIG, Config } from 'config';
import { MatTable } from '@angular/material/table';
import { isPlatformBrowser } from '@angular/common';
import { Meta, Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import {
  PageSpecControllerService,
  ConfigControllerService,
  CommandeControllerService,
  ClientControllerService,
} from 'src/api/services';
import * as moment from 'moment';
import { EtatCommandes } from 'src/app/shared/etat-commandes.shared';

@Component({
  selector: 'app-consult',
  templateUrl: './consult.component.html',
  styleUrls: [
    './../../styles.scss',
    './../panier.component.scss',
    './verif.component.scss',
    './valid.component.scss',
  ],
})
export class ConsultComponent implements OnInit, OnDestroy {
  configBoutique: ConfigBoutique = {
    genNbrProduitCat: 10,
    genNbrProduitStar: 10,
    d4: 'd4',
  };
  produits: ProduitAff[] = [];
  public configSite!: ConfigSite;
  config: Config = CONFIG;
  subConfigBoutique!: Subscription;
  subPage!: Subscription;
  page!: PageWithRelations;
  subConfig!: Subscription;

  moi!: completeClient;
  subMoi!: Subscription;
  displayedColumns: string[] = [
    'ref',
    'produit',
    'puttc',
    'attributs',
    'nbr',
    'pttc',
  ];
  public panierTemp: boolean = false;
  public panier: panierCookies = { date: Date.now(), produits: [] };
  cptPanier: number = 0;
  @ViewChild(MatTable) table!: MatTable<produitCookies>;
  testBrowser: boolean;
  user!: tokenClient;
  subCommande!: Subscription;
  subUrl!: Subscription;
  title: string='';
  commande!: CommandeWithRelations;
  constructor(
    @Inject(PLATFORM_ID) platformId: string,
    private intEtat: EtatCommandes,
    private readonly meta: Meta,
    private titre: Title,
    private route: ActivatedRoute,
    private router: Router,
    private pagesService: PageSpecControllerService,
    private configService: ConfigControllerService,
    private commandeService: CommandeControllerService,
    private clientsService: ClientControllerService
  ) {
    this.testBrowser = isPlatformBrowser(platformId);
  }

  ngOnInit(): void {
    //ici on depil la commande idcommande (cf depil panierCookies) + page VALIDCOMMANDE
    this.subConfig = this.configService.findd4().subscribe((config) => {
      this.configSite = config;
      this.subUrl = this.route.params.subscribe((params) => {
        this.subCommande = this.commandeService
          .findById({ id: params.idcommande })
          .subscribe((commande) => {
            this.commande=commande;
            this.title =
              'Ma commande N°' +
              params.idcommande +
              ' - ' +
              this.configSite.title!;

            this.titre.setTitle(this.title);

            this.subMoi = this.clientsService
              .printCurrentClient()
              .subscribe((moi) => {
                this.moi = moi;
                if (!(commande.clientId === this.moi.id)) {
                  this.router.navigate(['']);
                }
                this.panier = {
                  date: moment(commande.date).unix(),
                  produits: commande.produits as produitCookies[],
                  codepromo: commande.promodetail?JSON.parse(commande.promodetail!):'',
                  fidelite:{id:commande.fideliteId!,valeur:commande.fideliteValeur!}
                };
              });
          });
      });
    });
  }
  ngOnDestroy(): void {
    if (this.subConfig) this.subConfig.unsubscribe();
    if (this.subConfigBoutique) this.subConfigBoutique.unsubscribe();
    if (this.subPage) this.subPage.unsubscribe();
    if (this.subConfig) this.subConfig.unsubscribe();
    if (this.subConfigBoutique) this.subConfigBoutique.unsubscribe();
    if (this.subPage) this.subPage.unsubscribe();
    if (this.subMoi) this.subMoi.unsubscribe();
    if (this.subCommande) this.subCommande.unsubscribe();
  }
  getTotalCost() {
    return this.panier.produits
      .map((t) => t.prixTotalTTC)
      .reduce((acc, value) => acc + value, 0);
  }
  etat(code: string) {
    return this.intEtat.etat(code);
  }
}
