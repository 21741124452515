import {
  Component,
  OnInit,
  OnDestroy,
  Inject,
  ViewChild,
  ElementRef,
  PLATFORM_ID,
  AfterContentChecked,
} from '@angular/core';
import { Config, CONFIG } from '../../../config';
import { DOCUMENT, isPlatformBrowser } from '@angular/common';
import { Page, Produit } from 'src/api/models';
import { Observable, Subscription } from 'rxjs';
import { ConfigPartial as ConfigSite } from 'src/api/models';
import { HttpClient } from '@angular/common/http';
import { Location } from '@angular/common';
import { Gtag } from 'angular-gtag';
import {
  ConfigBoutiqueControllerService,
  ConfigControllerService,
  PagesControllerService,
  ProduitControllerService,
} from 'src/api/services';
import { NavigationEnd, Router } from '@angular/router';
import { filter, map, startWith } from 'rxjs/operators';
import { FormControl } from '@angular/forms';
import { panierCookies } from 'src/models';

interface Anchor {
  ordre: number | undefined;
  lien: string | undefined;
  description?: string;
  titre: string | undefined;
  type: string;
  ss?: Anchor[];
}

interface Pcherch {
  nom: string;
  resume: string;
  ref: string;
  url: string;
}

@Component({
  selector: 'app-pages',
  templateUrl: './pages.component.html',
  /* template:'<router-outlet></router-outlet>', */
  styleUrls: ['./pages.component.scss'],
})
export class PagesComponent implements OnInit, OnDestroy, AfterContentChecked {
  public isCollapsed: boolean = false;
  anchorsNF: Anchor[] = [];
  anchors: Anchor[] = [];
  ssAnchors: Anchor[][] = [];
  config: Config = CONFIG;
  public pages: Page[] = [];
  subMenu!: Subscription;
  public Sspages: Page[] = [];
  subSsMenu!: Subscription;
  public configSite!: ConfigSite;
  subConfig!: Subscription;
  subConfigBoutique!: Subscription;
  testBrowser: boolean = false;
  private subProduits: Subscription = new Subscription();
  public produits!: Produit[];
  tabsearch: any[any] = [];
  rechControl = new FormControl();
  filteredOptions!: Observable<Pcherch[]>;
  clientActif: boolean = false;
  nbrArtPanier: number = 0;
  ligne: boolean = false;
  @ViewChild('container', { static: false })
  private container!: ElementRef;
  constructor(
    @Inject(PLATFORM_ID) platformId: string,
    private http: HttpClient,
    private gtag: Gtag,
    private location: Location,
    private pagesService: PagesControllerService,
    private configService: ConfigControllerService,
    private router: Router,
    private produitsService: ProduitControllerService,
    private configBoutiqueService: ConfigBoutiqueControllerService
  ) {
    this.testBrowser = isPlatformBrowser(platformId);
    router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe((e) => {
        let titr = '';
        titr = this.location.path().toString().replace(/\//gi, ' ').slice(1);
        if (this.testBrowser && this.config.GTAGKEY !== '') {
          this.gtag.pageview({
            page_location: this.location.path(),
            page_title: titr !== '' ? titr : this.config.title,
          });
          this.gtag.event('page_view');
        }
      });
  }
  ngAfterContentChecked(): void {
    if (this.testBrowser) {
      const panierRecup: panierCookies = JSON.parse(
        localStorage.getItem('proPulsePanier')!
      );
      this.nbrArtPanier = 0;
      if (panierRecup) {
        panierRecup.produits.map((e) => {
          this.nbrArtPanier += e.nbr;
        });
      } else {
        this.nbrArtPanier = 0;
      }
      const clientCookies = JSON.parse(localStorage.getItem('clientUser')!);
      if (clientCookies) {
        this.clientActif = true;
      }
    }
  }
  ngOnInit() {
    this.subConfigBoutique = this.configBoutiqueService
      .findd4()
      .subscribe((confBoutique) => {
        this.ligne = confBoutique.ligne!;
        if (this.ligne) {
          this.subProduits = this.produitsService
            .find({
              filter: {
                filter: `{"order": "nom ASC","where":{"and":[{"actif":true},{"stock":{"gt":0}}]}}`,
              },
            })
            .subscribe((out) => {
              this.produits = out;
              out.map((pr) => {
                this.tabsearch.push({
                  nom: pr.nom,
                  resume: pr.resume,
                  ref: pr.ref,
                  url: pr.url,
                });
              });
              this.filteredOptions = this.rechControl.valueChanges.pipe(
                startWith(''),
                map((value) => (typeof value === 'string' ? value : value.nom)),
                map((nom) => (nom ? this._filter(nom) : this.tabsearch.slice()))
              );
            });
        }
      });
    if (this.subMenu) {
      this.subMenu.unsubscribe();
    }
    if (this.subConfig) {
      this.subConfig.unsubscribe();
    }
    if (this.subSsMenu) {
      this.subSsMenu.unsubscribe();
    }

    this.subConfig = this.configService.findd4().subscribe((config) => {
      this.configSite = config;
      this.subMenu = this.pagesService
        .find({
          filter: {
            filter: `{"order": "ordre ASC","where":{"and":[{"parent": ""},{"brouillon":"false"}]}}`,
          },
        })
        .subscribe((pageData) => {
          this.pages = pageData;
          this.pages.map((page) => {
            const parent = page.menu;
            this.anchorsNF[page.ordre! - 1000] = {
              ordre: page.ordre,
              lien: page.url,
              titre: page.menu,
              description: page.description,
              type: page.type ? this.config.lienSpec[page.type] : 'PAGE',
            };
            this.subSsMenu = this.pagesService
              .find({
                filter: {
                  filter: `{"order": "ordre ASC","where":{"and":[{"parent": "${parent}"},{"brouillon":"false"}]}}`,
                },
              })
              .subscribe((dataSs) => {
                this.Sspages = dataSs;
                this.ssAnchors[page.ordre! - 1000] = [];
                this.Sspages.map((sspage) => {
                  this.ssAnchors[page.ordre! - 1000].push({
                    ordre: sspage.ordre,
                    lien: sspage.url,
                    titre: sspage.menu,
                    description: sspage.description,
                    type: sspage.type
                      ? this.config.lienSpec[sspage.type]
                      : 'PAGE',
                  });
                  this.anchorsNF[page.ordre! - 1000].ss =
                    this.ssAnchors[page.ordre! - 1000];
                });
              });
          });
          this.anchors = this.anchorsNF.filter(
            (el) => Object.keys(el).length > 0
          );
        });
    });
  }
  displayFn(pr: Pcherch): string {
    return pr && pr.nom ? pr.nom : '';
  }
  cherche() {
    this.router.navigate([
      '/',
      this.config.lienSpec.CATALOGUE,
      'fiche',
      this.rechControl.value.ref,
      this.rechControl.value.url,
    ]);
  }
  cherchBtnValid() {
    return (
      this.rechControl.value &&
      this.rechControl.valid &&
      this.rechControl.value.nom
    );
  }
  normalise(str:string){
    return str.trim().toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "");
  }
  private _filter(value: string): Pcherch[] {
    const filterValue = this.normalise(value).split(" ");
    const data = this.tabsearch;

    return data.filter(
      (word: any) =>
      filterValue.every(v=>this.normalise(word.nom).includes(v)) ||
      filterValue.every(v=>this.normalise(word.resume).includes(v))
    );
  }
  ngOnDestroy() {
    if (this.subMenu) {
      this.subMenu.unsubscribe();
    }
    if (this.subConfig) {
      this.subConfig.unsubscribe();
    }
    if (this.subSsMenu) {
      this.subSsMenu.unsubscribe();
    }
  }
}
