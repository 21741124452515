<div class="cont">

    <h1>{{page?.h1}}</h1>
    <div id="page" class="dyn" [innerHtml]="page?.html | unsafe:'html'"> </div>

    <div class="menucat">
        <mat-accordion>
            <mat-expansion-panel class="mainCat">
                <mat-expansion-panel-header>
                    <mat-panel-title>
                        Les Catégories
                    </mat-panel-title>
                    <mat-panel-description>
                        Cliquez ici pour filtrer par catégorie
                    </mat-panel-description>
                </mat-expansion-panel-header>
                <div fxLayout="row wrap" fxLayoutGap="5px" fxLayoutAlign="center stretch">
                    <ng-template #recursiveList let-menuCat>
                        <div *ngFor="let item of menuCat" class="contCat">
                            <div>
                                <div class="parentCat">
                                    <a
                                        [routerLink]="['/',config.lienSpec.CATALOGUE,'categorie',item.menu]">{{item.etiquette}}</a>
                                </div>

                                <div *ngIf="item.enfants.length > 0">

                                    <ng-container
                                        *ngTemplateOutlet="recursiveSsList; context:{ $implicit: item.enfants,etiq:item.etiquette  }">
                                    </ng-container>
                                </div>
                            </div>
                        </div>

                        <ng-template #recursiveSsList let-menuCat let-etiquette="etiq">
                            <div *ngFor="let ss of menuCat" class="childCat"><a
                                    [routerLink]="['/',config.lienSpec.CATALOGUE,'categorie',ss.menu]"><!-- {{etiquette}}/ -->{{ss.etiquette}}</a>
                                <div *ngIf="ss.enfants.length > 0">
                                    <ng-container
                                        *ngTemplateOutlet="recursiveSsList; context:{ $implicit: ss.enfants,etiq:etiquette+'/'+ss.etiquette }">
                                    </ng-container>
                                </div>
                            </div>
                        </ng-template>
                    </ng-template>
                    <ng-container *ngTemplateOutlet="recursiveList; context:{ $implicit: menuCat }"></ng-container>
                </div>
            </mat-expansion-panel>

        </mat-accordion>
    </div>
    <div>
        <div class="produitsListe">
            <div class="produilistCont mat-elevation-z2">
                <div fxLayout="row wrap" fxLayoutGap="5px" fxLayoutAlign="center stretch">
                    <mat-card class="produit mat-elevation-z2" *ngFor="let produit of produitsLimit"
                        [routerLink]="['/',config.lienSpec.CATALOGUE,'fiche',produit.ref,produit.url]">
                        <mat-card-header>
                            <mat-card-title>{{produit?.nom}}</mat-card-title>
                            <mat-card-subtitle class="sstitre">{{produit?.categorieEtiquette}}</mat-card-subtitle>
                        </mat-card-header>
                        <mat-card-content>
                            <figure *ngIf="produit?.images!.length" class="imgproduit"><img class="lazyload"
                                    data-src="{{produit?.images![0].replace('_max.', '_med.')}}" [alt]="produit?.nom">
                            </figure>
                            <div class="resume">{{produit?.resume}}</div>
                        </mat-card-content>
                        <mat-card-actions class="prodAction" [title]="produit?.resume">
                            <div fxLayout="row" fxLayoutGap="5px" fxLayoutAlign="center center">
                                <div [class]="produit?.prixbarre! >0?'barre':'prix'">{{produit?.prixTTC | currency}} / {{produit?.unite}}
                                </div>
                                <div class="prix" *ngIf="produit?.prixBarreTTC! >0">{{produit?.prixBarreTTC | currency}} / {{produit?.unite}}
                                </div>
                            </div>
                            <a mat-icon-button title="Voir la fiche {{produit?.nom}}"
                                [routerLink]="['/',config.lienSpec.CATALOGUE,'fiche',produit.ref,produit.url]">
                                <mat-icon>launch</mat-icon>
                            </a>
                        </mat-card-actions>
                    </mat-card>
                </div>
                <div class="plusContainer"> Nbr. articles affichés : {{produitsLimit.length}}/{{produits.length}}
                    <button *ngIf="produitsLimit.length<produits.length" mat-stroked-button (click)="plus()">Afficher
                        plus d'articles <mat-icon>add</mat-icon></button></div>
            </div>

        </div>
    </div>
</div>