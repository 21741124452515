<div class="cont" *ngIf="page?.html">

  <h1>{{page?.h1}}</h1>

  <div class="dyn" [innerHtml]="page?.html | unsafe:'html'"> </div>
  <div class="articlContent" fxLayout="row wrap" fxLayout.lt-sm="column"  fxLayoutGap="10px"
    fxLayoutAlign="center stretch" fxLayoutAlign.lt-sm="center center">

    <mat-card class="article-card mat-elevation-z5" *ngFor="let article of journal" fxLayout="column" fxFlex="1">
      <mat-card-header>
        <div mat-card-avatar class="article-header-image"></div>
        <mat-card-title>{{article.titre}}</mat-card-title>
        <mat-card-subtitle>Par {{article.auteur}}</mat-card-subtitle>
      </mat-card-header>
      <mat-card-content>
        <figure *ngIf="article?.ogimage" class="imgarticle"><img class="mat-elevation-z2"
            [src]="article?.ogimage!.replace('/media/blog/', '/media/blog/_med.')" [alt]="article.titre"></figure>
        <p [innerHtml]="article.description | nl2br">

        </p>
      </mat-card-content>
      <div class="spacer"></div>
      <mat-card-actions class="article-action" fxLayoutAlign="end">
        <a mat-button [routerLink]="['/','article',article.url]" [title]="article.titre">Lire {{article.titre}}</a>
      </mat-card-actions>
    </mat-card>
<div class="clearfix"></div>
  </div>
  
  <div class="plusarticle"><a mat-raised-button *ngIf="journal?.length!<nbrArticle" (click)="plusArticle()"><mat-icon>
    more_horiz
    </mat-icon> Plus d'articles</a></div>
</div>