/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { Config } from '../models/config';
import { ConfigPartial } from '../models/config-partial';
import { ConfigWithRelations } from '../models/config-with-relations';
import { NewConfig } from '../models/new-config';
import { Count as LoopbackCount } from '../models/loopback/count';

@Injectable({
  providedIn: 'root',
})
export class ConfigControllerService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation configControllerCount
   */
  static readonly ConfigControllerCountPath = '/configs/count';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `count()` instead.
   *
   * This method doesn't expect any request body.
   */
  count$Response(params?: {
    where?: any;
  }): Observable<StrictHttpResponse<LoopbackCount>> {

    const rb = new RequestBuilder(this.rootUrl, ConfigControllerService.ConfigControllerCountPath, 'get');
    if (params) {
      rb.query('where', params.where, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<LoopbackCount>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `count$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  count(params?: {
    where?: any;
  }): Observable<LoopbackCount> {

    return this.count$Response(params).pipe(
      map((r: StrictHttpResponse<LoopbackCount>) => r.body as LoopbackCount)
    );
  }

  /**
   * Path part for operation configControllerFindd4
   */
  static readonly ConfigControllerFindd4Path = '/configs/d4';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `findd4()` instead.
   *
   * This method doesn't expect any request body.
   */
  findd4$Response(params?: {
  }): Observable<StrictHttpResponse<{ 'id'?: string, 'url'?: string, 'title'?: string, 'description'?: string, 'enseigne'?: { 'nom'?: string, 'adresse'?: string, 'cp'?: number, 'ville'?: string, 'tel'?: string, 'port'?: string, 'lat'?: number, 'lng'?: number }, 'email'?: string, 'd4'?: string }>> {

    const rb = new RequestBuilder(this.rootUrl, ConfigControllerService.ConfigControllerFindd4Path, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{ 'id'?: string, 'url'?: string, 'title'?: string, 'description'?: string, 'enseigne'?: { 'nom'?: string, 'adresse'?: string, 'cp'?: number, 'ville'?: string, 'tel'?: string, 'port'?: string, 'lat'?: number, 'lng'?: number }, 'email'?: string, 'd4'?: string }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `findd4$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  findd4(params?: {
  }): Observable<{ 'id'?: string, 'url'?: string, 'title'?: string, 'description'?: string, 'enseigne'?: { 'nom'?: string, 'adresse'?: string, 'cp'?: number, 'ville'?: string, 'tel'?: string, 'port'?: string, 'lat'?: number, 'lng'?: number }, 'email'?: string, 'd4'?: string }> {

    return this.findd4$Response(params).pipe(
      map((r: StrictHttpResponse<{ 'id'?: string, 'url'?: string, 'title'?: string, 'description'?: string, 'enseigne'?: { 'nom'?: string, 'adresse'?: string, 'cp'?: number, 'ville'?: string, 'tel'?: string, 'port'?: string, 'lat'?: number, 'lng'?: number }, 'email'?: string, 'd4'?: string }>) => r.body as { 'id'?: string, 'url'?: string, 'title'?: string, 'description'?: string, 'enseigne'?: { 'nom'?: string, 'adresse'?: string, 'cp'?: number, 'ville'?: string, 'tel'?: string, 'port'?: string, 'lat'?: number, 'lng'?: number }, 'email'?: string, 'd4'?: string })
    );
  }

  /**
   * Path part for operation configControllerFindById
   */
  static readonly ConfigControllerFindByIdPath = '/configs/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `findById()` instead.
   *
   * This method doesn't expect any request body.
   */
  findById$Response(params: {
    id: string;
    filter?: any;
  }): Observable<StrictHttpResponse<ConfigWithRelations>> {

    const rb = new RequestBuilder(this.rootUrl, ConfigControllerService.ConfigControllerFindByIdPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
      rb.query('filter', params.filter, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ConfigWithRelations>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `findById$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  findById(params: {
    id: string;
    filter?: any;
  }): Observable<ConfigWithRelations> {

    return this.findById$Response(params).pipe(
      map((r: StrictHttpResponse<ConfigWithRelations>) => r.body as ConfigWithRelations)
    );
  }

  /**
   * Path part for operation configControllerDeleteById
   */
  static readonly ConfigControllerDeleteByIdPath = '/configs/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteById()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteById$Response(params: {
    id: string;
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, ConfigControllerService.ConfigControllerDeleteByIdPath, 'delete');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `deleteById$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteById(params: {
    id: string;
  }): Observable<void> {

    return this.deleteById$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation configControllerUpdateById
   */
  static readonly ConfigControllerUpdateByIdPath = '/configs/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateById()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateById$Response(params: {
    id: string;
    body?: ConfigPartial
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, ConfigControllerService.ConfigControllerUpdateByIdPath, 'patch');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `updateById$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateById(params: {
    id: string;
    body?: ConfigPartial
  }): Observable<void> {

    return this.updateById$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation configControllerCreate
   */
  static readonly ConfigControllerCreatePath = '/configs';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `create()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  create$Response(params?: {
    body?: NewConfig
  }): Observable<StrictHttpResponse<Config>> {

    const rb = new RequestBuilder(this.rootUrl, ConfigControllerService.ConfigControllerCreatePath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Config>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `create$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  create(params?: {
    body?: NewConfig
  }): Observable<Config> {

    return this.create$Response(params).pipe(
      map((r: StrictHttpResponse<Config>) => r.body as Config)
    );
  }

}
