import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import {
  MatSnackBar,
  MatSnackBarHorizontalPosition,
  MatSnackBarVerticalPosition,
} from '@angular/material/snack-bar';
import { Meta, Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable, Subscription } from 'rxjs';
import { Categorie, Tva } from 'src/api/models';
import { Attribu, Produit, ConfigPartial as ConfigSite } from 'src/api/models';
import {
  ProduitControllerService,
  CategoriesControllerService,
  TvaControllerService,
  AttribusControllerService,
  ConfigControllerService,
  ConfigBoutiqueControllerService,
} from 'src/api/services';
import { AnimationType } from 'src/app/carousel/carousel.animations';
import { CarouselComponent } from 'src/app/carousel/carousel.component';
import { Slide } from 'src/app/carousel/carousel.interface';
import {
  attributCommande,
  CatProduits,
  panierCookies,
  ProduitAff,
  produitCookies,
} from 'src/models';
import { Config, CONFIG } from '../../../../config';

@Component({
  selector: 'app-produit',
  templateUrl: './produit.component.html',
  styleUrls: [
    './../styles.scss',
    './catalogue.component.scss',
    './produit.component.scss',
  ],
})
export class ProduitComponent implements OnInit, OnDestroy {
  @ViewChild(CarouselComponent) carousel: CarouselComponent =
    new CarouselComponent();
  animationType = AnimationType.Fade; //Scale Fade Flip JackInTheBox
  subUrl: Subscription = new Subscription();
  produit!: Produit;
  subProduits: Subscription = new Subscription();
  subCat: Subscription = new Subscription();
  public cats!: Categorie[];
  public cat!: Categorie;
  subTva: Subscription = new Subscription();
  public tva!: Tva;
  catsProduit: Categorie[] = [];
  tabCat: any[any] = [];
  tabTva: any[any] = [];
  tabNbrProduitTVA: any[number] = [];
  subAttribut: Subscription = new Subscription();
  public attribus: Attribu[] = [];
  tabAttr: any[any] = [];
  produitImages: Slide[] = [];
  prixTTC: number = 0;
  prixBarreTTC: number = 0;
  public configSite!: ConfigSite;
  config: Config = CONFIG;
  subConfig!: Subscription;
  subConfigBoutique!: Subscription;
  public majoration: number = 0;
  public attributSel: number[] = [];
  public attributSelEl: attributCommande[][] = [];
  attributSeEl: any;
  private attributCommande: attributCommande[] = [];
  public nbrProduit: number = 1;
  maxProd: number = 20;
  minProd: number = 1;
  subProduitsAsso: any;
  produitAsso: ProduitAff[] = [];
  horizontalPosition: MatSnackBarHorizontalPosition = 'center';
  verticalPosition: MatSnackBarVerticalPosition = 'top';
  nbrHorsAttributs: number = 0;
  testNbr: boolean = false;
  constructor(
    private configService: ConfigControllerService,
    private readonly meta: Meta,
    private titre: Title,
    private router: Router,
    private route: ActivatedRoute,
    private produitsService: ProduitControllerService,
    private catService: CategoriesControllerService,
    private tvaService: TvaControllerService,
    private attribuService: AttribusControllerService,
    private configBoutiqueService: ConfigBoutiqueControllerService,
    private _snackBar: MatSnackBar
  ) {}
  ngOnInit(): void {
    this.subConfigBoutique = this.configBoutiqueService
      .findd4()
      .subscribe((confBoutique) => {
        /*  if (!confBoutique.ligne!) {
          this.router.navigate(['/']);
        } */
      });
    this.getCats();
    this.subUrl = this.route.params.subscribe((params) => {
      this.subProduits = this.produitsService
        .find({
          filter: {
            filter: `{"where":{"and":[{"url":"${params.url}"},{"ref":"${params.ref}"}]}}`,
          },
        })
        .subscribe((p) => {
          this.subConfig = this.configService.findd4().subscribe((config) => {
            this.configSite = config;
            if (p.length) {
              this.produit = p[0];
              this.nbrHorsAttributsRecup();
              //associes
              this.produitAsso = [];
              if (this.produit.assoProdId?.length) {
                this.subProduitsAsso = this.produitsService
                  .find({
                    filter: {
                      filter: `{"where":{"and":[{"id":{"inq":["${this.produit.assoProdId?.join(
                        '","'
                      )}"]}},{"actif":true},{"stock":{"gt":0}}]}}`,
                    },
                  })
                  .subscribe((pA) => {
                    pA.map((pA) => {
                      this.calcPrixTTC(
                        pA.tvaId,
                        pA.prixHT,
                        pA.prixbarre! | 0
                      ).then((out) => {
                        const prixTTC = out.ttc;
                        const prixBarreTTC = out.ttcbarre;
                        this.produitAsso.push(
                          Object.assign(pA, {
                            prixTTC: prixTTC,
                            prixBarreTTC: prixBarreTTC,
                          })
                        );
                      });
                    });
                  });
              }
              //images
              this.produitImages = [];
              this.produit.images?.map((src, i) => {
                this.produitImages.push({
                  src: src,
                });
              });

              //tva
              this.subTva = this.tvaService
                .findById({ id: this.produit.tvaId! })
                .subscribe((tva) => {
                  this.tva = tva;
                });
              //categorie
              this.subCat = this.catService
                .find({
                  filter: {
                    filter: `{"where":{"id":{"inq":["${this.produit.categorieId?.join(
                      '","'
                    )}"]}}}`,
                  },
                })
                .subscribe((cats) => {
                  this.catsProduit = cats;
                  //attributs
                  this.attribus = [];
                  this.subAttribut = this.attribuService
                    .find({
                      filter: {
                        filter: `{"where":{"id":{"inq":["${this.produit.attribusId?.join(
                          '","'
                        )}"]}}}`,
                      },
                    })
                    .subscribe((attr) => {
                      this.attribus = attr;

                      this.calcVal(true);
                      // metas
                      const Tags = [
                        "name='description'",
                        "property= 'og:url'",
                        "property='og:title'",
                        "property='og:description'",
                        "property='og:image'",
                        "property='og:locale'",
                        "property='og:type'",
                        "property='og:site_name'",
                        "name='twitter:card'",
                        "name='twitter:description'",
                        "name='twitter:title'",
                        "name='twitter:image'",
                      ];
                      Tags.map((e) => this.meta.removeTag(e));

                      const title =
                        this.produit.nom + ' - ' + this.configSite.title!;
                      const description = this.produit.resume || '';
                      this.titre.setTitle(title);
                      let ogimage =
                        this.produit.ogimage || this.config.imageUrl || '';
                      this.meta.addTags([
                        { name: 'description', content: description },
                        {
                          property: 'og:url',
                          content:
                            this.config.applicationUrl +
                            '/' +
                            this.config.lienSpec.CATALOGUE +
                            '/fiche/' +
                            this.produit.ref +
                            '/' +
                            this.produit.url,
                        },
                        { property: 'og:title', content: title },
                        { property: 'og:description', content: description },
                        { property: 'og:image', content: ogimage },
                        { property: 'og:locale', content: 'fr_FR' },
                        { property: 'og:type', content: 'website' },
                        {
                          property: 'og:site_name',
                          content: this.configSite.title!,
                        },
                        { name: 'twitter:card', content: 'summary' },
                        { name: 'twitter:description', content: description },
                        { name: 'twitter:title', content: title },
                        { name: 'twitter:image', content: ogimage },
                      ]);
                    });
                });
            } else {
              this.router.navigate(['catalogue']);
            }
          });
        });
    });
  }
  ngOnDestroy(): void {
    if (this.subAttribut) this.subAttribut.unsubscribe();
    if (this.subCat) this.subCat.unsubscribe();
    if (this.subProduits) this.subProduits.unsubscribe();
    if (this.subProduitsAsso) this.subProduitsAsso.unsubscribe();
    if (this.subTva) this.subTva.unsubscribe();
    if (this.subUrl) this.subUrl.unsubscribe();
    if (this.subConfig) this.subConfig.unsubscribe();
    if (this.subConfigBoutique) this.subConfigBoutique.unsubscribe();
  }
  async calcPrixTTC(
    idTva: string,
    ht: number,
    htBarre: number
  ): Promise<{ ttc: number; ttcbarre: number }> {
    const tva = await this.tvaService.findById({ id: idTva }).toPromise();
    return {
      ttc: ht + (ht * tva.taux) / 100,
      ttcbarre: htBarre + (htBarre * tva.taux) / 100,
    };
  }
  catEtig(id: string, strenfant?: string): string {
    const obj = this.tabCat[id];
    const etiquette = obj.etiquette;
    const str = strenfant ? etiquette + '/' + strenfant : etiquette;
    if (obj.parent) return this.catEtig(obj.parent, str);
    return str;
  }

  getCats() {
    this.subCat = this.catService
      .find({
        filter: {
          filter: `{"order":["parent ASC","ordre ASC"]}`,
        },
      })
      .subscribe((cats) => {
        this.cats = cats;
        this.cats.map((cat) => {
          this.tabCat[cat.id!] = {
            etiquette: cat.etiquette,
            parent: cat.parent,
          };
        });
      });
  }
  calcVal(init?: boolean) {
    this.testNbr = Number.isNaN(
      Number.parseFloat(this.nbrProduit as unknown as string)
    );
    this.maxProd = this.produit.stock! - this.nbrHorsAttributs;
    this.minProd =
      Number(this.produit.minUnite) > 0 ? this.produit.minUnite! : 1; //Math.max(this.produit.minUnite!, 1);
    if (!this.testNbr) {
      if (this.nbrProduit > this.maxProd) this.nbrProduit = this.maxProd;
      if (this.nbrProduit < this.minProd)
        this.nbrProduit = Number(this.minProd);
    }
    this.majoration = 0;
    this.attributCommande = [];
    if (init) {
      this.nbrProduit = Number(this.minProd);
      this.attributSelEl = [];
      this.attributSel = [];
      this.attribus.map((e, i) => {
        this.attributSel[i] = 0;
        this.attributSelEl[i] = [];
        e.valeurs.map((eEl, id) => {
          this.attributSelEl[i].push({
            id: e.id!,
            choix: eEl,
            plusvalue: e.prixmods[id],
          });
        });
        this.majoration += Number(e.prixmods[0]);
      });
    }
    this.attributSel.map((e: any, id) => {
      this.majoration += Number(this.attributSelEl[id][e].plusvalue);
      this.attributCommande.push(this.attributSelEl[id][e]);
    });
    this.prixTTC =
      (this.produit.prixHT +
        (this.produit.prixHT * this.tva.taux) / 100 +
        this.majoration) *
      this.nbrProduit;
    this.prixBarreTTC =
      (this.produit.prixbarre! > 0
        ? this.produit.prixbarre! +
          (this.produit.prixbarre! * this.tva.taux) / 100 +
          this.majoration
        : 0) * this.nbrProduit;
  }
  nbrHorsAttributsRecup() {
    this.nbrHorsAttributs = 0;
    const panierRecup: panierCookies = JSON.parse(
      localStorage.getItem('proPulsePanier')!
    );
    if (panierRecup) {
      panierRecup.produits.map((e) => {
        if (e.id === this.produit.id) {
          e.nbrHorsAttributs! += this.nbrProduit;
          this.nbrHorsAttributs += e.nbr;
        }
      });
    }
  }
  adPannier(nbr: number) {
    /**Ajout du produit Courant au pannier */
    const panierRecup: panierCookies = JSON.parse(
      localStorage.getItem('proPulsePanier')!
    );
    console.log(this.nbrHorsAttributs, nbr);
    const ProduitPanier: produitCookies = {
      id: this.produit.id!,
      ref: this.produit.ref,
      url: this.produit.url,
      unite: this.produit.unite,
      nom: this.produit.nom,
      prixHt: this.produit.prixHT,
      prixTTC:
        this.produit.prixHT + (this.produit.prixHT * this.tva.taux) / 100,
      attributs: this.attributCommande,
      majoration: this.majoration,
      nbr: nbr,
      prixTotalTTC: this.produit.prixbarre ? this.prixBarreTTC : this.prixTTC,
      stock: this.produit.stock,
      min: this.produit.minUnite,
      nbrHorsAttributs: nbr,
    };
    if (panierRecup) {
      let exist = false;
      let tempnbrHorsAttributs = 0;
      panierRecup.produits.map((e) => {
        if (e.id === this.produit.id) {
          if (
            JSON.stringify(e.attributs) ===
            JSON.stringify(this.attributCommande)
          ) {
            exist = true;
            if (this.nbrHorsAttributs! + nbr <= this.produit.stock!) {
              e.prixHt = this.produit.prixHT;
              e.prixTTC =
                this.produit.prixHT +
                (this.produit.prixHT * this.tva.taux) / 100;
              e.majoration = this.majoration;
              e.nbr += nbr;
              e.prixTotalTTC += this.produit.prixbarre
                ? this.prixBarreTTC
                : this.prixTTC;
              e.nbrHorsAttributs = this.nbrHorsAttributs;
            }
          }
          if (e.nbrHorsAttributs! + nbr <= this.produit.stock!) {
            tempnbrHorsAttributs += e.nbr;
          }
        }
      });
      panierRecup.produits.map((e) => {
        if (e.id === this.produit.id) {
          e.nbrHorsAttributs = tempnbrHorsAttributs;
        }
      });
      this.nbrHorsAttributs = tempnbrHorsAttributs;
      ProduitPanier.nbrHorsAttributs! = tempnbrHorsAttributs;
      if (!exist) panierRecup.produits.push(ProduitPanier);
      panierRecup.date = Date.now();
      localStorage.setItem('proPulsePanier', JSON.stringify(panierRecup));
    } else {
      this.nbrHorsAttributs += nbr;
      const ProduitsPanier: produitCookies[] = [ProduitPanier];
      const panierNew: panierCookies = {
        date: Date.now(),
        produits: ProduitsPanier,
      };
      localStorage.setItem('proPulsePanier', JSON.stringify(panierNew));
    }
    
    this.nbrProduit =  Number(this.produit.minUnite) > 0 ? this.produit.minUnite! : 1;
    this._snackBar
      .open(
        this.produit.nom + ' a bien été ajouté à votre panier',
        'Voir le panier',
        {
          horizontalPosition: this.horizontalPosition,
          verticalPosition: this.verticalPosition,
          duration: 5000,
          panelClass: 'SnackBarAdddPanier',
        }
      )
      .onAction()
      .subscribe(() => {
        this.router.navigate([this.config.lienSpec.CATALOGUE, 'panier']);
      });
  }
}
