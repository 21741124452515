import {
  Component,
  Inject,
  OnDestroy,
  OnInit,
  PLATFORM_ID,
  ViewChild,
} from '@angular/core';
import { MatAccordion } from '@angular/material/expansion';
import { Meta, Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
import {
  ConfigBoutique,
  Produit,
  Categorie,
  ConfigPartial as ConfigSite,
  Tva,
  PageWithRelations,
  CategorieWithRelations,
} from 'src/api/models';
import {
  PagesControllerService,
  ConfigControllerService,
  ProduitControllerService,
  TvaControllerService,
  CategoriesControllerService,
  ConfigBoutiqueControllerService,
} from 'src/api/services';
import { ProduitAff } from 'src/models';
import { Config, CONFIG } from '../../../../config';
interface produitCat {
  id?: string;
  cat: String;
  parent: string;
  description: string | undefined;
  produits: ProduitAff[];
  limit: number;
  nbrProduits?: number;
}
@Component({
  selector: 'app-categorie',
  templateUrl: './categorie.component.html',
  styleUrls: ['./../styles.scss', './catalogue.component.scss'],
})
export class CategorieComponent implements OnInit, OnDestroy {
  configBoutique: ConfigBoutique = {
    genNbrProduitCat: 10,
    genNbrProduitStar: 10,
    d4: 'd4',
  };
  produits: Produit[] = [];
  produitCat: produitCat[] = [];
  categories: Categorie[] = [];
  tvas: Tva[] = [];
  stars: any[any] = [];
  public configSite!: ConfigSite;
  config: Config = CONFIG;
  subConfigBoutique!: Subscription;
  subProduits!: Subscription;
  subCategories!: Subscription;
  subProduitsStars!: Subscription;
  subPage!: Subscription;
  page!: PageWithRelations;
  subConfig!: Subscription;
  subCat!: Subscription;
  subCatPage!: Subscription;
  catPage!: Categorie;
  cats!: Categorie[];
  subSsCat!: Subscription;
  menuCat: any[] = [];
  tempmenuCat: any[] = [];
  paramsSub!: Subscription;
  menu: any;
  tabSscat: any[] = [];
  produitSousCat!: any[];
  produitsLimit: ProduitAff[] = [];
  nbrProduits: number = 0;
  @ViewChild(MatAccordion) accordion: MatAccordion | undefined;
  constructor(
    @Inject(PLATFORM_ID) platformId: string,
    private readonly meta: Meta,
    private titre: Title,
    private pagesService: PagesControllerService,
    private route: ActivatedRoute,
    private configBoutiqueService: ConfigBoutiqueControllerService,
    private configService: ConfigControllerService,
    private produitService: ProduitControllerService,
    private catService: CategoriesControllerService,
    private tvaService: TvaControllerService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.subConfigBoutique = this.configBoutiqueService
      .findd4()
      .subscribe((confBoutique) => {
        /*  if (!confBoutique.ligne!) {
          this.router.navigate(['/']);
        } */
      });
    this.subConfig = this.configService.findd4().subscribe((config) => {
      this.configSite = config;
      this.subPage = this.pagesService
        .find({ filter: { filter: `{"where": { "type": "CATALOGUE" } }` } })
        .subscribe((page) => {
          this.page = page[0];
          //lazy-loading
          this.page.html = this.page.html
            ? this.page.html
                .replace(/<img /gi, '<img class="lazyload" ')
                .replace(/(<img[^>]*)src=/gi, '$1data-src=')
            : '';
          this.paramsSub = this.route.params
            .pipe(map((params) => params['menu']))
            .subscribe((menu) => {
              this.menu = menu;
              this.subCatPage = this.catService
                .find({
                  filter: { filter: `{"where": { "menu": "${menu}" } }` },
                })
                .subscribe((cat) => {
                  this.produitCat = [];
                  this.tabSscat = [];

                  this.catPage = cat[0];

                  // metas
                  const Tags = [
                    "name='description'",
                    "property= 'og:url'",
                    "property='og:title'",
                    "property='og:description'",
                    "property='og:image'",
                    "property='og:locale'",
                    "property='og:type'",
                    "property='og:site_name'",
                    "name='twitter:card'",
                    "name='twitter:description'",
                    "name='twitter:title'",
                    "name='twitter:image'",
                  ];
                  Tags.map((e) => this.meta.removeTag(e));
                  const title =
                    this.page.titre +
                    ' ' +
                    this.catPage.etiquette +
                    ' - ' +
                    this.configSite.title!;
                  const description = this.page.description || '';
                  this.titre.setTitle(title);
                  let ogimage = this.page.ogimage || this.config.imageUrl || '';
                  this.meta.addTags([
                    { name: 'description', content: description },
                    { property: 'og:url', content: this.config.applicationUrl },
                    { property: 'og:title', content: title },
                    { property: 'og:description', content: description },
                    { property: 'og:image', content: ogimage },
                    { property: 'og:locale', content: 'fr_FR' },
                    { property: 'og:type', content: 'website' },
                    {
                      property: 'og:site_name',
                      content: this.configSite.title!,
                    },
                    { name: 'twitter:card', content: 'summary' },
                    { name: 'twitter:description', content: description },
                    { name: 'twitter:title', content: title },
                    { name: 'twitter:image', content: ogimage },
                  ]);
                  this.subConfigBoutique = this.configBoutiqueService
                    .findd4()
                    .subscribe((configs) => {
                      this.configBoutique = configs;
                      this.nbrProduits = this.configBoutique.genNbrProduitCat;
                      this.getProduitsCat();
                    });
                  this.getCatsChild('')
                    .then((out) => {
                      this.tempmenuCat = out;
                    })
                    .finally(() => {
                      this.menuCat = this.tempmenuCat;
                    });
                });
            });
        });
    });
  }
  ngOnDestroy(): void {
    this.unscribe(this.subConfigBoutique);
    this.unscribe(this.subCategories);
    this.unscribe(this.subProduits);
    this.unscribe(this.subCatPage);
    this.unscribe(this.paramsSub);

    this.unscribe(this.subPage);
    this.produitCat = [];
  }

  unscribe(sub: Subscription): void {
    if (sub) sub.unsubscribe();
  }
  plus(id: string) {
    this.produitCat.map((e) => {
      if (e.id === id) {
        e.limit += this.configBoutique.genNbrProduitCat;
      }
    });
  }
  min(nbr1: number, nbr2: number) {
    return Math.min(nbr1, nbr2);
  }
  async getCatsChild(id: string): Promise<any[]> {
    let tabCats: any[] = [];
    const res = await this.catService
      .find({
        filter: {
          filter: `{"where":{"and":[{"parent":"${id}"},{"visible":{"neq":false}}]},"order":["ordre ASC"]}`,
        },
      })
      .toPromise();
    res.map(async (cat) => {
      const enfants = await this.getCatsChild(cat.id!).then((out) => {
        return out;
      });
      tabCats.push({
        id: cat.id,
        ordre: cat.ordre,
        menu: cat.menu,
        etiquette: cat.etiquette,
        enfants: enfants,
      });
    });
    return tabCats
      .sort((a, b) => {
        return this.compare(a.ordre, b.ordre, true);
      })
      .sort((a, b) => {
        return this.compare(a.parent, b.parent, true);
      });
  }
  getProduitsCat() {
    //ajouter page avec tout les produits d'une seule cat (/categorie/:menu/all)
    this.getProduitsCatRecursive(this.catPage.id!).then((out) => {
      this.produitCat = out;
    });
  }

  async getProduitsCatRecursive(
    id: string,
    cmpt: number = 0,
    tabCats?: any[]
  ): Promise<any[]> {
    let champ: string = 'parent';
    if (!tabCats) {
      tabCats = [];
      champ = 'id';
    }
    const res = await this.catService
      .find({
        filter: {
          filter: `{"where":{"${champ}":"${id}"},"order":["parent ASC","ordre ASC"]}`,
        },
      })
      .toPromise();
    cmpt++;
    res.map(async (cat, id) => {
      const enfants = await this.getProduitsCatRecursive(
        cat.id!,
        cmpt,
        tabCats
      ).then((out) => {
        return out;
      });
      const TVAS = await this.tvaService.find().toPromise();
      let tabTva: any[string] = [];
      TVAS.map((tva) => {
        tabTva[tva.id!] = tva.taux;
      });
      const produits = await this.produitService
        .find({
          filter: {
            filter: `{"order": "ordre ASC","where":{"and":[{"categorieId":"${cat.id}"},{"actif":true},{"stock":{"gt":0}}]}}`,
          },
        })
        .toPromise();
      let produitsMod: ProduitAff[] = [];
      produits.map((p) => {
        const prixTTC = p.prixHT + (p.prixHT * tabTva[p.tvaId]) / 100;
        const prixBarreTTC =
          p.prixbarre! > 0
            ? p.prixbarre! + (p.prixbarre! * tabTva[p.tvaId]) / 100
            : 0;
        produitsMod.push(
          Object.assign(p, {
            prixTTC: prixTTC,
            prixBarreTTC: prixBarreTTC,
          })
        );
      });
      let count = await this.produitService
        .count({
          where: `{"and":[{"categorieId":"${cat.id}"},{"actif":true},{"stock":{"gt":0}}]}`,
        })
        .toPromise();
      tabCats!.push({
        id: cat.id,
        parent: cat.parent,
        cat: cat.etiquette,
        ordre: cat.id === this.catPage.id ? cat.ordre : cat.ordre! + 100 * cmpt,
        description: cat.description,
        produits: produitsMod,
        limit: this.configBoutique.genNbrProduitCat,
        nbrProduits: count.count,
      });
    });
    return tabCats.sort((a, b) => {
      return this.compare(a.ordre, b.ordre, true);
    });
  }
  compare(a: number | string, b: number | string, isAsc: boolean) {
    return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
  }
}
