import {
  Component,
  OnInit,
  Inject,
  PLATFORM_ID,
  OnDestroy,
} from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { Article } from 'src/api/models';
import { Config, CONFIG } from '../../../../config';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { Router, ActivatedRoute } from '@angular/router';
/* import {
  AdvancedLayout,
  Image,
  PlainGalleryConfig,
  PlainGalleryStrategy,
} from '@ks89/angular-modal-gallery' */
import { DomSanitizer, Meta, Title } from '@angular/platform-browser';
import { isPlatformBrowser } from '@angular/common';
import { ConfigPartial as ConfigSite } from 'src/api/models';
import {
  ArticlesControllerService,
  ConfigControllerService,
} from 'src/api/services';
@Component({
  selector: 'app-article',
  templateUrl: './article.component.html',
  styleUrls: ['./article.component.scss', '../styles.scss'],
})
export class ArticleComponent implements OnInit, OnDestroy {
  article!: Article;
  subArticle!: Subscription;
  config: Config = CONFIG;
  httpOptions!: { headers: HttpHeaders };
  paramsSub!: Subscription;
  idSTR: any;
  /*  images!: Image[];
  customPlainGalleryRowConfig: PlainGalleryConfig = {
    strategy: PlainGalleryStrategy.CUSTOM,
    layout: new AdvancedLayout(-1, true)
  }; */
  testBrowser: boolean;
  title: string = '';
  lat: number;
  lng: number;
  public configSite!: ConfigSite;
  subConfig!: Subscription;

  /* openImageModalRow(image: Image) {
    // console.log('Opening modal gallery from custom plain gallery row and description, with image: ', image);
    const index: number = this.getCurrentIndexCustomLayout(image, this.images);
    this.customPlainGalleryRowConfig = Object.assign({},
      this.customPlainGalleryRowConfig,
      { layout: new AdvancedLayout(index, true) });
  }
  private getCurrentIndexCustomLayout(image: Image, images: Image[]): number {
    return image ? images.indexOf(image) : -1;
  } */
  constructor(
    @Inject(PLATFORM_ID) platformId: string,
    private http: HttpClient,
    private readonly meta: Meta,
    private titre: Title,
    private router: Router,
    private route: ActivatedRoute,
    private configService: ConfigControllerService,
    private articlService: ArticlesControllerService
  ) {
    this.testBrowser = isPlatformBrowser(platformId);
    this.lat = 51.678418;
    this.lng = 7.809007;
  }

  ngOnInit() {
    this.paramsSub = this.route.params
      .pipe(map((params) => params['url']))
      .subscribe((url) => {
        this.idSTR = url;
        this.subConfig = this.configService.findd4().subscribe((config) => {
          this.configSite = config;
          this.subArticle = this.articlService
            .find({ filter: { filter: `{"where":{"url":"${url}"}}` } })
            .subscribe((data) => {
              this.article = data[0];
              //lazy-loading
              this.article.html = this.article.html
                ? this.article.html
                    .replace(/<img /gi, '<img class="lazyload" ')
                    .replace(/(<img[^>]*) src=/gi, '$1data-src=')
                : '';
              // metas
              const Tags = [
                "name='description'",
                "property= 'og:url'",
                "property='og:title'",
                "property='og:description'",
                "property='og:image'",
                "property='og:locale'",
                "property='og:type'",
                "property='og:site_name'",
                "name='twitter:card'",
                "name='twitter:description'",
                "name='twitter:title'",
                "name='twitter:image'",
              ];
              Tags.map((e) => this.meta.removeTag(e));
              const title = this.article.titre + ' - ' + this.configSite.title!;
              const description = this.article.description || '';
              this.titre.setTitle(title);
              let ogimage = this.article.ogimage || this.config.imageUrl || '';
              this.meta.addTags(
                [
                  { name: 'description', content: description },
                  {
                    property: 'og:url',
                    content:
                      this.config.applicationUrl + '/article/' + this.idSTR,
                  },
                  { property: 'og:title', content: title },
                  { property: 'og:description', content: description },
                  { property: 'og:image', content: ogimage },
                  { property: 'og:locale', content: 'fr_FR' },
                  { property: 'og:type', content: 'website' },
                  {
                    property: 'og:site_name',
                    content: this.configSite.title!,
                  },
                  { name: 'twitter:card', content: 'summary' },
                  { name: 'twitter:description', content: description },
                  { name: 'twitter:title', content: title },
                  { name: 'twitter:image', content: ogimage },
                ],
                true
              );
            });
        });
      });
  }
  ngOnDestroy() {
    if (this.subArticle) {
      this.subArticle.unsubscribe();
    }
    if (this.paramsSub) {
      this.paramsSub.unsubscribe();
    }
    if (this.subConfig) {
      this.subConfig.unsubscribe();
    }
  }
}
