<div id="mainlogin">
<form [formGroup]="loginForm" (ngSubmit)="login();">
  <mat-card class="form-signin">
    <mat-card-header>
      <mat-card-title style="font-size: 24px;">Connectez-vous </mat-card-title>
    </mat-card-header>
    <mat-card-content>
      <p>
        <mat-form-field class="full-width" appearance="outline">
          <mat-label>Nom d'utilisateur</mat-label>
          <input matInput id="username" placeholder="Utilisateur" type="text" formControlName="username">
        </mat-form-field>
      </p>
      <p>
        <mat-form-field class="full-width" appearance="outline">
          <mat-label>Mot de passe</mat-label>
          <input matInput id="password" placeholder="Mot de passe" [type]="hide ? 'password' : 'text'"
            formControlName="password" autocomplete="new-password">
          <mat-icon matSuffix (click)="hide = !hide">{{hide ? 'visibility' : 'visibility_off'}}</mat-icon>
        </mat-form-field>
      </p>
    </mat-card-content>
    <mat-card-actions style="text-align:end"><button mat-raised-button color="primary" [disabled]="!loginForm.valid"
        type="submit">OK</button></mat-card-actions>
    <mat-error [hidden]="error == ''" style="text-align: center;">

      {{ error }}

    </mat-error>
  </mat-card>
</form>
</div>