import {
  Component,
  OnInit,
  OnDestroy,
  Inject,
  PLATFORM_ID,
  Input,
} from '@angular/core';
import { Config, CONFIG } from '../../../config';
import { Subscription, Observable } from 'rxjs';

import {
  ConfigBoutique,
  ConfigPartial as ConfigSite,
  Produit,
} from 'src/api/models';
/* import {
  AdvancedLayout,
  Image,
  PlainGalleryConfig,
  PlainGalleryStrategy,
} from '@ks89/angular-modal-gallery'; */
import { map } from 'rxjs/operators';
import * as moment from 'moment';
import { isPlatformBrowser } from '@angular/common';
import { Meta, Title } from '@angular/platform-browser';
import { HttpClient } from '@angular/common/http';
import { Page, Agenda, Article } from 'src/api/models';
import {
  PagesControllerService,
  ConfigControllerService,
  ConfigBoutiqueControllerService,
  ProduitControllerService,
  TvaControllerService,
  ArticlesControllerService,
} from 'src/api/services';
import { PagesComponent } from './pages.component';
import { ProduitAff } from 'src/models';
/**RESTE A FAIRE :
 *
 * Actu
 * Selection
 * Cat Catalogue
 *
 */
@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./styles.scss', './home.component.scss'],
})
export class HomeComponent implements OnInit, OnDestroy {
  public page!: Page;
  config: Config = CONFIG;
  PagesComponent!: PagesComponent;
  public configSite!: ConfigSite;
  subConfig!: Subscription;
  /* images: Image[]=[];
  customPlainGalleryRowConfig: PlainGalleryConfig = {
    strategy: PlainGalleryStrategy.CUSTOM,
    layout: new AdvancedLayout(-1, true),
  };
 */
  journal: Article[] = [];
  limiteArticleHome: number=5;
  agenda: Agenda[] = [];
  testBrowser: boolean;
  subPage!: Subscription;
  subJournal!: Subscription;
  subAgenda!: Subscription;
  subConfigBoutique!: Subscription;
  agendaTest: boolean = false;
  journalTest: boolean = false;
  stars: any[any] = [];
  produitsStars: ProduitAff[] = [];
  starsEtiquette: string = 'Produits Stars';
  ligne: boolean = false;

  /*  openImageModalRow(image: Image) {
    const index: number = this.getCurrentIndexCustomLayout(image, this.images);
    this.customPlainGalleryRowConfig = Object.assign(
      {},
      this.customPlainGalleryRowConfig,
      { layout: new AdvancedLayout(index, true) }
    );
  } */
  /*  private getCurrentIndexCustomLayout(image: Image, images: Image[]): number {
    return image ? images.indexOf(image) : -1;
  } */
  constructor(
    @Inject(PLATFORM_ID) platformId: string,
    private readonly meta: Meta,
    private titre: Title,
    private http: HttpClient,
    private pagesService: PagesControllerService,
    private configService: ConfigControllerService,
    private configBoutiqueService: ConfigBoutiqueControllerService,
    private produitService: ProduitControllerService,
    private tvaService: TvaControllerService,
    private articlService: ArticlesControllerService
  ) {
    this.testBrowser = isPlatformBrowser(platformId);
  }

  ngOnInit() {
    this.limiteArticleHome = this.config.limites.articleHome;
    this.subConfig = this.configService.findd4().subscribe((config) => {
      this.configSite = config;
      this.subPage = this.pagesService
        .find({ filter: { filter: `{"where": { "type": "HOME" } }` } })
        .subscribe((page) => {
          this.page = page[0];
          //lazy-loading
          this.page.html = this.page.html
            ? this.page.html
                .replace(/<img /gi, '<img class="lazyload" ')
                .replace(/(<img[^>]*) src=/gi, '$1data-src=')
            : '';
          // metas
          const Tags = [
            "name='description'",
            "property= 'og:url'",
            "property='og:title'",
            "property='og:description'",
            "property='og:image'",
            "property='og:locale'",
            "property='og:type'",
            "property='og:site_name'",
            "name='twitter:card'",
            "name='twitter:description'",
            "name='twitter:title'",
            "name='twitter:image'",
          ];
          Tags.map((e) => this.meta.removeTag(e));
          const title = this.page.titre + ' - ' + this.configSite.title!;
          const description = this.page.description || '';
          this.titre.setTitle(title);
          let ogimage = this.page.ogimage || this.config.imageUrl || '';
          this.meta.addTags([
            { name: 'description', content: description },
            { property: 'og:url', content: this.config.applicationUrl },
            { property: 'og:title', content: title },
            { property: 'og:description', content: description },
            { property: 'og:image', content: ogimage },
            { property: 'og:locale', content: 'fr_FR' },
            { property: 'og:type', content: 'website' },
            {
              property: 'og:site_name',
              content: this.configSite.title!,
            },
            { name: 'twitter:card', content: 'summary' },
            { name: 'twitter:description', content: description },
            { name: 'twitter:title', content: title },
            { name: 'twitter:image', content: ogimage },
          ]);
        });
    });
    this.subConfigBoutique = this.configBoutiqueService
      .findd4()
      .subscribe((confBoutique) => {
        this.ligne = confBoutique.ligne!;
        this.produitsStars = [];
        if (this.ligne) {
          this.getProduitsSatrs().then((out) => {
            out.tabstars
              .filter((el) => {
                return el != null;
              })
              .map((p) => {
                this.calcPrixTTC(p.tvaId, p.prixHT, p.prixbarre? p.prixbarre:0).then(
                  (out) => {
                    const prixTTC = out.ttc;
                    const prixBarreTTC = out.ttcbarre;
                    this.produitsStars.push(
                      Object.assign(p, {
                        prixTTC: prixTTC,
                        prixBarreTTC: prixBarreTTC,
                      })
                    );
                  }
                );
              });
            this.starsEtiquette = out.starsEtiquette;
          });
        }
      });
    /* this.subJournal = this.getLastArticle(this.limiteArticleHome).subscribe(
        (dataJ) => {
          if (dataJ) {
            this.journal = dataJ;
            this.journalTest = !!dataJ.length;
          }
        }
      ); */
    const limit = this.limiteArticleHome | 5;
    const skip = 0;
    const limitStr = `,"skip":${skip},"limit":${limit}`;
    const maintenant = moment().utc().format();
    this.subJournal = this.articlService
      .find({
        filter: {
          filter: `{"order": "parution DESC" ${limitStr},"where":{"and":[{"parution":{"lte": "${maintenant}"}},{"archive":"false"}]}}`,
        },
      })
      .subscribe((dataJ) => {
        if (dataJ) {
          this.journal = dataJ;
          this.journalTest = !!dataJ.length;
        }
      });
    /* this.subAgenda = this.getAgenda().subscribe((data) => {
        // console.log(data);
        this.agenda = data;
        this.agendaTest =
          !!Object(data).entries.length && data.constructor === Object;
      }); */
  }
  ngOnDestroy(): void {
    if (this.subConfig) {
      this.subConfig.unsubscribe();
    }
    if (this.subJournal) {
      this.subJournal.unsubscribe();
    }
    if (this.subAgenda) {
      this.subAgenda.unsubscribe();
    }
    if (this.subPage) {
      this.subPage.unsubscribe();
    }
    if (this.subConfigBoutique) {
      this.subConfigBoutique.unsubscribe();
    }
  }

  /* getLastArticle(limite: number): Observable<any> {
    const url =
      this.config.urlApi +
      '/api/articles?filter=' +
      encodeURIComponent(
        '{"where":{"and":[{"parution":{"lte": "' +
          moment().utc().format() +
          '"}},{"archive":"false"}]},"order":"parution DESC","limit":' +
          limite +
          '}'
      );
    return this.http.get(url).pipe(map((res) => res));
  } */
  getAgenda(): Observable<any> {
    const url =
      this.config.urlApi +
      '/api/agendas?filter=' +
      encodeURIComponent(
        '{"where":{"and":[{"dateau":{"gte":"' +
          moment().utc().format() +
          '"}},{"parution":{"lte":"' +
          moment().utc().format() +
          '"}}]},"order":"dateau DESC"}'
      );
    return this.http.get(url).pipe(map((res) => res));
  }
  async getProduitsSatrs(): Promise<{
    tabstars: Produit[];
    starsEtiquette: string;
  }> {
    let tabStars: any[any] = [];
    const stars = await this.getStars().then((out) => {
      return out;
    });
    const res = await this.produitService
      .find({
        filter: {
          filter: `{ "where": {"and":[{ "id": { "inq": ["${stars.produitStar!.join(
            '","'
          )}"] } },{"actif":"true"},{"stock":{"gt":"0"}} ]}}`,
        },
      })
      .toPromise();
    res.map((star) => {
      tabStars[stars.produitStar!.indexOf(star.id!)] = star;
    });
    return { tabstars: tabStars, starsEtiquette: stars.produitStarNom! };
  }
  async getStars(): Promise<ConfigBoutique> {
    const res = await this.configBoutiqueService.findStars().toPromise();
    return res;
  }
  async calcPrixTTC(
    idTva: string,
    ht: number,
    htBarre: number
  ): Promise<{ ttc: number; ttcbarre: number }> {
    const tva = await this.tvaService.findById({ id: idTva }).toPromise();
    return {
      ttc: ht + (ht * tva.taux) / 100,
      ttcbarre: htBarre + (htBarre * tva.taux) / 100,
    };
  }
}
