import { isPlatformBrowser } from '@angular/common';
import {
  Component,
  Inject,
  OnDestroy,
  OnInit,
  PLATFORM_ID,
  ViewChild,
} from '@angular/core';
import { MatTable } from '@angular/material/table';
import { Meta, Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { CONFIG, Config } from 'config';
import { Subscription } from 'rxjs';
import {
  Categorie,
  ConfigPartial as ConfigSite,
  ConfigBoutique,
  Tva,
  PageWithRelations,
} from 'src/api/models';
import {
  AttribusControllerService,
  ConfigBoutiqueControllerService,
  ConfigControllerService,
  PageSpecControllerService,
  ProduitControllerService,
  TvaControllerService,
} from 'src/api/services';
import {
  attributCommande,
  panierCookies,
  ProduitAff,
  produitCookies,
} from 'src/models';

@Component({
  selector: 'app-panier',
  templateUrl: './panier.component.html',
  styleUrls: ['./../styles.scss', './panier.component.scss'],
})
export class PanierComponent implements OnInit, OnDestroy {
  configBoutique: ConfigBoutique = {
    genNbrProduitCat: 10,
    genNbrProduitStar: 10,
    d4: 'd4',
  };
  produits: ProduitAff[] = [];
  categories: Categorie[] = [];
  tvas: Tva[] = [];
  stars: any[any] = [];
  public configSite!: ConfigSite;
  config: Config = CONFIG;
  subConfigBoutique!: Subscription;
  subProduits!: Subscription;
  subPage!: Subscription;
  page!: PageWithRelations;
  subConfig!: Subscription;
  subCat!: Subscription;
  subTva!: Subscription;
  tabTva: any[any] = [];
  displayedColumns: string[] = [
    'ref',
    'produit',
    'puttc',
    'attributs',
    'nbr',
    'pttc',
  ];
  public panierTemp: boolean = false;
  public panier: panierCookies = { date: Date.now(), produits: [] };
  cptPanier: number = 0;
  @ViewChild(MatTable) table!: MatTable<produitCookies>;
  testBrowser: boolean;
  constructor(
    @Inject(PLATFORM_ID) platformId: string,
    private readonly meta: Meta,
    private titre: Title,
    private router: Router,
    private pagesService: PageSpecControllerService,
    private configBoutiqueService: ConfigBoutiqueControllerService,
    private configService: ConfigControllerService,
    private produitService: ProduitControllerService,
    private tvaService: TvaControllerService,
    private attribuService: AttribusControllerService
  ) {
    this.testBrowser = isPlatformBrowser(platformId);
  }

  ngOnInit(): void {
    this.subConfigBoutique = this.configBoutiqueService
      .findd4()
      .subscribe((confBoutique) => {
        if (!confBoutique.ligne!) {
          this.router.navigate(['/']);
        }
      });
    this.subConfig = this.configService.findd4().subscribe((config) => {
      this.configSite = config;
      this.subPage = this.pagesService
        .find({ filter: { filter: `{"where": { "type": "PANIER" } }` } })
        .subscribe((page) => {
          this.page = page[0];
          //lazy-loading
          this.page.html = this.page.html
            ? this.page.html
                .replace(/<img /gi, '<img class="lazyload" ')
                .replace(/(<img[^>]*)src=/gi, '$1data-src=')
            : '';
          // metas
          const Tags = [
            "name='description'",
            "property= 'og:url'",
            "property='og:title'",
            "property='og:description'",
            "property='og:image'",
            "property='og:locale'",
            "property='og:type'",
            "property='og:site_name'",
            "name='twitter:card'",
            "name='twitter:description'",
            "name='twitter:title'",
            "name='twitter:image'",
          ];
          Tags.map((e) => this.meta.removeTag(e));
          const title = this.page.titre + ' - ' + this.configSite.title!;
          const description = this.page.description || '';
          this.titre.setTitle(title);
          let ogimage = this.page.ogimage || this.config.imageUrl || '';
          this.meta.addTags([
            { name: 'description', content: description },
            { property: 'og:url', content: this.config.applicationUrl },
            { property: 'og:title', content: title },
            { property: 'og:description', content: description },
            { property: 'og:image', content: ogimage },
            { property: 'og:locale', content: 'fr_FR' },
            { property: 'og:type', content: 'website' },
            {
              property: 'og:site_name',
              content: this.configSite.title!,
            },
            { name: 'twitter:card', content: 'summary' },
            { name: 'twitter:description', content: description },
            { name: 'twitter:title', content: title },
            { name: 'twitter:image', content: ogimage },
          ]);
          if (this.testBrowser)
            this.depilPanier().then((e) => {
              this.panier = e;
              //console.log(e);
            });
        });
    });
  }
  ngOnDestroy(): void {
    if (this.subCat) this.subCat.unsubscribe();
    if (this.subConfig) this.subConfig.unsubscribe();
    if (this.subConfigBoutique) this.subConfigBoutique.unsubscribe();
    if (this.subPage) this.subPage.unsubscribe();
    if (this.subProduits) this.subProduits.unsubscribe();
    if (this.subTva) this.subTva.unsubscribe();
  }
  async depilPanier(): Promise<panierCookies> {
    const panierRecup: panierCookies = await JSON.parse(
      localStorage.getItem('proPulsePanier')!
    );
    let trueProduits: produitCookies[] = [];
    this.cptPanier = panierRecup.produits.length;
    await Promise.all(
      panierRecup.produits.map(async (lign) => {
        await this.verifLignPanier(
          lign.id,
          lign.nbr,
          lign.attributs,
          lign.nbrHorsAttributs!
        ).then((e) => {
          trueProduits.push(e);
        });
      })
    );
    panierRecup.produits = trueProduits;
    /* localStorage.setItem('proPulsePanier', JSON.stringify(panierRecup)); */
    return panierRecup;
  }
  async verifLignPanier(
    id: string,
    nbr: number,
    attributs: attributCommande[],
    nbrHorsAttributs: number
  ): Promise<produitCookies> {
    const ligne = await this.produitService.findById({ id: id }).toPromise();
    const majoration: number = await this.calcMaj(attributs);
    const TRUELIGNE = await this.calcPrixTTC(
      ligne.tvaId,
      ligne.prixHT,
      ligne.prixbarre!
    ).then((TTC) => {
      const prixTTC = TTC.ttcbarre > 0 ? TTC.ttcbarre : TTC.ttc;
      const trueLigne: produitCookies = {
        id: ligne.id!,
        ref: ligne.ref,
        url: ligne.url,
        unite: ligne.unite,
        nom: ligne.nom,
        prixHt: ligne.prixHT,
        prixTTC: prixTTC,
        attributs: attributs,
        majoration: majoration,
        nbr: nbr,
        prixTotalTTC: (prixTTC + majoration) * nbr,
        stock: ligne.stock,
        min: ligne.minUnite!>0?ligne.minUnite:1,
        nbrHorsAttributs: nbrHorsAttributs,
      };
      return trueLigne;
    });
    return TRUELIGNE;
  }
  async calcMaj(attributs: attributCommande[]): Promise<number> {
    let maj = 0;
    await Promise.all(
      attributs.map(async (attr) => {
        const trueAttributs = await this.attribuService
          .findById({ id: attr.id })
          .toPromise();
        const choix = attr.choix;
        const plusValue = Number(
          trueAttributs.prixmods[trueAttributs.valeurs.indexOf(choix)]
        );
        maj += plusValue;
      })
    );
    return maj;
  }
  async calcPrixTTC(
    idTva: string,
    ht: number,
    htBarre: number
  ): Promise<{ ttc: number; ttcbarre: number }> {
    const tva = await this.tvaService.findById({ id: idTva }).toPromise();
    return {
      ttc: ht + (ht * tva.taux) / 100,
      ttcbarre: htBarre + (htBarre * tva.taux) / 100,
    };
  }
  getTotalCost() {
    return this.panier.produits
      .map((t) => t.prixTotalTTC)
      .reduce((acc, value) => acc + value, 0);
  }
  addPanier(id: string, nbr: number, attributs: attributCommande[]) {
    let tempnbrHorsAttributs = 0;
    this.panier.produits.map((e, i) => {
      if (e.id === id) {
        if (JSON.stringify(e.attributs) === JSON.stringify(attributs)) {
          if (e.nbrHorsAttributs! < e.stock! || nbr === -1) e.nbr += nbr;
          e.prixTotalTTC = (e.prixTTC + e.majoration) * e.nbr;
          if (e.nbr < Math.max(e.min!, 1)) this.panier.produits.splice(i, 1);
        }
        if (tempnbrHorsAttributs! < e.stock! || nbr === -1)
          tempnbrHorsAttributs += e.nbr;
      }
    });
    this.panier.produits.map((e) => {
      if (e.id === id) {
        e.nbrHorsAttributs = tempnbrHorsAttributs;
      }
    });

    if (this.testBrowser)
      localStorage.setItem('proPulsePanier', JSON.stringify(this.panier));
    this.table.renderRows();
  }
  valid() {
    this.router.navigate([this.config.lienSpec.CATALOGUE, 'commande', 'verif']);
  }
}
