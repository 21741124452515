import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AuthenticationService } from '../services';
import { Title } from '@angular/platform-browser';
import { User } from 'src/api/models/user';
import { HttpHeaders } from '@angular/common/http';
import { UserControllerService } from 'src/api/services';
import { tokenUser } from 'src/models';
import { Observable } from 'rxjs';

@Component({
  templateUrl: 'login.component.html',
  styles: [
    `
      #mainlogin {
        background: #ccc;
        margin: 0;
        padding: 10vh 0 0 0;
        height: 90vh;
      }
      .form-signin {
       
        max-width: 450px;
        margin: auto;
      }
      .full-width {
        width: 100%;
      }
      mat-form-field.mat-form-field {
        font-size: 18px !important;
      }
    `,
  ],
})
export class LoginComponent implements OnInit {
  loginForm!: FormGroup;
  error: string = '';
  user!: User;
  loading = false;
  returnUrl: string = '';
  hide = true;
  httpOptions!: { headers: HttpHeaders };

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private formBuilder: FormBuilder,
    private authenticationService: AuthenticationService,
    private userService: UserControllerService,
    private titre: Title
  ) {}

  ngOnInit() {
    //this.authenticationService.reset();
    this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/admin';
    this.loginForm = this.formBuilder.group({
      username: ['', Validators.required],
      password: ['', Validators.required],
    });

    this.error = '';
    this.titre.setTitle('Connectez-vous');
  }

  login() {
    if (this.loginForm.valid) {
      this.authenticationService
        .login(this.loginForm.value.username, this.loginForm.value.password)
        .subscribe(
          (user) => {
            if (user && user.token) {
              localStorage.setItem('currentUser', JSON.stringify(user));
              this.router.navigate([this.returnUrl]);
            } else {
              this.error =
                "Connexion Impossible : Vérifiez votre Nom d'utilisateur et votre Mot de Passe !";
            }
          },
          (error) => {
            this.error =
              "Connexion Impossible : Vérifiez votre Nom d'utilisateur et votre Mot de Passe !";
          }
        );
    }
  }
}
