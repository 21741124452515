<div id="pages">
  <div id="container" class="container mat-elevation-z5">
    <header class="header" role="banner" fxShow="true" fxHide.lt-sm="true"> <a [routerLink]="['/']"><img
          [src]="config.applicationUrl+config.headerUrl" [alt]="config.applicationUrl+config.title">
      </a>
      <div *ngIf="ligne" class="modCatalogue" fxLayout="row wrap" fxLayoutAlign="end center">
        <form (ngSubmit)="cherche();" class="cherch">
          <mat-form-field matTooltip="Commencez à saisir puis sélectionnez un produit dans la liste.">

            <mat-label>Rechercher un produit</mat-label>
            <mat-icon color="primary" matPrefix>search</mat-icon><input type="text" placeholder="Intitulé"
              aria-label="Trouver par Intitulé" matInput [formControl]="rechControl" [matAutocomplete]="auto"
              minlength="4">
            <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn" panelWidth="auto">
              <mat-option *ngFor="let option of filteredOptions | async " [value]="option" (click)="cherche()" style="overflow: visible;
              line-height: initial;
              height: fit-content;
              word-wrap: break-word;
              white-space: pre-wrap;">
                {{option.nom}}<br><span
                  style="font-size: x-small;">{{option.resume}}</span>
              </mat-option>
            </mat-autocomplete>
          </mat-form-field>

        </form>
        <a [routerLink]="[config.lienSpec.CATALOGUE, 'panier']" matTooltip="Voir mon Panier">
          <mat-icon class="panierbtn material-icons-outlined" [matBadge]="nbrArtPanier"
            [matBadgeHidden]="nbrArtPanier==0" matBadgePosition="above after" matBadgeColor="accent">
            shopping_cart
          </mat-icon>
        </a>
      </div>

    </header>
    <header class="headermob" fxShow="true" fxHide.gt-xs="true">
      <div *ngIf="ligne" class="modCataloguemob" fxLayout="row" fxLayoutAlign="end center">
        <form (ngSubmit)="cherche();" class="cherch">
          <mat-form-field style="margin-bottom: -1.25em" color="accent"
            matTooltip="Commencez à saisir puis sélectionnez un produit dans la liste.">

            <mat-label>Rechercher un produit</mat-label>
            <mat-icon matPrefix>search</mat-icon><input type="text" placeholder="Intitulé"
              aria-label="Trouver par Intitulé" matInput [formControl]="rechControl" [matAutocomplete]="auto"
              minlength="4">
            <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn" panelWidth="auto">
              <mat-option *ngFor="let option of filteredOptions | async " [value]="option" (click)="cherche()" style="overflow: visible;
              line-height: initial;
              height: fit-content;
              word-wrap: break-word;
              white-space: pre-wrap;">
                {{option.nom}}<br><span
                  style="font-size: x-small;">{{option.resume}}</span>
              </mat-option>
            </mat-autocomplete>
          </mat-form-field>

        </form>
        <a [routerLink]="[config.lienSpec.CATALOGUE, 'panier']" matTooltip="Voir mon Panier">
          <mat-icon class="panierbtn material-icons-outlined" [matBadge]="nbrArtPanier"
            [matBadgeHidden]="nbrArtPanier==0" matBadgePosition="above after" matBadgeColor="accent">
            shopping_cart
          </mat-icon>
        </a>
      </div>
    </header>
    <div>
      <mat-toolbar class="navBar">

        <div fxShow="true" fxHide.gt-sm="true">

          <a mat-menu-item (click)="sidenav.toggle()">
            <mat-icon class="menuicon">menu</mat-icon>
          </a>

        </div>



        <div fxShow="true" fxHide.lt-md="true" fxLayout="row" fxLayoutGap="10px" fxLayoutAlign="center center">
          <span *ngFor="let anchor of anchors">
            <mat-menu #SsMenu="matMenu">
              <span *ngIf="anchor && anchor.ss">
                <span *ngFor="let ssanchor of anchor.ss">
                  <a title="{{ssanchor.description}}" mat-menu-item *ngIf="ssanchor"
                    [routerLink]="ssanchor.type==='PAGE' || ssanchor.type==='CONTACT'?['/page',ssanchor.lien]:['/',ssanchor.type]"
                    routerLinkActive="active">{{ssanchor.titre}}</a>
                </span>
              </span>
            </mat-menu>
            <a title="{{anchor.description}}" *ngIf="anchor && anchor.ss" mat-button
              [matMenuTriggerFor]="SsMenu">{{anchor.titre}}</a>

            <a title="{{anchor.description}}" *ngIf="anchor && !anchor.ss" mat-button
              [routerLink]="anchor.type==='PAGE' || anchor.type==='CONTACT'?['/page',anchor.lien]:(anchor.type=='HOME'?['/']:['/',anchor.type])"
              routerLinkActive="active" [routerLinkActiveOptions]="{exact:
              true}">{{anchor.titre}}</a>
          </span>
          <!-- *ngIf="clientActif" --> <a [routerLink]="['/',config.lienSpec.CATALOGUE,'client','moncompte',]"
            title="Mon compte" mat-button><mat-icon>
              person
            </mat-icon></a>
        </div><span class="spacer"></span><span fxShow="true" fxHide.gt-sm="true"
          fxHide.lt-sm="true">{{configSite?.enseigne?.nom}}</span>
        <img fxShow="true" fxHide.gt-xs="true" [src]="config.applicationUrl+config.headerMobUrl"
          [alt]="config.applicationUrl+config.title">

      </mat-toolbar>

      <mat-sidenav-container class="all">
        <mat-sidenav #sidenav fxLayout="column" class="menumob" fxLayoutAlign="center center">
          <!-- <div fxLayout="column"> -->

          <ul>
            <!-- *ngIf="clientActif" -->
            <li><a [routerLink]="['/',config.lienSpec.CATALOGUE,'client','moncompte',]" title="Mon compte" mat-button
                (click)="sidenav.toggle()"><mat-icon>
                  person
                </mat-icon>Mon Compte</a></li>
            <li *ngFor="let anchor of anchors"> <a title="{{anchor.description}}" *ngIf="anchor && !anchor.ss"
                mat-button
                [routerLink]="anchor.type==='PAGE' || anchor.type==='CONTACT'?['/page',anchor.lien]:(anchor.type=='HOME'?['/']:['/',anchor.type])"
                routerLinkActive="active" [routerLinkActiveOptions]="{exact:true}"
                (click)="sidenav.toggle()">{{anchor.titre}}</a>
              <!--  -->
              <mat-menu #SsMenuMob="matMenu" xPosition="after" yPosition="below">
                <span *ngIf="anchor && anchor.ss">
                  <span *ngFor="let ssanchor of anchor.ss">
                    <a title="{{ssanchor.description}}" mat-menu-item *ngIf="ssanchor"
                      [routerLink]="ssanchor.type==='PAGE' || ssanchor.type==='CONTACT'?['/page',ssanchor.lien]:['/',ssanchor.type]"
                      routerLinkActive="active">{{ssanchor.titre}}</a>
                  </span>
                </span>
              </mat-menu>
              <a title="{{anchor.description}}" *ngIf="anchor && anchor.ss" mat-button
                [matMenuTriggerFor]="SsMenuMob">{{anchor.titre}}</a>

            </li>
          </ul>

        </mat-sidenav>

        <mat-sidenav-content class="parent">

          <main>


            <router-outlet> </router-outlet>
          </main>
          <footer>
            <mat-icon class="copycon">copyright</mat-icon>
            <a href="https://www.digitalatelier.fr">digitalAtelier.fr 2020 - Création de site internet - Informatique -
              Bapaume</a> | <a [routerLink]="['/infos','mentions-legales']">Mentions légales</a>
          </footer>

        </mat-sidenav-content>

      </mat-sidenav-container>
    </div>
  </div>