/* tslint:disable */
import { ActivatedRoute, Router } from '@angular/router';
import {
  FileUploadControllerService,
  FileDownloadControllerService,
} from 'src/api/services';
import {
  Component,
  OnInit,
  Inject,
  PLATFORM_ID,
  Input,
  ViewChild,
  ElementRef,
} from '@angular/core';
import { HttpErrorResponse, HttpEventType } from '@angular/common/http';
import { Config, CONFIG } from '../../../config';
import { CurrentUser, tokenUser } from 'src/models';

import { isPlatformBrowser } from '@angular/common';
import { MatDialog } from '@angular/material/dialog';
import { of, Subscription } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { UploadService } from '../services/';

@Component({
  selector: 'app-explore',
  templateUrl: './explore.component.html',
  styles: [
    `#file-label {margin: auto}
    ul,
      li {
        margin: 0;
        padding: 0;
        list-style: none;
        text-align:center;
      }
    `,
  ],
  styleUrls: ['./admin.component.scss'],
})
export class ExploreComponent implements OnInit {
  @ViewChild('fileUpload', { static: false }) fileUpload!: ElementRef;
  files: any[any] = [];
  //Controle User
  user!: CurrentUser;
  role!: string;
  config: Config = CONFIG;
  droitsUser: Array<string> = [];
  ///////
  testBrowser: boolean;
  section: any;
  idSTR: any;
  paramsSub!: Subscription;
  efSub!: Subscription;
  dfSub!: Subscription;
  public images: any[any] = [];
  public hasBaseDropZoneOver = false;
  SERVER_URL: string = '';
  split: any;
  public fileOverBase(e: any): void {
    this.hasBaseDropZoneOver = e;
  }
  public cont: string | undefined = 'files';
  @Input() impass: string = '';
  constructor(
    @Inject(PLATFORM_ID) platformId: string,
    private uploadService: UploadService,
    private fileD: FileDownloadControllerService,
    private fileU: FileUploadControllerService,
    private router: Router,
    private route: ActivatedRoute,
    public dialog: MatDialog
  ) {
    this.testBrowser = isPlatformBrowser(platformId);
    if (this.testBrowser) {
      const TT: tokenUser = JSON.parse(localStorage.getItem('currentUser')!);
      this.user = TT.thisUser;
      this.droitsUser = this.config.DROITS[this.user.role];
      if (this.droitsUser.indexOf('catalogue') === -1)
        this.router.navigate(['/admin']);
    }
  }

  ngOnInit() {
    this.paramsSub = this.route.params
      .pipe(map((params) => params['cont']))
      .subscribe((cont) => {
        this.split = cont.split('.');
        this.cont = this.split[0].replace(':', '/');
        const repRacine = this.cont?.split('/')[0];
        const tailleMax = repRacine
          ? this.config.tailleImages_max[repRacine!]
          : 450;

        this.SERVER_URL =
          this.config.urlApi +
          '/files?destination=' +
          (this.cont ? this.cont : 'files') +
          '&taillemax=' +
          tailleMax;
        this.refreshImages();
      });
  }
  refreshImages() {
    this.images = [];
    if (this.efSub) {
      this.efSub.unsubscribe();
    }
    this.efSub = this.fileD
      .listFiles({ folder: this.cont })
      .subscribe((data) => {
        data.map((res) => {
          if (!res.name!.match(/^(\.|_min.|_med.|_max.)/))
            if (!res.folder) this.images.push(res);
        });
      });
  }
  getSrc(container: string, file: string, prefix?: string) {
    const fichier = prefix ? prefix + file : file;
    return this.getFile(container, fichier);
  }
  getMed(container: string, file: string, prefix?: string) {
    const fichier = prefix ? prefix + file : file;
    const r = new RegExp(/(.jpg|.jpeg|.gif|png|svg)$/i);
    const cond = file.match(r);
    return cond ? this.getFile(container, fichier) : './images/documents.png';
  }
  getUrlParam(paramName: string) {
    const reParam = new RegExp('(?:[?&]|&)' + paramName + '=([^&]+)', 'i');
    const match = window.location.search.match(reParam);

    return match && match.length > 1 ? match[1] : null;
  }
  returnFileUrl(file: string) {
    const funcNum = this.getUrlParam('CKEditorFuncNum');
    if (funcNum === null) {
      if (this.split[1]) {
        localStorage.setItem(
          'produitIm' + this.split[1],
          this.getSrc(this.cont!, file, '_max.')
        );
      } else {
        localStorage.setItem('pageIm', this.getSrc(this.cont!, file));
      }
      window.close();
    } else {
      window.opener!.CKEDITOR.tools.callFunction(
        funcNum,
        this.getSrc(this.cont!, file)
      );
      window.close();
    }
  }
  getFile(container: string, file: string) {
    const getFileUrl =
      this.config.applicationUrl + `/media/${container}/${file}`;
    return getFileUrl;
  }
  // delete
  delete(image: string) {
    const message =
      'Etes-vous certain de vouloir supprimer ce fichier ?' +
      '\nCette action est définitive !';
    if (confirm(message)) {
      if (this.dfSub) {
        this.dfSub.unsubscribe();
      }
      this.dfSub = this.fileU
        .fileDelete({ filename: this.cont + '/' + image })
        .subscribe(() => {
          this.dfSub = this.fileU
            .fileDelete({ filename: this.cont + '/' + `_med.${image}` })
            .subscribe();
          this.dfSub = this.fileU
            .fileDelete({ filename: this.cont + '/' + `_max.${image}` })
            .subscribe();
          setTimeout(() => {
            this.refreshImages();
          }, 1000);
        });
    } else {
      console.log('annulé');
    }
    /* }); */
  }

  uploadFile(file: any) {
    const formData = new FormData();
    formData.append('file', file.data);
    file.OK='';
    file.inProgress = true;
    this.uploadService
      .upload(this.SERVER_URL, formData)
      .pipe(
        map((event) => {
          switch (event.type) {
            case HttpEventType.UploadProgress:
              file.progress = Math.round((event.loaded * 100) / event.total!);
              console.log(event);
              break;
            case HttpEventType.Response:
              return event;
          }
          return;
        }),
        catchError((error: HttpErrorResponse) => {
          file.inProgress = false;
          return of(`${file.data.name} echec upload.`);
        })
      )
      .subscribe(
        (event: any) => {
          if (typeof event === 'object') {
            //console.log(event.body);
          }
        },
        () => {},
        () => {
          file.OK='...';
          setTimeout(() => {
            file.inProgress = false;
            file.OK= '[ OK ]';
            this.refreshImages();
          }, 3000);
        }
      );
  }
  private uploadFiles() {
    this.fileUpload.nativeElement.value = '';
    this.files.forEach((file: any) => {
      this.uploadFile(file);
    });
  }
  onClick() {
    const fileUpload = this.fileUpload.nativeElement;
    fileUpload.onchange = () => {
      for (let index = 0; index < fileUpload.files.length; index++) {
        const file = fileUpload.files[index];
        this.files.push({ data: file, inProgress: false, progress: 0 });
      }
      this.uploadFiles();
    };
    fileUpload.click();
  }
}
