<div class="cont">

    <h1> {{page?.titre}} </h1>
    <div id="page" class="dyn" [innerHtml]="page?.html | unsafe:'html'"> </div>
    <mat-card class="panierverif">
        <mat-card-header>
            <mat-card-title>Mon Panier</mat-card-title>
            <mat-card-subtitle>Merci de bien vouloir vérifiez le contenu de votre panier</mat-card-subtitle>
        </mat-card-header>
        <mat-card-content>
            <table mat-table *ngIf="panier.produits.length" [dataSource]="panier.produits"
                class="mat-elevation-z3 panier-table">
                <ng-container matColumnDef="ref">
                    <th mat-header-cell *matHeaderCellDef> Ref. </th>
                    <td mat-cell *matCellDef="let element"> {{element.ref}} </td>

                </ng-container>

                <ng-container matColumnDef="produit">
                    <th mat-header-cell *matHeaderCellDef> Produit </th>
                    <td mat-cell *matCellDef="let element"> <a
                            [routerLink]="['/',config.lienSpec.CATALOGUE, 'fiche',element.ref,element.url]">{{element.nom}}
                        </a></td>

                </ng-container>



                <ng-container matColumnDef="puttc">
                    <th mat-header-cell *matHeaderCellDef> P.U </th>
                    <td mat-cell *matCellDef="let element"> {{element.prixTTC |currency}} </td>

                </ng-container>
                <ng-container matColumnDef="attributs">
                    <th mat-header-cell *matHeaderCellDef> Choix </th>
                    <td mat-cell *matCellDef="let element">
                        <div *ngFor="let attribut of element.attributs">{{attribut.choix}}<span class="plusvalue"
                                *ngIf="attribut.plusvalue>0">+ {{attribut.plusvalue |currency}}</span></div>
                    </td>

                </ng-container>
                <ng-container matColumnDef="nbr">
                    <th mat-header-cell *matHeaderCellDef> Nbr. </th>
                    <td mat-cell *matCellDef="let element">
                        <div>{{element.nbr}} <span class="unite">({{element.unite}})</span></div>
                    </td>

                </ng-container>
                <ng-container matColumnDef="pttc">
                    <th mat-header-cell *matHeaderCellDef> Total </th>
                    <td mat-cell *matCellDef="let element"> {{element.prixTotalTTC |currency}} </td>

                </ng-container>
                <ng-container matColumnDef="disclaimer">
                    <td mat-footer-cell *matFooterCellDef colspan="6" class="total">
                        <div [class.barre]="panier.codepromo?.valeurPanier"> Total Commande: {{getTotalCost() | currency}}</div>
                        <div *ngIf="panier.codepromo?.valeurPanier">Code Promo "{{panier.codepromo?.code!}}"
                       <span class="mintxt">{{panier.codepromo?.description!}}</span> (-{{panier.codepromo?.valeurPanier |currency}})
                        Total Commande : {{getTotalCost()-panier.codepromo?.valeurPanier! | currency}}</div>
                        <div *ngIf="panier.fidelite?.valeur">Solde Fidélité
                       (-{{panier.fidelite?.valeur |currency}})
                        Total Commande : {{panier.codepromo?.valeurPanier?getTotalCost()-panier.codepromo?.valeurPanier!-panier.fidelite?.valeur!:getTotalCost()-panier.fidelite?.valeur! | currency}}</div>
                    </td>
                    <td mat-footer-cell *matFooterCellDef> </td>
                </ng-container>


                <tr mat-header-row *matHeaderRowDef="displayedColumns" class="monheader"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;" class="mestd"></tr>
                <tr mat-footer-row *matFooterRowDef="['disclaimer']"></tr>
            </table>

        </mat-card-content>
        <mat-card-actions>

            <button mat-raised-button color="accent" [routerLink]="['/',config.lienSpec.CATALOGUE,'panier']">Modifier
                mon
                Panier</button>
        </mat-card-actions>
    </mat-card>
    <mat-card class="infosverif">
        <mat-card-header>
            <mat-card-title>Ma carte de fidélité</mat-card-title>
            <mat-card-subtitle>Les commandes sont ajoutées à votre carte lorsqu'elles sont finalisées.</mat-card-subtitle>
        </mat-card-header>
        <mat-card-content>
            <!-- Carte de fidélité -->
            <div id="fidelite" class="mat-elevation-z3" style="margin: auto" >
                <div class="fidelheader">Carte de Fidélité de M./Mme {{moi?.nom | capitalize}} {{moi?.prenom |
                    capitalize}}</div>
                <div class="fidelcontent" fxLayout="row wrap" fxLayoutAlign="center start"
                    fxLayoutAlign.lt-md="center center">
                    <span class="{{fidelAFF.class}}" matTooltip="{{fidelAFF.id?'Commande '+fidelAFF.id:''}}"
                        *ngFor="let fidelAFF of arrayFidel"></span>
                </div>
                <div class="fidelresult" *ngIf="fidel.length">
                    Vous avez déjà cumulé <b>{{fidel[0]?.valeur|currency}}</b> <br>Cette somme sera déduite de vôtre
                    {{configBoutique.fidelNbrcommande!+1}}ème
                    commande !
                </div>
            </div>
            <!-- Carte de fidélité - FIN -->
        </mat-card-content>

    </mat-card>
    <mat-card class="codepromo">
        <mat-card-header>
            <mat-card-title>Code Promo</mat-card-title>
            <mat-card-subtitle>Vous avez un "Code Promo" ?<br>Saisissez-le ci-dessous puis cliquez sur "Valider le code
                Promo" pour l'ajouter à votre panier</mat-card-subtitle>
        </mat-card-header>
        <mat-card-content>

            <mat-form-field appearance="outline">
                <mat-label>Code Promo</mat-label>
                <input matInput #codepromo>
            </mat-form-field><br>
            {{codePromo}}

        </mat-card-content>
        <mat-card-actions><button mat-raised-button color="primary"
                (click)="verifPromo(codepromo.value);codepromo.value=''">Valider le code Promo</button>

        </mat-card-actions>
    </mat-card>

    <mat-card class="infosverif">
        <mat-card-header>
            <mat-card-title>Mes informations de facturation</mat-card-title>
            <mat-card-subtitle>Merci de bien vouloir vérifiez vos informations</mat-card-subtitle>
        </mat-card-header>
        <mat-card-content>
            <div class="nom">{{moi?.prenom}} {{moi?.nom}}</div>
            <div class="adresse">
                <h4>Adresse</h4>
                <p>{{moi?.adresse}}
                    <br *ngIf="moi?.adresse2!=''"> {{moi?.adresse2}}
                    <br>{{moi?.cp}} {{moi?.ville}}
                </p>
            </div>
            <div class="contact">
                <h4>Contact</h4>
                <p><b>Tél :</b> {{moi?.tel}}
                    <br><b>E-mail :</b> {{moi?.email}}
                </p>

            </div>

        </mat-card-content>
        <mat-card-actions>
            <button mat-raised-button color="accent" [routerLink]="['/',config.lienSpec.CATALOGUE,'client','mod']"
                [queryParams]="{returnUrl: snapshot}">Modifier mes informations</button>
        </mat-card-actions>
    </mat-card>
    <mat-card class="codepromo">
        <mat-card-header>
            <mat-card-title>Consignes au préparateur</mat-card-title>
            <mat-card-subtitle [innerHtml]="configBoutique?.consigneinfo | unsafe:'html'"></mat-card-subtitle>
        </mat-card-header>
        <mat-card-content>

            <mat-form-field appearance="outline" style="width:100%">
                <mat-label>Consignes au préparateur</mat-label>
                <input matInput #consigne>
            </mat-form-field>

        </mat-card-content>
    </mat-card>
    <div class="actionPanier"><button mat-raised-button color="primary" (click)="valid(consigne.value)">Je valide ma commande</button>
    </div>


</div>