import { CurrencyPipe, isPlatformBrowser } from '@angular/common';
import {
  Component,
  Inject,
  OnDestroy,
  OnInit,
  PLATFORM_ID,
  ViewChild,
} from '@angular/core';
import { MatTable } from '@angular/material/table';
import { Meta, Title } from '@angular/platform-browser';
import { Router, RouterState, RouterStateSnapshot } from '@angular/router';
import { CONFIG, Config } from 'config';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import {
  Categorie,
  ConfigPartial as ConfigSite,
  ConfigBoutique,
  Tva,
  PageWithRelations,
  CodepromoWithRelations,
  Fidelite,
} from 'src/api/models';
import {
  AttribusControllerService,
  CategoriesControllerService,
  ClientControllerService,
  CodepromoControllerService,
  ConfigBoutiqueControllerService,
  ConfigControllerService,
  PageSpecControllerService,
  ProduitControllerService,
  TvaControllerService,
  CommandeControllerService,
  FideliteControllerService,
} from 'src/api/services';
import {
  attributCommande,
  panierCookies,
  ProduitAff,
  produitCookies,
  completeClient,
} from 'src/models';

import * as moment from 'moment';
@Component({
  selector: 'app-verif',
  templateUrl: './verif.component.html',
  styleUrls: [
    './../../styles.scss',
    './../panier.component.scss',
    './verif.component.scss',
  ],
})
export class VerifComponent implements OnInit, OnDestroy {
  configBoutique: ConfigBoutique = {
    genNbrProduitCat: 10,
    genNbrProduitStar: 10,
    d4: 'd4',
  };
  produits: ProduitAff[] = [];
  categories: Categorie[] = [];
  tvas: Tva[] = [];
  stars: any[any] = [];
  public configSite!: ConfigSite;
  config: Config = CONFIG;
  subConfigBoutique!: Subscription;
  subProduits!: Subscription;
  subPage!: Subscription;
  subMoi!: Subscription;
  page!: PageWithRelations;
  subConfig!: Subscription;
  subCat!: Subscription;
  subTva!: Subscription;
  subCodePromo!: Subscription;
  tabTva: any[any] = [];
  displayedColumns: string[] = [
    'ref',
    'produit',
    'puttc',
    'attributs',
    'nbr',
    'pttc',
  ];
  public panierTemp: boolean = false;
  public panier: panierCookies = { date: Date.now(), produits: [] };
  cptPanier: number = 0;
  @ViewChild(MatTable) table!: MatTable<produitCookies>;
  testBrowser: boolean;
  moi!: completeClient;
  snapshot!: string;
  codePromo: string = '';
  consigne: string = '';
  nbrLimitegen!: any;
  nbrLimite: any;
  valPromo: number = 0;
  subCommande!: Subscription;
  calculPromo!: { valeur: number; unite: string };
  private subFidelites: Subscription = new Subscription();
  public fidel: Fidelite[] = [];
  public arrayFidel: Array<{ id: string; class: string }> = [];
  public fidelFULL: boolean = false;
  constructor(
    @Inject(PLATFORM_ID) platformId: string,
    private readonly meta: Meta,
    private titre: Title,
    private router: Router,
    private pagesService: PageSpecControllerService,
    private configBoutiqueService: ConfigBoutiqueControllerService,
    private configService: ConfigControllerService,
    private produitService: ProduitControllerService,
    private catService: CategoriesControllerService,
    private tvaService: TvaControllerService,
    private attribuService: AttribusControllerService,
    private clientsService: ClientControllerService,
    private codePromoService: CodepromoControllerService,
    private commandeService: CommandeControllerService,
    private currency: CurrencyPipe,
    private fidelitesService: FideliteControllerService
  ) {
    this.testBrowser = isPlatformBrowser(platformId);
    moment.locale('fr');
  }

  ngOnInit(): void {
    const state: RouterState = this.router.routerState;
    this.snapshot = state.snapshot.url;
    this.subConfig = this.configService.findd4().subscribe((config) => {
      this.configSite = config;
      this.subConfigBoutique = this.configBoutiqueService
        .findd4()
        .subscribe((configBoutique) => {
          this.configBoutique = configBoutique;
          this.subPage = this.pagesService
            .find({
              filter: { filter: `{"where": { "type": "VERIFCOMMANDE" } }` },
            })
            .subscribe((page) => {
              this.page = page[0];
              //lazy-loading
              this.page.html = this.page.html
                ? this.page.html
                    .replace(/<img /gi, '<img class="lazyload" ')
                    .replace(/(<img[^>]*)src=/gi, '$1data-src=')
                : '';
              // metas
              const Tags = [
                "name='description'",
                "property= 'og:url'",
                "property='og:title'",
                "property='og:description'",
                "property='og:image'",
                "property='og:locale'",
                "property='og:type'",
                "property='og:site_name'",
                "name='twitter:card'",
                "name='twitter:description'",
                "name='twitter:title'",
                "name='twitter:image'",
              ];
              Tags.map((e) => this.meta.removeTag(e));
              const title = this.page.titre + ' - ' + this.configSite.title!;
              const description = this.page.description || '';
              this.titre.setTitle(title);
              let ogimage = this.page.ogimage || this.config.imageUrl || '';
              this.meta.addTags([
                { name: 'description', content: description },
                { property: 'og:url', content: this.config.applicationUrl },
                { property: 'og:title', content: title },
                { property: 'og:description', content: description },
                { property: 'og:image', content: ogimage },
                { property: 'og:locale', content: 'fr_FR' },
                { property: 'og:type', content: 'website' },
                {
                  property: 'og:site_name',
                  content: this.configSite.title!,
                },
                { name: 'twitter:card', content: 'summary' },
                { name: 'twitter:description', content: description },
                { name: 'twitter:title', content: title },
                { name: 'twitter:image', content: ogimage },
              ]);
              this.subMoi = this.clientsService
                .printCurrentClient()
                .subscribe((moi) => {
                  this.moi = moi;
                });
              if (this.testBrowser)
                this.depilPanier().then((e) => {
                  this.panier = e;
                  //console.log(e);
                  this.verifPromo(this.panier.codepromo?.code!);
                  this.subFidelites = this.fidelitesService
                    .findByIdClient({ id: this.moi.id! })
                    .subscribe((fidel) => {
                      //console.log(fidel)
                      this.fidel = fidel as Array<Fidelite>;
                      for (
                        let i = 0;
                        i < this.configBoutique.fidelNbrcommande!;
                        i++
                      ) {
                        this.fidel.length && this.fidel[0].idscommandes![i]
                          ? this.arrayFidel.push({
                              id: this.fidel[0].idscommandes![i],
                              class: 'on',
                            })
                          : this.arrayFidel.push({ id: '', class: 'off' });
                      }
                      this.fidelFULL =
                        !!this.fidel.length &&
                        this.fidel[0].idscommandes!.length >=
                          this.configBoutique.fidelNbrcommande!;
                      if (this.fidelFULL) {
                        /* console.log(
                          this.getTotalCost(),
                          this.configBoutique.fidelMinpanier
                        ); */
                        if (
                          this.getTotalCost() >
                            this.configBoutique.fidelMinpanier! &&
                          this.getTotalCost() > this.fidel[0].valeur!
                        ) {
                          this.panier.fidelite = {
                            id: this.fidel[0].id!,
                            valeur: this.fidel[0].valeur!,
                          };
                          if (this.testBrowser)
                            localStorage.setItem(
                              'proPulsePanier',
                              JSON.stringify(this.panier)
                            );
                        }
                      }
                    });
                });
            });
        });
    });
  }
  ngOnDestroy(): void {
    if (this.subCat) this.subCat.unsubscribe();
    if (this.subConfig) this.subConfig.unsubscribe();
    if (this.subConfigBoutique) this.subConfigBoutique.unsubscribe();
    if (this.subMoi) this.subMoi.unsubscribe();
    if (this.subPage) this.subPage.unsubscribe();
    if (this.subProduits) this.subProduits.unsubscribe();
    if (this.subTva) this.subTva.unsubscribe();
    if (this.subCodePromo) this.subCodePromo.unsubscribe();
    if (this.subCommande) this.subCommande.unsubscribe();
    if (this.subFidelites) this.subFidelites.unsubscribe();
  }
  async depilPanier(): Promise<panierCookies> {
    const panierRecup: panierCookies = await JSON.parse(
      localStorage.getItem('proPulsePanier')!
    );
    let trueProduits: produitCookies[] = [];
    this.cptPanier = panierRecup.produits.length;
    await Promise.all(
      panierRecup.produits.map(async (lign) => {
        await this.verifLignPanier(
          lign.id,
          lign.nbr,
          lign.attributs,
          lign.nbrHorsAttributs!
        ).then((e) => {
          trueProduits.push(e);
        });
      })
    );
    panierRecup.produits = trueProduits;
    /* localStorage.setItem('proPulsePanier', JSON.stringify(panierRecup)); */
    return panierRecup;
  }
  async verifLignPanier(
    id: string,
    nbr: number,
    attributs: attributCommande[],
    nbrHorsAttributs: number
  ): Promise<produitCookies> {
    const ligne = await this.produitService.findById({ id: id }).toPromise();
    const majoration: number = await this.calcMaj(attributs);
    const TRUELIGNE = await this.calcPrixTTC(
      ligne.tvaId,
      ligne.prixHT,
      ligne.prixbarre!
    ).then((TTC) => {
      const prixTTC = TTC.ttcbarre > 0 ? TTC.ttcbarre : TTC.ttc;
      const trueLigne: produitCookies = {
        id: ligne.id!,
        ref: ligne.ref,
        url: ligne.url,
        unite: ligne.unite,
        nom: ligne.nom,
        prixHt: ligne.prixHT,
        prixTTC: prixTTC,
        attributs: attributs,
        majoration: majoration,
        nbr: nbr,
        prixTotalTTC: (prixTTC + majoration) * nbr,
        stock: ligne.stock,
        min: ligne.minUnite,
        nbrHorsAttributs: nbrHorsAttributs,
      };
      return trueLigne;
    });
    return TRUELIGNE;
  }
  async calcMaj(attributs: attributCommande[]): Promise<number> {
    let maj = 0;
    await Promise.all(
      attributs.map(async (attr) => {
        const trueAttributs = await this.attribuService
          .findById({ id: attr.id })
          .toPromise();
        const choix = attr.choix;
        const plusValue = Number(
          trueAttributs.prixmods[trueAttributs.valeurs.indexOf(choix)]
        );
        maj += plusValue;
      })
    );
    return maj;
  }
  async calcPrixTTC(
    idTva: string,
    ht: number,
    htBarre: number
  ): Promise<{ ttc: number; ttcbarre: number }> {
    const tva = await this.tvaService.findById({ id: idTva }).toPromise();
    return {
      ttc: ht + (ht * tva.taux) / 100,
      ttcbarre: htBarre + (htBarre * tva.taux) / 100,
    };
  }
  getTotalCost() {
    return this.panier.produits
      .map((t) => t.prixTotalTTC)
      .reduce((acc, value) => acc + value, 0);
  }
  verifPromo(codepromo: string) {
    this.codePromo = codepromo;
    if (codepromo) {
      if (this.subCodePromo) this.subCodePromo.unsubscribe();
      this.subCodePromo = this.codePromoService
        .find({ filter: { filter: `{"where": { "code": "${codepromo}" } }` } })
        .subscribe((cp: CodepromoWithRelations[]) => {
          const promo: CodepromoWithRelations = cp[0];
          this.testPromo(codepromo, promo).then((o) => {
            this.codePromo = o;
          });
        });
    }
  }
  async testPromo(
    codepromo: string,
    promo: CodepromoWithRelations
  ): Promise<string> {
    let totalAcalculer: number = 0;
    this.nbrLimitegen = await this.commandeService
      .count({ where: `{"promo":"${codepromo}"}` })
      .toPromise();
    this.nbrLimite = await this.commandeService
      .count({
        where: `{"and":[{"promo":"${codepromo}"},{"clientId":"${this.moi.id}"}]}`,
      })
      .toPromise();

    let tabProdId: string[] = []; //liste des ID produits
    let tabProdEqualCat: string[] = []; //listes des produits dans les cat si promo Cat
    let tabProdConcernes: string[] = [];
    this.panier.produits.forEach((produit) => {
      tabProdId.push(produit.id);
    });
    const inq = '"' + tabProdId.join('","') + '"';
    const LesCats = await this.produitService
      .find({
        filter: { filter: `{ "where": { "id": { "inq": [${inq}] } } }` },
      })
      .toPromise();
    LesCats.forEach((prod) => {
      if (promo.categorieid!.length) {
        if (
          prod.categorieId!.some((o: string) => promo.categorieid!.includes(o))
        ) {
          tabProdEqualCat.push(prod.id!);
        }
      }
    });
    const maintenant = moment().toDate();
    //date départ
    if (!(moment(promo.datedu).toDate() <= maintenant)) {
      this.codePromo = `"${codepromo}" ne sera utilisable qu'à partir du ${moment(
        promo.datedu
      ).format('DD/MM/YYYY à HH:mm')}`;
    }

    //date fin
    if (promo.dateau && !(moment(promo.dateau).toDate() > maintenant)) {
      return `"${codepromo}" n'est plus utilisable depuis le ${moment(
        promo.dateau
      ).format('DD/MM/YYYY à HH:mm')}`;
    }

    //minimum panier
    if (this.getTotalCost() < promo.minpanier!) {
      return `Pour profiter de "${codepromo}" votre "Total Commande" doit être d'au minimum ${promo.minpanier} €`;
    }

    //limite utilisation client
    if (promo.limite! > 0 && !(promo.limite! <= this.nbrLimite.count)) {
      return `Le code "${codepromo}" a été utilisé trop de fois et n'est donc plus valable`;
    }

    //limite utilisation globale
    if (
      promo.limiteGen! > 0 &&
      !(promo.limiteGen! <= this.nbrLimitegen.count)
    ) {
      return `Le code "${codepromo}" a été utilisé trop de fois et n'est donc plus valable`;
    }

    //utilisable par un seul client
    if (promo.clientid!.length > 0 && !promo.clientid?.includes(this.moi.id!)) {
      return `Le code "${codepromo}" n'est pas valable`; //code non destiné au client actuel
    }

    //utilisable sur certain produits (d'abord produits puis cats)
    if (promo.articleid?.length) {
      if (!promo.articleid.some((r) => tabProdId.includes(r))) {
        //pas de produits concernés
        return `Aucun article de votre panier n'est concerné pour le code "${codepromo}"`;
      } else {
        //OK produits concernés
        //calcul seulement sur les produits
        tabProdConcernes = [];
        totalAcalculer = this.panier.produits
          .filter((o) => promo.articleid!.includes(o.id))
          .map((t) => t.prixTotalTTC)
          .reduce((acc, value) => acc + value, 0);
        this.panier.produits
          .filter((o) => promo.articleid!.includes(o.id))
          .map((ids) => tabProdConcernes.push(ids.id));
        if (promo.unite === '%') {
          this.valPromo = (totalAcalculer * promo.valeur) / 100;
          this.calculPromo = { valeur: promo.valeur, unite: promo.unite };
        } else {
          this.valPromo = promo.valeur;
          this.calculPromo = { valeur: promo.valeur, unite: promo.unite };
        }
        this.panier.codepromo = {
          id: promo.id!,
          code: promo.code,
          description: promo.description,
          valeurPanier: this.valPromo,
          calcul: this.calculPromo,
          idProduits: tabProdConcernes,
        };
        if (this.testBrowser)
          localStorage.setItem('proPulsePanier', JSON.stringify(this.panier));
        return `Le code "${codepromo}" vous fait économiser ${this.currency.transform(
          this.valPromo
        )}`;
      }
    } else if (promo.categorieid?.length) {
      //si pas sur certains produits mais sur certaines catégories
      if (!tabProdEqualCat.length) {
        //aucun produit de ces cat dans le panier
        return `Aucun article de votre panier n'est concerné pour le code "${codepromo}"`;
      } else {
        //OK il y a des produits de ces cat concernés
        //calcul seulement sur les produits de ces cats
        tabProdConcernes = [];
        totalAcalculer = this.panier.produits
          .filter((o) => tabProdEqualCat!.includes(o.id))
          .map((t) => t.prixTotalTTC)
          .reduce((acc, value) => acc + value, 0);
        this.panier.produits
          .filter((o) => tabProdEqualCat!.includes(o.id))
          .map((ids) => tabProdConcernes.push(ids.id));
        if (promo.unite === '%') {
          this.valPromo = (totalAcalculer * promo.valeur) / 100;
          this.calculPromo = { valeur: promo.valeur, unite: promo.unite };
        } else {
          this.valPromo = promo.valeur;
          this.calculPromo = { valeur: promo.valeur, unite: promo.unite };
        }
        this.panier.codepromo = {
          id: promo.id!,
          code: promo.code,
          description: promo.description,
          valeurPanier: this.valPromo,
          calcul: this.calculPromo,
          idProduits: tabProdConcernes,
        };
        if (this.testBrowser)
          localStorage.setItem('proPulsePanier', JSON.stringify(this.panier));
        return `Le code "${codepromo}" vous fait économiser ${this.currency.transform(
          this.valPromo
        )}`;
      }
    } else {
      //sur tout
      //calcul sur this.getTotalCost()
      tabProdConcernes = [];
      totalAcalculer = this.getTotalCost();
      this.panier.produits.map((ids) => tabProdConcernes.push(ids.id));
      if (promo.unite === '%') {
        this.valPromo = (totalAcalculer * promo.valeur) / 100;
        this.calculPromo = { valeur: promo.valeur, unite: promo.unite };
      } else {
        this.valPromo = promo.valeur;
        this.calculPromo = { valeur: promo.valeur, unite: promo.unite };
      }
      this.panier.codepromo = {
        id: promo.id!,
        code: promo.code,
        description: promo.description,
        valeurPanier: this.valPromo,
        calcul: this.calculPromo,
        idProduits: tabProdConcernes,
      };
      if (this.testBrowser)
        localStorage.setItem('proPulsePanier', JSON.stringify(this.panier));
      return `Le code "${codepromo}" vous fait économiser ${this.currency.transform(
        this.valPromo
      )}`;
    }
  }
  valid(consigne: string) {
    //enregistrement de la commande puis redirection vers recap + infos mail de contact boutique etc...
    //
    let fideliteValeur = this.panier.fidelite?.valeur || 0;
    this.subCommande = this.commandeService
      .create({
        body: {
          clientId: this.moi.id,

          date: moment().utc().format(),
          livraison: false,
          etat: '0',
          produits: this.panier.produits,
          promo: this.panier.codepromo?.code || '',
          consigne: consigne.replace(/<[^>]+>/gi, ''),
          promodetail: JSON.stringify(this.panier.codepromo) || '',
          fideliteId: this.panier.fidelite?.id || '',
          fideliteValeur: this.panier.fidelite?.valeur
            ? Number(this.panier.fidelite?.valeur)
            : undefined,
          total:
            this.valPromo > 0
              ? this.getTotalCost() - this.valPromo - fideliteValeur
              : this.getTotalCost() - fideliteValeur,
        },
      })
      .subscribe((o) => {
        const stock = this.panier.produits?.map((produit) => {
          const pr = produit as produitCookies;
          this.produitService
            .incStockAll({
              where: `{ "id": "${pr.id}" }`,
              body: -pr.nbr,
            })
            .subscribe((out) => {});
        });
        Promise.all(stock).then(() => {
          localStorage.removeItem('proPulsePanier');
          this.router.navigate([
            this.config.lienSpec.CATALOGUE,
            'commande',
            'valid',
            o.id,
          ]);
        });
      });
  }
}
