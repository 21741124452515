import { PLATFORM_ID, Inject, Injectable } from '@angular/core';
import {
  Router,
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
} from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { isPlatformBrowser } from '@angular/common';
import { AuthenticationService } from '../services/index';
import { CurrentUser } from 'src/models';
import { Config, CONFIG } from 'config';

@Injectable()
export class ClientGuard implements CanActivate {
  user!: CurrentUser;
  userRole!: string;
  testBrowser: boolean;
  login = false;
  config: Config = CONFIG;
  constructor(
    @Inject(PLATFORM_ID) platformId: string,
    private router: Router,
    private http: HttpClient,
    private authenticationService: AuthenticationService
  ) {
    this.testBrowser = isPlatformBrowser(platformId);
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    if (this.testBrowser && localStorage.getItem('clientUser')) {
      this.user = JSON.parse(localStorage.getItem('clientUser')!);
      return !!this.authenticationService.verifClient().subscribe(
        (data) => {
          return data;
        },
        (error) => {
          console.log(error);
          this.authenticationService.resetClient();
          this.router.navigate(
            [this.config.lienSpec.CATALOGUE, 'client', 'login'],
            { queryParams: { returnUrl: state.url } }
          );
          return false;
        }
      );
    } else {
      console.log('paslogué')
      this.router.navigate(
        [this.config.lienSpec.CATALOGUE, 'client', 'login'],
        {
          queryParams: { returnUrl: state.url },
        }
      );
      return false;
    }
  }
}
