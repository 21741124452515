import { isPlatformBrowser } from '@angular/common';
import { Component, Inject, OnInit, PLATFORM_ID } from '@angular/core';

import {
  NgcCookieConsentService,
  NgcInitializeEvent,
  NgcStatusChangeEvent,
  NgcNoCookieLawEvent,
} from 'ngx-cookieconsent';
import { Subscription } from 'rxjs';
import { Page } from 'src/api/models';
import { PagesControllerService } from 'src/api/services/pages-controller.service';

@Component({
  selector: 'app-root',
  /* templateUrl: './app.component.html', */
  template: `<router-outlet></router-outlet>`,
})
export class AppComponent implements OnInit {
  public isMenuCollapsed = true;
  public isBrowser: boolean = false;
  private statusChangeSubscription: Subscription | undefined;
  public pages : Page[] | undefined;
  constructor(
    @Inject(PLATFORM_ID) platformId: Object,
    private ccService: NgcCookieConsentService,
    private pagesService : PagesControllerService
  ) {
    
    this.isBrowser = isPlatformBrowser(platformId);
    
  }

  title = 'ProPulse';
  ngOnInit() {
    this.getPages();
    this.statusChangeSubscription = this.ccService.statusChange$.subscribe(
      (event: NgcStatusChangeEvent) => {
        if (!this.ccService.hasConsented()) {
          window.location.href =
            'https://www.google.fr/search?q=digitalatelier';
        } else {
        }
      }
    );
  }
  
  getPages(): void {
    this.pagesService.find().subscribe(pages => (this.pages = pages));
  }
  ngOnDestroy() {
    if(this.statusChangeSubscription)this.statusChangeSubscription.unsubscribe();
  }
}
