<div class="cont">
    <h1> Mon compte Client </h1>
    <mat-card class="infosverif">
        <mat-card-header>
            <mat-card-title>Mes informations de facturation</mat-card-title>
            <mat-card-subtitle>Merci de bien vouloir vérifiez vos informations</mat-card-subtitle>
        </mat-card-header>
        <mat-card-content>

            <div class="nom">{{moi?.prenom}} {{moi?.nom}}</div>
            <div *ngIf="moi?.date" class="date">Inscrit depuis le {{moi?.date | date:'short'}}</div>
            <div class="adresse">
                <h4>Adresse</h4>
                <p>{{moi?.adresse}}
                    <br *ngIf="moi?.adresse2!=''"> {{moi?.adresse2}}
                    <br>{{moi?.cp}} {{moi?.ville}}
                </p>
            </div>
            <div class="contact">
                <h4>Contact</h4>
                <p><b>Tél :</b> {{moi?.tel}}
                    <br><b>E-mail :</b> {{moi?.email}}
                </p>

            </div>

        </mat-card-content>
        <mat-card-actions>
            <button mat-raised-button color="accent" [routerLink]="['/',config.lienSpec.CATALOGUE,'client','mod']"
                [queryParams]="{returnUrl: snapshot}">Modifier mes informations</button>
            <button mat-raised-button color="warn" (click)='modPass()'>Modifier mon mot de passe</button>
        </mat-card-actions>
    </mat-card>
    <mat-card class="infosverif">
        <mat-card-header>
            <mat-card-title>Ma carte de fidélité</mat-card-title>

        </mat-card-header>
        <mat-card-content>
            <!-- Carte de fidélité -->
            <div id="fidelite" class="mat-elevation-z3" style="margin: auto">
                <div class="fidelheader">Carte de Fidélité de M./Mme {{moi?.nom | capitalize}} {{moi?.prenom |
                    capitalize}}</div>
                <div class="fidelcontent" fxLayout="row wrap" fxLayoutAlign="center start"
                    fxLayoutAlign.lt-md="center center">
                    <span class="{{fidelAFF.class}}" matTooltip="{{fidelAFF.id?'Commande '+fidelAFF.id:''}}"
                        *ngFor="let fidelAFF of arrayFidel"></span>
                </div>
                <div class="fidelresult" *ngIf="fidel.length">
                    Vous avez déjà cumulé <b>{{fidel[0]?.valeur|currency}}</b> <br>Cette somme sera déduite de vôtre
                    {{configBoutique.fidelNbrcommande!+1}}ème
                    commande !
                </div>
            </div>
            <!-- Carte de fidélité - FIN -->
        </mat-card-content>

    </mat-card>
    <mat-card class="infosverif">
        <mat-card-header>
            <mat-card-title>Mon historique de commande</mat-card-title>
            <!-- <mat-card-subtitle>subtitle</mat-card-subtitle> -->
        </mat-card-header>
        <mat-card-content>

            <table>
                <th>Le :</th>
                <th>Montant</th>
                <th>État</th>
                <th>Code de retrait/suivi</th>
                <th></th>
                <tr class="commandeLigne" *ngFor="let macommande of mescommande">
                    <td>{{macommande.date |date:'short'}}</td>
                    <td>{{macommande.total | currency}}</td>
                    <td class="etat">{{etat(macommande.etat!).message}} <mat-icon>{{etat(macommande.etat!).matIcon}}
                        </mat-icon>
                    </td>
                    <td>{{macommande.code ||'Non disponible'}}</td>
                    <td><button [routerLink]="['/',
                        config.lienSpec.CATALOGUE,
                        'commande',
                        'consult',macommande.id
                      ]" mat-icon-button aria-hidden="false" aria-label="Voir cette commande"
                            matTooltip="Voir cette commande" color="primary">
                            <mat-icon>visibility</mat-icon>
                        </button></td>
                </tr>
            </table>
        </mat-card-content>
    </mat-card>

</div>