import {
  Component,
  Inject,
  OnDestroy,
  OnInit,
  PLATFORM_ID,
  ViewChild,
} from '@angular/core';

import { Subscription } from 'rxjs';
import {
  ConfigBoutique,
  ConfigPartial as ConfigSite,
  PageWithRelations,
} from 'src/api/models';
import {
  completeClient,
  panierCookies,
  ProduitAff,
  produitCookies,
  tokenClient,
} from 'src/models';
import { CONFIG, Config } from 'config';
import { MatTable } from '@angular/material/table';
import { isPlatformBrowser } from '@angular/common';
import { Meta, Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import {
  PageSpecControllerService,
  ConfigControllerService,
  CommandeControllerService,
  ClientControllerService,
} from 'src/api/services';
import * as moment from 'moment';
@Component({
  selector: 'app-valid',
  templateUrl: './valid.component.html',
  styleUrls: [
    './../../styles.scss',
    './../panier.component.scss',
    './verif.component.scss',
    './valid.component.scss',
  ],
})
export class ValidComponent implements OnInit, OnDestroy {
  configBoutique: ConfigBoutique = {
    genNbrProduitCat: 10,
    genNbrProduitStar: 10,
    d4: 'd4',
  };
  produits: ProduitAff[] = [];
  public configSite!: ConfigSite;
  config: Config = CONFIG;
  subConfigBoutique!: Subscription;
  subPage!: Subscription;
  page!: PageWithRelations;
  subConfig!: Subscription;

  moi!: completeClient;
  subMoi!: Subscription;
  displayedColumns: string[] = [
    'ref',
    'produit',
    'puttc',
    'attributs',
    'nbr',
    'pttc',
  ];
  public panierTemp: boolean = false;
  public panier: panierCookies = { date: Date.now(), produits: [] };
  cptPanier: number = 0;
  @ViewChild(MatTable) table!: MatTable<produitCookies>;
  testBrowser: boolean;
  user!: tokenClient;
  subCommande!: Subscription;
  subUrl!: Subscription;
  constructor(
    @Inject(PLATFORM_ID) platformId: string,
    private readonly meta: Meta,
    private titre: Title,
    private route: ActivatedRoute,
    private router: Router,
    private pagesService: PageSpecControllerService,
    private configService: ConfigControllerService,
    private commandeService: CommandeControllerService,
    private clientsService: ClientControllerService,
  ) {
    this.testBrowser = isPlatformBrowser(platformId);
  }

  ngOnInit(): void {
    //ici on depil la commande idcommande (cf depil panierCookies) + page VALIDCOMMANDE
    this.subConfig = this.configService.findd4().subscribe((config) => {
      this.configSite = config;
      this.subPage = this.pagesService
        .find({ filter: { filter: `{"where": { "type": "VALIDCOMMANDE" } }` } })
        .subscribe((page) => {
          this.page = page[0];
          //lazy-loading
          this.page.html = this.page.html
            ? this.page.html
                .replace(/<img /gi, '<img class="lazyload" ')
                .replace(/(<img[^>]*)src=/gi, '$1data-src=')
            : '';
          // metas
          const Tags = [
            "name='description'",
            "property= 'og:url'",
            "property='og:title'",
            "property='og:description'",
            "property='og:image'",
            "property='og:locale'",
            "property='og:type'",
            "property='og:site_name'",
            "name='twitter:card'",
            "name='twitter:description'",
            "name='twitter:title'",
            "name='twitter:image'",
          ];
          Tags.map((e) => this.meta.removeTag(e));
          const title = this.page.titre + ' - ' + this.configSite.title!;
          const description = this.page.description || '';
          this.titre.setTitle(title);
          let ogimage = this.page.ogimage || this.config.imageUrl || '';
          this.meta.addTags([
            { name: 'description', content: description },
            { property: 'og:url', content: this.config.applicationUrl },
            { property: 'og:title', content: title },
            { property: 'og:description', content: description },
            { property: 'og:image', content: ogimage },
            { property: 'og:locale', content: 'fr_FR' },
            { property: 'og:type', content: 'website' },
            {
              property: 'og:site_name',
              content: this.configSite.title!,
            },
            { name: 'twitter:card', content: 'summary' },
            { name: 'twitter:description', content: description },
            { name: 'twitter:title', content: title },
            { name: 'twitter:image', content: ogimage },
          ]);
          this.subMoi = this.clientsService
            .printCurrentClient()
            .subscribe((moi) => {
              this.moi = moi;

              this.subUrl = this.route.params.subscribe((params) => {
                this.subCommande = this.commandeService
                  .findById({ id: params.idcommande })
                  .subscribe((commande) => {
                    if (!(commande.clientId === this.moi.id)) {
                      this.router.navigate(['']);
                    }
                    this.panier = {
                      date: moment(commande.date).unix(),
                      produits: commande.produits as produitCookies[],
                      codepromo: commande.promodetail
                        ? JSON.parse(commande.promodetail!)
                        : '',
                      fidelite:{id:commande.fideliteId!,valeur:commande.fideliteValeur!}
                      };
                    /* commande.produits?.map((produit) => {
                      const pr = produit as produitCookies;
                      console.log(pr.id);
                      this.produitsService
                        .incStockAll({
                          where: `{ "id": "${pr.id}" }`,
                          body: -pr.nbr,
                        })
                        .subscribe((out) => {
                          console.log(out);
                        });
                    }); */
                  });
              });
            });
        });
    });
  }
  ngOnDestroy(): void {
    if (this.subConfig) this.subConfig.unsubscribe();
    if (this.subConfigBoutique) this.subConfigBoutique.unsubscribe();
    if (this.subPage) this.subPage.unsubscribe();
    if (this.subConfig) this.subConfig.unsubscribe();
    if (this.subConfigBoutique) this.subConfigBoutique.unsubscribe();
    if (this.subPage) this.subPage.unsubscribe();

    if (this.subMoi) this.subMoi.unsubscribe();
    if (this.subCommande) this.subCommande.unsubscribe();
  }
  getTotalCost() {
    return this.panier.produits
      .map((t) => t.prixTotalTTC)
      .reduce((acc, value) => acc + value, 0);
  }
}
