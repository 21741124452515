import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { Config, CONFIG } from 'config';
import { ClientControllerService } from 'src/api/services';
import {  tokenClient } from 'src/models';
@Component({
  selector: 'app-mod',
  templateUrl: './mod.component.html',
  styleUrls: ['../../styles.scss', './mod.component.scss'],
})
export class ModComponent implements OnInit {
  config: Config = CONFIG;
  user!: tokenClient;
  signinForm: FormGroup = new FormGroup({});
  errorSignin: string = '';
  returnUrl: string = '';
  pageTitre: string = 'Modifier mon Compte Client';
  hide = true;
  ok = false;
  moiMail: string = '';
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private formBuilder: FormBuilder,
    private clientsService: ClientControllerService,
    private titre: Title
  ) {}

  ngOnInit(): void {
    this.returnUrl =
      this.route.snapshot.queryParams['returnUrl'] ||
      '/' + this.config.lienSpec.CATALOGUE + '/client/moncompte';
    this.clientsService.printCurrentClient().subscribe((moi) => {
      this.ok = true;
      this.moiMail = moi.email!;

      this.signinForm = this.formBuilder.group({
        adresse: [moi.adresse, Validators.required],
        adresse2: [moi.adresse2],
        cp: [
          moi.cp,
          [
            Validators.required,
            Validators.pattern(/^(?:[0-8]\d|9[0-8])\d{3}$/),
          ],
        ],
        emailsign: [
          moi.email,
          Validators.compose([Validators.required, Validators.email]),
        ],
        nom: [moi.nom, Validators.required],
        prenom: [moi.prenom, Validators.required],
        tel: [
          moi.tel,
          [
            Validators.required,
            Validators.pattern(
              /^(?:(?:\+|00)33[\s.-]{0,3}(?:\(0\)[\s.-]{0,3})?|0)[1-9](?:(?:[\s.-]?\d{2}){4}|\d{2}(?:[\s.-]?\d{3}){2})$/
            ),
          ],
        ],
        ville: [moi.ville],
      });
    });
    this.titre.setTitle(this.config.DOMAINE + ' - ' + this.pageTitre);
  }
  getErrorSigninMessage(formControl: string) {
    let message = '';
    switch (formControl) {
      case 'email':
        if (this.signinForm.controls.emailsign.hasError('required')) {
          message = 'Vous devez saisir une adresse Email';
        }
        if (this.signinForm.controls.emailsign.hasError('email')) {
          message = 'Veuillez saisir une adresse Email valide';
        }
        return message;
      case 'nom':
        if (this.signinForm.controls.nom.hasError('required')) {
          message = 'Vous devez saisir votre nom';
        }
        return message;
      case 'prenom':
        if (this.signinForm.controls.prenom.hasError('required')) {
          message = 'Vous devez saisir votre prénom';
        }
        return message;
      case 'adresse':
        if (this.signinForm.controls.adresse.hasError('required')) {
          message = 'Vous devez saisir votre adresse';
        }
        return message;
      case 'cp':
        if (this.signinForm.controls.cp.hasError('required')) {
          message = 'Vous devez saisir votre code postal';
        }
        if (this.signinForm.controls.cp.hasError('pattern')) {
          message = 'Le code postal saisi ne semble pas valide. Ex:62121';
        }
        return message;
      case 'ville':
        if (this.signinForm.controls.ville.hasError('required')) {
          message = 'Vous devez saisir votre ville';
        }
        return message;
      case 'tel':
        if (this.signinForm.controls.tel.hasError('required')) {
          message = 'Vous devez saisir un numéro de téléphone';
        }
        if (this.signinForm.controls.tel.hasError('pattern')) {
          message = 'Le numéro saisi ne semble pas valide. Ex:0312345678';
        }
        return message;
      default:
        return message;
    }
  }

  modClient() {
    if (this.signinForm.valid) {
      this.clientsService
        .updateMe({
          body: {
            email: this.signinForm.value.emailsign,
            tel: this.signinForm.value.tel,
            nom: this.signinForm.value.nom,
            prenom: this.signinForm.value.prenom,
            adresse2: this.signinForm.value.adresse2,
            adresse: this.signinForm.value.adresse,
            cp: this.signinForm.value.cp,
            ville: this.signinForm.value.ville,
          },
        })
        .subscribe((out) => {
          this.user = JSON.parse(localStorage.getItem('clientUser')!);
          this.user.thisClient={role:'client',email:this.signinForm.value.emailsign,nom:this.signinForm.value.nom,prenom:this.signinForm.value.prenom};
          localStorage.setItem('clientUser', JSON.stringify(this.user));
          this.router.navigate([this.returnUrl]);
        });
    }
  }
}
