import { Injectable } from '@angular/core';
export interface Etat {
  message: string;
  matIcon: string;
  mailTemplate: string;
}
@Injectable({
  providedIn: 'root',
})
export class EtatCommandes {
  etat(code: string): Etat {
    switch (code) {
      case '0':
        return { message: 'En attente', matIcon: 'watch', mailTemplate: '' };
      case '1':
        return { message: 'Validée', matIcon: 'done', mailTemplate: '0001' };
      case '2':
        return {
          message: 'En cours de préparation',
          matIcon: 'settings',
          mailTemplate: '0002',
        };
      case '3':
        return {
          message: 'Disponible en Casier',
          matIcon: 'store',
          mailTemplate: '0003',
        };
      case '4':
        return {
          message: 'Envoyée',
          matIcon: 'local_shipping',
          mailTemplate: '0004',
        };
      case '5':
        return {
          message: 'Terminée',
          matIcon: 'check_circle',
          mailTemplate: '',
        };
      case '10':
        return { message: 'Annulée', matIcon: 'cancel', mailTemplate: '' };
      default:
        return { message: 'En attente', matIcon: 'watch', mailTemplate: '' };
    }
  }
}
