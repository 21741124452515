<h1 mat-dialog-title>Vous avez oublié votre mot de passe ?</h1>
<div mat-dialog-content>
  <p>Saisissez ci-dessous votre adresse e-mail</p>
  <mat-form-field  class="full-width" appearance="outline">
    <mat-label>E-mail</mat-label>
    <input matInput [formControl]="mailform"  type="email" name="email"  cdkFocusInitial>
  </mat-form-field>
  <p>Si votre adresse est dans notre base, vous recevrez un email avec les instructions pour modifier votre mot de passe.</p>
</div>
<div mat-dialog-actions style="justify-content: flex-end;">
  <button mat-button [mat-dialog-close]="mailform.value"  [disabled]="!mailform.valid">Valider</button>
</div>
