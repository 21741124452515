import { Injectable } from '@angular/core';
import {
  HttpClient,
} from '@angular/common/http';
@Injectable({
  providedIn: 'root',
})
export class UploadService {
  constructor(private httpClient: HttpClient) {}
  public upload(SERVER_URL: string, formData: any) {
    return this.httpClient.post<any>(SERVER_URL, formData, {
      reportProgress: true,
      observe: 'events',
    });
  }
}
