<!-- carousel -->
<div class="carousel">
  <ng-container *ngFor="let slide of slides; let i = index">
    <img [matTooltip]="slide?.headline!"
      *ngIf="i === currentSlide"
      [src]="slide?.src"
      class="slide"
      [@slideAnimation]="animationType"
    />
  </ng-container>

  <!-- controls -->
  <button *ngIf="slides.length>1" class="control prev" type="button" (click)="onPreviousClick()">
    <span class="arrow left"></span>
  </button>
  <button *ngIf="slides.length>1" class="control next" type="button" (click)="onNextClick()">
    <span class="arrow right"></span>
  </button>
</div>
