import { NgModule } from '@angular/core';



import { HtmlPagePipe } from './htmlPage.pipe';
import { InterditValidatorDirective, interditValidator } from './interdit.directive';
import { CapitalizePipe } from './capitalize.pipe';
import { UnsafePipe } from './unsafe.pipe';
import { Nl2BrPipe } from './nl2br.pipe';

@NgModule({
  declarations: [HtmlPagePipe, InterditValidatorDirective, CapitalizePipe, UnsafePipe, Nl2BrPipe,],
  exports: [HtmlPagePipe, InterditValidatorDirective, CapitalizePipe, UnsafePipe, Nl2BrPipe]
})
export class SharedModule {
  static forRoot() {
    return {
      ngModule: SharedModule,
      providers: [],
    };
  }
}
