import { BrowserModule, BrowserTransferStateModule } from '@angular/platform-browser';
import { DEFAULT_CURRENCY_CODE, LOCALE_ID, NgModule } from '@angular/core';
/* CONFIG */
import { Config, CONFIG } from '../../config';
const config: Config = CONFIG;
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';

import { CKEditorModule } from 'ckeditor4-angular';

//gtag
import { GtagModule } from 'angular-gtag';
import {
  NgcCookieConsentConfig,
  NgcCookieConsentModule,
} from 'ngx-cookieconsent';
import { HttpClientModule } from '@angular/common/http';
import {TransferHttpCacheModule} from '@nguniversal/common';
import { HomeComponent } from './pages/home.component';
import { PagesComponent } from './pages/pages.component';
import { SharedModule } from './shared/shared.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { PageItemViewComponent } from './pages/page-item-view.component';
import { PageSpecComponent } from './pages/page-spec.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatBadgeModule } from '@angular/material/badge';
import { MatBottomSheetModule } from '@angular/material/bottom-sheet';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatNativeDateModule, MatRippleModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSliderModule } from '@angular/material/slider';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatSortModule } from '@angular/material/sort';
import { MatStepperModule } from '@angular/material/stepper';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatTreeModule } from '@angular/material/tree';
import { FlexLayoutModule } from '@angular/flex-layout';
import { ArticleComponent } from './pages/journal/article.component';
import { JournalComponent } from './pages/journal/journal.component';
import { CatalogueComponent } from './pages/catalogue/catalogue.component';
import { ProduitComponent } from './pages/catalogue/produit.component';
import { LoginComponent } from './login/login.component';
import { AuthGuard,ClientGuard } from './guards';
import {
  AuthenticationService,
  httpCacheInterceptorProviders,
  httpInterceptorProviders,
} from './services';
import { CurrencyPipe,DatePipe, registerLocaleData } from '@angular/common';
import localeFr from '@angular/common/locales/fr';
import localeFrExtra from '@angular/common/locales/extra/fr';
import { CarouselModule } from './carousel/carousel.module';
import { CategorieComponent } from './pages/catalogue/categorie.component';
import { PanierComponent } from './pages/catalogue/panier.component';
import { VerifComponent } from './pages/catalogue/commandes/verif.component';
import { CLientLoginComponent } from './pages/catalogue/clients/login.component';
import { ModComponent } from './pages/catalogue/clients/mod.component';
import { DialogRecupPassComponent } from './pages/catalogue/clients/dialog-recup-pass.component';
import { ValidComponent } from './pages/catalogue/commandes/valid.component';
import { ConsultComponent } from './pages/catalogue/commandes/consult.component';
import { RecupPassComponent } from './pages/catalogue/clients/recup-pass.component';
import { MoncompteComponent } from './pages/catalogue/clients/moncompte.component';
registerLocaleData(localeFr, 'fr-FR', localeFrExtra);
const cookieConfig: NgcCookieConsentConfig = {
  cookie: {
    domain: config.DOMAINE,
  },
  position: 'bottom-right',
  theme: 'classic',
  palette: {
    popup: {
      background: '#000000',
      text: '#ffffff',
      link: '#ffffff',
    },
    button: {
      background: '#f1d600',
      text: '#000000',
      border: 'transparent',
    },
  },
  type: 'info',
  content: {
    message: "Ce site utilise les cookies à des fins d'analyse d'audience.",
    dismiss: "Oui, j'ai compris!",
    deny: 'Refuser',
    link: "Plus d'informations",
    href: 'https://www.cnil.fr/fr/cookies-les-outils-pour-les-maitriser',
    header: 'Cookies sur le site!',
    allow: 'Autoriser les cookies',
    policy: 'Gestion des cookies',
  },
};

@NgModule({
  declarations: [
    AppComponent,
    PagesComponent,
    HomeComponent,
    PageItemViewComponent,
    PageSpecComponent,
    JournalComponent,
    ArticleComponent,
    CatalogueComponent,
    ProduitComponent,
    LoginComponent,
    CategorieComponent,
    PanierComponent,
    VerifComponent,
    CLientLoginComponent,
    ModComponent,
    DialogRecupPassComponent,
    ValidComponent,
    ConsultComponent,
    RecupPassComponent,
    MoncompteComponent,
    
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    TransferHttpCacheModule,
    BrowserTransferStateModule,
    FlexLayoutModule,
    HttpClientModule,
    AppRoutingModule,
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.production,
    }),
    SharedModule,
    CKEditorModule,
    FormsModule,
    ReactiveFormsModule,
    NgcCookieConsentModule.forRoot(cookieConfig),
    GtagModule.forRoot({
      trackingId: config.GTAGKEY,
      trackPageviews: config.GTAGKEY !== '',
    }),
    BrowserAnimationsModule,
    MatButtonModule,
    MatMenuModule,
    MatToolbarModule,
    MatSidenavModule,
    MatIconModule,
    MatAutocompleteModule,
    MatBadgeModule,
    MatBottomSheetModule,
    MatButtonToggleModule,
    MatCardModule,
    MatCheckboxModule,
    MatChipsModule,
    MatDatepickerModule,
    MatDialogModule,
    MatDividerModule,
    MatExpansionModule,
    MatGridListModule,
    MatInputModule,
    MatListModule,
    MatNativeDateModule,
    MatPaginatorModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatRadioModule,
    MatRippleModule,
    MatSelectModule,
    MatSliderModule,
    MatSlideToggleModule,
    MatSnackBarModule,
    MatSortModule,
    MatStepperModule,
    MatTableModule,
    MatTabsModule,
    MatTooltipModule,
    MatTreeModule,
    CarouselModule,
  ],
  providers: [
    AuthGuard,
    ClientGuard,
    AuthenticationService,
    httpInterceptorProviders,
    CurrencyPipe,DatePipe,
    { provide: LOCALE_ID, useValue: 'fr-FR' },
    { provide: DEFAULT_CURRENCY_CODE, useValue: 'EUR' },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
