import { PLATFORM_ID, Inject, Injectable } from '@angular/core';
import { Config, CONFIG } from '../../../config';
import { from, Observable } from 'rxjs';
import { isPlatformBrowser } from '@angular/common';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ClientControllerService, UserControllerService } from '../../api/services';
import { User } from 'src/api/models';
import { map, tap } from 'rxjs/operators';
import { tokenClient, tokenUser } from 'src/models';
import { Router } from '@angular/router';
export interface LoginReponse {
  token?: string;
  userProfile?: {
    role?: string;
    userName?: string;
    email?: string;
  };
}

@Injectable()
export class AuthenticationService {
  testBrowser: boolean;
  config: Config = CONFIG;
  httpOptions!: { headers: HttpHeaders };
  constructor(
    @Inject(PLATFORM_ID) platformId: string,
    private http: HttpClient,
    private userService: UserControllerService,
    private clientService: ClientControllerService,
    private router: Router,
  ) {
    this.testBrowser = isPlatformBrowser(platformId);
  }

  login(username: string, password: string): Observable<LoginReponse> {
    let body = { email: username, password: password };
    this.reset();this.resetClient();
    return this.userService.login({
      body,
    }) /* .pipe().map((loginReponse) => loginReponse) */;
  }
  loginClient(username: string, password: string): Observable<LoginReponse> {
    let body = { email: username, password: password };
    this.resetClient();this.reset();
    return this.clientService.login({
      body,
    }) /* .pipe().map((loginReponse) => loginReponse) */;
  }
  reset() {
    if (this.testBrowser) {
      localStorage.removeItem('currentUser');
    }
  }
  resetClient() {
    if (this.testBrowser) {
      localStorage.removeItem('clientUser');
    }
  }
  logOut() {
    localStorage.removeItem('currentUser');
    this.router.navigate(['/']);
  }
  logOutClient(){
    localStorage.removeItem('clientUser');
    this.router.navigate(['/']);
  }
  /*  getToken(accessToken, userId): Observable<any> {
    const getRole: string =
      this.config.urlApi +
      '/token?' +
      `access_token=${accessToken}&u=${userId}`;
    return this.http.get(getRole).pipe(map((res) => res));
  }
  test(accessToken, userId, role) {
    return !!this.getToken(accessToken, userId).subscribe((data) => {
      if (data.login && data.role === role) {
        return true;
      } else {
        return false;
      }
    });
  } */
  verifUser(): Observable<boolean> {
    return this.userService.printCurrentUser().pipe(
      map((res
      ) => {
        if (!this.testBrowser) return false;
        if (res.id) {
          //réécriture du localstorage
          const TT: tokenUser = JSON.parse(
            localStorage.getItem('currentUser')!
          );
          const TTnew: tokenUser = {
            token: TT.token,
            thisUser: {
              email: res.email!,
              userName: res.userName!,
              role: res.role!,
            },
          };
          localStorage.setItem('currentUser',JSON.stringify(TTnew))
        }
        return res.id?true:false;
      })
    )
  }
  verifClient(): Observable<boolean> {
   
    return this.clientService.printCurrentClient().pipe(
      map((res
      ) => {
        if (!this.testBrowser) return false;
        if (res.id) {
          //réécriture du localstorage si non expiré
          const TT: tokenClient = JSON.parse(
            localStorage.getItem('clientUser')!
          );
          if(Date.now() > TT.expire){//expiré
            this.resetClient();
            
            return false;
          }
          const TTnew: tokenClient = {
            token: TT.token,
            thisClient: {
              role: 'client',
              email:res.email!,
              nom: res.nom!,
              prenom:res.prenom!,
            },
            expire:Date.now() + (this.config.limites.expireLoginClient * 1000)
          };
          localStorage.setItem('clientUser',JSON.stringify(TTnew))
        }
        return res.id?true:false;
      })
    )
  }
  getUser(id: string): Observable<User> {
    return this.userService.findById({ userId: id }).pipe(
      map((res) => {
        return res;
      })
    );
  }
}
