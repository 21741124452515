import { Component, Inject, OnInit, PLATFORM_ID } from '@angular/core';

import { Subscription } from 'rxjs';
import {
  Commande,
  ConfigBoutiqueWithRelations,
  ConfigPartial as ConfigSite,
  Fidelite,
} from 'src/api/models';
import { completeClient, tokenClient } from 'src/models';
import { CONFIG, Config } from 'config';
import { isPlatformBrowser } from '@angular/common';
import { Router, RouterState, RouterStateSnapshot } from '@angular/router';
import {
  ConfigControllerService,
  CommandeControllerService,
  ClientControllerService,
  ConfigBoutiqueControllerService,
  FideliteControllerService,
} from 'src/api/services';

import * as CryptoJS from 'crypto-js';
import { EtatCommandes } from 'src/app/shared/etat-commandes.shared';

@Component({
  selector: 'app-moncompte',
  templateUrl: './moncompte.component.html',
  styleUrls: ['./../../styles.scss', './moncompte.component.scss'],
})
export class MoncompteComponent implements OnInit {
  public configSite!: ConfigSite;
  config: Config = CONFIG;
  tokenFromUI: string = this.config.PASSPHR;
  subConfig!: Subscription;

  moi!: completeClient;
  subMoi!: Subscription;
  testBrowser: boolean = false;
  user!: tokenClient;
  mescommande: Commande[] = [];
  subCommande!: Subscription;
  subUrl!: Subscription;
  snapshot!: string;
  private subFidelites: Subscription = new Subscription();
  public fidel: Fidelite[] = [];
  private subConfigBoutique: Subscription = new Subscription();
  configBoutique!: ConfigBoutiqueWithRelations;
  public arrayFidel: Array<{ id: string; class: string }> = [];
  constructor(
    @Inject(PLATFORM_ID) platformId: string,
    private intEtat: EtatCommandes,
    private router: Router,
    private configService: ConfigControllerService,
    private commandeService: CommandeControllerService,
    private clientsService: ClientControllerService,
    private configBoutiqueService: ConfigBoutiqueControllerService,
    private fidelitesService: FideliteControllerService
  ) {
    this.testBrowser = isPlatformBrowser(platformId);
  }

  ngOnInit(): void {
    const state: RouterState = this.router.routerState;
    this.snapshot = state.snapshot.url;
    this.subMoi = this.clientsService.printCurrentClient().subscribe((moi) => {
      this.moi = moi;
      this.subConfigBoutique = this.configBoutiqueService
        .findd4()
        .subscribe((confB) => {
          this.configBoutique = confB;
          this.subFidelites = this.fidelitesService
            .findByIdClient({ id: this.moi.id! })
            .subscribe((fidel) => {
              this.fidel = fidel as Array<Fidelite>;
              for (let i = 0; i < this.configBoutique.fidelNbrcommande!; i++) {
                this.fidel[0].idscommandes![i]
                  ? this.arrayFidel.push({
                      id: this.fidel[0].idscommandes![i],
                      class: 'on',
                    })
                  : this.arrayFidel.push({ id: '', class: 'off' });
              }
            });
        });
      this.subCommande = this.commandeService
        .find({
          filter: {
            filter: `{"order":"date DESC","where":{"clientId":"${moi.id}"}}`,
          },
        })
        .subscribe((coms) => {
          this.mescommande = coms;
        });
    });
  }
  modPass() {
    const state: RouterState = this.router.routerState;
    const snapshot: RouterStateSnapshot = state.snapshot;
    this.clientsService
      .recupMail({ email: `${this.moi.email}` })
      .subscribe((code: string | null) => {
        if (code !== null) {
          this.router.navigate(
            [
              '/',
              this.config.lienSpec.CATALOGUE,
              'client',
              'recuppass',
              this.encryptUsingAES256(code!),
            ],
            { queryParams: { returnUrl: snapshot.url } }
          );
        }
      });
  }
  encryptUsingAES256(chaine: string) {
    let _key = CryptoJS.enc.Utf8.parse(this.tokenFromUI);
    let _iv = CryptoJS.enc.Utf8.parse(this.tokenFromUI);
    let encrypted = CryptoJS.AES.encrypt(JSON.stringify(chaine), _key, {
      keySize: this.tokenFromUI.length,
      iv: _iv,
      mode: CryptoJS.mode.ECB,
      padding: CryptoJS.pad.Pkcs7,
    });
    return encrypted.toString();
  }
  etat(code: string) {
    return this.intEtat.etat(code);
  }
}
