<div class="cont">
    <h1> {{produit?.nom}} </h1>
    <div class="ref">Ref. {{produit?.ref}} </div>
    <div class="catégorie" fxLayout="row wrap" fxLayoutGap="10px" fxLayoutAlign="left start">
        <div *ngFor="let item of catsProduit"><a
                [routerLink]="['/',config.lienSpec.CATALOGUE,'categorie',item.menu]">{{catEtig(item?.id!)}}</a></div>
    </div>
    <div fxLayout="row wrap" fxLayout.lt-md="column" fxLayoutWrap fxLayoutGap="10px" fxLayoutAlign="center start"
        fxLayoutAlign.lt-md="center center">
        <div fxFlex *ngIf="this.produitImages.length">
            <div class="containerCarousel" *ngIf="produitImages.length">
                <carousel [slides]="produitImages" [animationType]="animationType"></carousel>
            </div>
        </div>
        <div class="dyn" fxFlex>
            <div class="resume">{{produit?.resume}}</div>

            <div class="prixaction">
                <h3>Commander</h3>
                <div fxLayout="column wrap" fxLayoutGap="10px" fxLayoutAlign="center center">
                    <div class="prix">
                        <div [class]="produit?.prixbarre! >0?'barre':'prix'">{{prixTTC | currency}}
                        </div>
                        <div class="prix" *ngIf="prixBarreTTC! >0">{{prixBarreTTC | currency}}
                        </div>
                    </div>
                    <div class="actionpanier" fxLayout="row wrap" fxLayoutGap="10px" fxLayoutAlign="center center">
                        <div *ngFor="let attribu of attribus; let id=index">

                            <mat-form-field appearance="fill">
                                <mat-label> {{attribu.etiquette}} </mat-label>
                                <mat-select [(value)]="attributSel[id]" (selectionChange)="calcVal()">
                                    <mat-option *ngFor="let attr of attributSelEl[id]; let i=index" [value]="i">
                                        {{attr.choix}}{{attr.plusvalue=='0'?'':' (+'+(attr.plusvalue | currency)+')'}}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                        <div>
                            <mat-form-field appearance="fill">
                                <mat-label>Quantité</mat-label>
                                <input #qtt matInput type="number" placeholder="{{minProd}}"
                                    [(ngModel)]="nbrProduit" (ngModelChange)="calcVal()" [max]="maxProd"
                                    (focus)="qtt.value=''" (blur)="qtt.value=nbrProduit.toString();calcVal()">
                                <span matSuffix> {{produit?.unite}} </span>
                            </mat-form-field>
                        </div>


                    </div>
                    <div class="adPanier" *ngIf="produit?.stock">
                        <button mat-fab title="Ajouter {{produit?.nom}} au panier." (click)="adPannier(nbrProduit)"
                            color="primary"
                            [disabled]="(produit?.stock!<nbrProduit! || produit?.stock!<nbrHorsAttributs+nbrProduit! || testNbr || !produit.actif  || nbrProduit<minProd)">
                            <mat-icon class="addPanierBtn">shopping_cart</mat-icon>
                        </button>
                    </div>
                </div>
            </div>
            <div class="description" [innerHtml]="produit?.description | unsafe:'html'"></div>


        </div>

    </div>
    <div class="associes" *ngIf="produitAsso.length">
        <h3>Vous pourriez aussi être intéressé par :</h3>
        <div fxLayout="row wrap" fxLayoutGap="5px" fxLayoutAlign="center stretch">
            <mat-card class="produit" *ngFor="let produit of produitAsso"
                [routerLink]="['/',config.lienSpec.CATALOGUE,'fiche',produit.ref,produit.url]">
                <mat-card-header>
                    <mat-card-title>{{produit?.nom}}</mat-card-title>
                </mat-card-header>
                <mat-card-content>
                    <figure *ngIf="produit?.images!.length" class="imgproduit"><img class="mat-elevation-z2 lazyload"
                            data-src="{{produit?.images![0].replace('_max.', '_med.')}}" [alt]="produit?.nom">
                    </figure>
                    <div class="resume">{{produit?.resume}}</div>
                </mat-card-content>
                <mat-card-actions class="prodAction" [title]="produit?.resume">
                    <div fxLayout="row" fxLayoutGap="5px" fxLayoutAlign="center center">
                        <div [class]="produit?.prixbarre! >0?'barre':'prix'">
                            {{produit.prixTTC| currency}}
                        </div>
                        <div class="prix" *ngIf="produit?.prixbarre! >0">{{produit.prixBarreTTC| currency}}
                        </div>
                    </div>
                    <a mat-icon-button title="Voir la fiche {{produit?.nom}}"
                        [routerLink]="['/',config.lienSpec.CATALOGUE,'fiche',produit.ref,produit.url]">
                        <mat-icon>launch</mat-icon>
                    </a>
                </mat-card-actions>
            </mat-card>
        </div>
    </div>
</div>