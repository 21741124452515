<div class="cont">
    <h1>{{title}}</h1>

    <div class="contformClient">
        <form [formGroup]="passForm" (ngSubmit)="majPass();" *ngIf="clientRecupPass">
            <mat-card class="form-signin mat-elevation-z8">
                <mat-card-header>
                    <mat-card-title>Créez votre nouveau mot de passe</mat-card-title>
                    <mat-card-subtitle>Votre mot de passe doit contenir au moins 8 caractères</mat-card-subtitle>
                </mat-card-header>
                <mat-card-content>


                    <mat-form-field class="full-width" appearance="outline">
                        <mat-label>Nouveau mot de passe</mat-label>
                        <input matInput id="password" placeholder="Nouveau mot de passe"
                            [type]="hide ? 'password' : 'text'" formControlName="password" autocomplete="new-password"
                            required>
                        <mat-icon matSuffix (click)="hide = !hide">{{hide ? 'visibility' : 'visibility_off'}}</mat-icon>
                        <mat-error *ngIf="passForm.controls.password.invalid">Votre mot de passe doit faire au moins 8
                            caractères
                        </mat-error>
                    </mat-form-field>


                    <mat-form-field class="full-width" appearance="outline">
                        <mat-label>Confirmez le mot de passe</mat-label>
                        <input matInput placeholder="Confirmez le mot de passe" [type]="hide ? 'password' : 'text'"
                            formControlName="password2" autocomplete="new-password" required>
                        <mat-icon matSuffix (click)="hide = !hide">{{hide ? 'visibility' : 'visibility_off'}}</mat-icon>

                        <mat-error *ngIf="passForm.controls.password2.invalid">Les mots de passes ne correspondent pas.
                        </mat-error>
                    </mat-form-field>


                </mat-card-content>
                <mat-card-actions>
                    <button mat-raised-button color="primary" [disabled]="!passForm.valid"
                        type="submit">Modifier</button>
                </mat-card-actions>
                <mat-error [hidden]="error == ''" style="text-align: center;">

                    {{ error }}

                </mat-error>
            </mat-card>

        </form>
    </div>
</div>