/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { Client } from '../models/client';
import { ClientWithRelations } from '../models/client-with-relations';
import { Count as LoopbackCount } from '../models/loopback/count';

@Injectable({
  providedIn: 'root',
})
export class ClientControllerService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation clientControllerCount
   */
  static readonly ClientControllerCountPath = '/clients/count';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `count()` instead.
   *
   * This method doesn't expect any request body.
   */
  count$Response(params?: {
    where?: any;
  }): Observable<StrictHttpResponse<LoopbackCount>> {

    const rb = new RequestBuilder(this.rootUrl, ClientControllerService.ClientControllerCountPath, 'get');
    if (params) {
      rb.query('where', params.where, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<LoopbackCount>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `count$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  count(params?: {
    where?: any;
  }): Observable<LoopbackCount> {

    return this.count$Response(params).pipe(
      map((r: StrictHttpResponse<LoopbackCount>) => r.body as LoopbackCount)
    );
  }

  /**
   * Path part for operation clientControllerUpdateInfosById
   */
  static readonly ClientControllerUpdateInfosByIdPath = '/clients/infos/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateInfosById()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateInfosById$Response(params: {
    id: string;

    /**
     * Client Infos functions
     */
    body: { 'id': string, 'email'?: string, 'nom'?: string, 'prenom'?: string, 'adresse'?: string, 'adresse2'?: string, 'cp'?: string, 'ville'?: string, 'tel'?: string, 'recupCode'?: string }
  }): Observable<StrictHttpResponse<any>> {

    const rb = new RequestBuilder(this.rootUrl, ClientControllerService.ClientControllerUpdateInfosByIdPath, 'patch');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<any>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `updateInfosById$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateInfosById(params: {
    id: string;

    /**
     * Client Infos functions
     */
    body: { 'id': string, 'email'?: string, 'nom'?: string, 'prenom'?: string, 'adresse'?: string, 'adresse2'?: string, 'cp'?: string, 'ville'?: string, 'tel'?: string, 'recupCode'?: string }
  }): Observable<any> {

    return this.updateInfosById$Response(params).pipe(
      map((r: StrictHttpResponse<any>) => r.body as any)
    );
  }

  /**
   * Path part for operation clientControllerLogin
   */
  static readonly ClientControllerLoginPath = '/clients/login';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `login()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  login$Response(params: {

    /**
     * User functions
     */
    body: { 'email': string, 'password': string }
  }): Observable<StrictHttpResponse<{ 'token'?: string }>> {

    const rb = new RequestBuilder(this.rootUrl, ClientControllerService.ClientControllerLoginPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{ 'token'?: string }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `login$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  login(params: {

    /**
     * User functions
     */
    body: { 'email': string, 'password': string }
  }): Observable<{ 'token'?: string }> {

    return this.login$Response(params).pipe(
      map((r: StrictHttpResponse<{ 'token'?: string }>) => r.body as { 'token'?: string })
    );
  }

  /**
   * Path part for operation clientControllerModPasse
   */
  static readonly ClientControllerModPassePath = '/clients/modpasse/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `modPasse()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  modPasse$Response(params: {
    id: string;

    /**
     * Client functions
     */
    body: { 'email': string, 'password': string, 'recupCode'?: string }
  }): Observable<StrictHttpResponse<any>> {

    const rb = new RequestBuilder(this.rootUrl, ClientControllerService.ClientControllerModPassePath, 'patch');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<any>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `modPasse$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  modPasse(params: {
    id: string;

    /**
     * Client functions
     */
    body: { 'email': string, 'password': string, 'recupCode'?: string }
  }): Observable<any> {

    return this.modPasse$Response(params).pipe(
      map((r: StrictHttpResponse<any>) => r.body as any)
    );
  }

  /**
   * Path part for operation clientControllerPrintCurrentClient
   */
  static readonly ClientControllerPrintCurrentClientPath = '/clients/moi';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `printCurrentClient()` instead.
   *
   * This method doesn't expect any request body.
   */
  printCurrentClient$Response(params?: {
  }): Observable<StrictHttpResponse<{ 'id': string, 'email'?: string, 'nom'?: string, 'prenom'?: string, 'adresse'?: string, 'adresse2'?: string, 'cp'?: string, 'ville'?: string, 'tel'?: string, 'recupCode'?: string }>> {

    const rb = new RequestBuilder(this.rootUrl, ClientControllerService.ClientControllerPrintCurrentClientPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{ 'id': string, 'email'?: string, 'nom'?: string, 'prenom'?: string, 'adresse'?: string, 'adresse2'?: string, 'cp'?: string, 'ville'?: string, 'tel'?: string, 'recupCode'?: string }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `printCurrentClient$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  printCurrentClient(params?: {
  }): Observable<{ 'id': string, 'email'?: string, 'nom'?: string, 'prenom'?: string, 'adresse'?: string, 'adresse2'?: string, 'cp'?: string, 'ville'?: string, 'tel'?: string, 'recupCode'?: string }> {

    return this.printCurrentClient$Response(params).pipe(
      map((r: StrictHttpResponse<{ 'id': string, 'email'?: string, 'nom'?: string, 'prenom'?: string, 'adresse'?: string, 'adresse2'?: string, 'cp'?: string, 'ville'?: string, 'tel'?: string, 'recupCode'?: string }>) => r.body as { 'id': string, 'email'?: string, 'nom'?: string, 'prenom'?: string, 'adresse'?: string, 'adresse2'?: string, 'cp'?: string, 'ville'?: string, 'tel'?: string, 'recupCode'?: string })
    );
  }

  /**
   * Path part for operation clientControllerUpdateMe
   */
  static readonly ClientControllerUpdateMePath = '/clients/moi';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateMe()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateMe$Response(params: {

    /**
     * Client functions
     */
    body: { 'email': string, 'nom'?: string, 'prenom'?: string, 'adresse'?: string, 'adresse2'?: string, 'cp'?: string, 'ville'?: string, 'tel'?: string, 'recupCode'?: string }
  }): Observable<StrictHttpResponse<any>> {

    const rb = new RequestBuilder(this.rootUrl, ClientControllerService.ClientControllerUpdateMePath, 'patch');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<any>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `updateMe$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateMe(params: {

    /**
     * Client functions
     */
    body: { 'email': string, 'nom'?: string, 'prenom'?: string, 'adresse'?: string, 'adresse2'?: string, 'cp'?: string, 'ville'?: string, 'tel'?: string, 'recupCode'?: string }
  }): Observable<any> {

    return this.updateMe$Response(params).pipe(
      map((r: StrictHttpResponse<any>) => r.body as any)
    );
  }

  /**
   * Path part for operation clientControllerRecupMail
   */
  static readonly ClientControllerRecupMailPath = '/clients/recupmail/{email}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `recupMail()` instead.
   *
   * This method doesn't expect any request body.
   */
  recupMail$Response(params: {
    email: string;
  }): Observable<StrictHttpResponse<string>> {

    const rb = new RequestBuilder(this.rootUrl, ClientControllerService.ClientControllerRecupMailPath, 'get');
    if (params) {
      rb.path('email', params.email, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<string>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `recupMail$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  recupMail(params: {
    email: string;
  }): Observable<string> {

    return this.recupMail$Response(params).pipe(
      map((r: StrictHttpResponse<string>) => r.body as string)
    );
  }

  /**
   * Path part for operation clientControllerCreate
   */
  static readonly ClientControllerCreatePath = '/clients/sign-up';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `create()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  create$Response(params: {

    /**
     * Client functions
     */
    body: { 'email': string, 'password': string, 'nom'?: string, 'prenom'?: string, 'adresse'?: string, 'adresse2'?: string, 'cp'?: string, 'ville'?: string, 'tel'?: string, 'recupCode'?: string, 'date'?: string }
  }): Observable<StrictHttpResponse<Client>> {

    const rb = new RequestBuilder(this.rootUrl, ClientControllerService.ClientControllerCreatePath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Client>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `create$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  create(params: {

    /**
     * Client functions
     */
    body: { 'email': string, 'password': string, 'nom'?: string, 'prenom'?: string, 'adresse'?: string, 'adresse2'?: string, 'cp'?: string, 'ville'?: string, 'tel'?: string, 'recupCode'?: string, 'date'?: string }
  }): Observable<Client> {

    return this.create$Response(params).pipe(
      map((r: StrictHttpResponse<Client>) => r.body as Client)
    );
  }

  /**
   * Path part for operation clientControllerVerifCode
   */
  static readonly ClientControllerVerifCodePath = '/clients/verifcode/{code}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `verifCode()` instead.
   *
   * This method doesn't expect any request body.
   */
  verifCode$Response(params: {
    code: string;
  }): Observable<StrictHttpResponse<any>> {

    const rb = new RequestBuilder(this.rootUrl, ClientControllerService.ClientControllerVerifCodePath, 'get');
    if (params) {
      rb.path('code', params.code, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<any>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `verifCode$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  verifCode(params: {
    code: string;
  }): Observable<any> {

    return this.verifCode$Response(params).pipe(
      map((r: StrictHttpResponse<any>) => r.body as any)
    );
  }

  /**
   * Path part for operation clientControllerFindById
   */
  static readonly ClientControllerFindByIdPath = '/clients/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `findById()` instead.
   *
   * This method doesn't expect any request body.
   */
  findById$Response(params: {
    id: string;
    filter?: any;
  }): Observable<StrictHttpResponse<ClientWithRelations>> {

    const rb = new RequestBuilder(this.rootUrl, ClientControllerService.ClientControllerFindByIdPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
      rb.query('filter', params.filter, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ClientWithRelations>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `findById$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  findById(params: {
    id: string;
    filter?: any;
  }): Observable<ClientWithRelations> {

    return this.findById$Response(params).pipe(
      map((r: StrictHttpResponse<ClientWithRelations>) => r.body as ClientWithRelations)
    );
  }

  /**
   * Path part for operation clientControllerDeleteById
   */
  static readonly ClientControllerDeleteByIdPath = '/clients/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteById()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteById$Response(params: {
    id: string;
  }): Observable<StrictHttpResponse<any>> {

    const rb = new RequestBuilder(this.rootUrl, ClientControllerService.ClientControllerDeleteByIdPath, 'delete');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<any>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `deleteById$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteById(params: {
    id: string;
  }): Observable<any> {

    return this.deleteById$Response(params).pipe(
      map((r: StrictHttpResponse<any>) => r.body as any)
    );
  }

  /**
   * Path part for operation clientControllerUpdateById
   */
  static readonly ClientControllerUpdateByIdPath = '/clients/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateById()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateById$Response(params: {
    id: string;

    /**
     * Client functions
     */
    body: { 'email': string, 'password': string, 'nom'?: string, 'prenom'?: string, 'adresse'?: string, 'adresse2'?: string, 'cp'?: string, 'ville'?: string, 'tel'?: string, 'recupCode'?: string, 'date'?: string }
  }): Observable<StrictHttpResponse<any>> {

    const rb = new RequestBuilder(this.rootUrl, ClientControllerService.ClientControllerUpdateByIdPath, 'patch');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<any>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `updateById$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateById(params: {
    id: string;

    /**
     * Client functions
     */
    body: { 'email': string, 'password': string, 'nom'?: string, 'prenom'?: string, 'adresse'?: string, 'adresse2'?: string, 'cp'?: string, 'ville'?: string, 'tel'?: string, 'recupCode'?: string, 'date'?: string }
  }): Observable<any> {

    return this.updateById$Response(params).pipe(
      map((r: StrictHttpResponse<any>) => r.body as any)
    );
  }

  /**
   * Path part for operation clientControllerFind
   */
  static readonly ClientControllerFindPath = '/clients';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `find()` instead.
   *
   * This method doesn't expect any request body.
   */
  find$Response(params?: {
    filter?: any;
  }): Observable<StrictHttpResponse<Array<ClientWithRelations>>> {

    const rb = new RequestBuilder(this.rootUrl, ClientControllerService.ClientControllerFindPath, 'get');
    if (params) {
      rb.query('filter', params.filter, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<ClientWithRelations>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `find$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  find(params?: {
    filter?: any;
  }): Observable<Array<ClientWithRelations>> {

    return this.find$Response(params).pipe(
      map((r: StrictHttpResponse<Array<ClientWithRelations>>) => r.body as Array<ClientWithRelations>)
    );
  }

}
