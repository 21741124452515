<div style="height: 100vh;background: gray;">

  <!--  -->
  <mat-card style="margin: auto">
    <h2>Fichiers du site</h2>
    <mat-card-header>
      <mat-card-title>Ajouter des Fichier (Images, Vidéos, PDF) au dossier "{{cont}}"</mat-card-title>
    </mat-card-header>
    <mat-card-content>
      <div style="width: 450px; margin: auto; text-align: center;">
        <ul>
          <li *ngFor="let file of files">
            <mat-progress-bar color="accent" mode="indeterminate" *ngIf="file.inProgress"></mat-progress-bar>
            <span id="file-label">
              {{file.data.name}} {{file.OK}}
            </span>
          </li>
        </ul> <button mat-raised-button color="warn" (click)="onClick()">
          <mat-icon>file_upload</mat-icon>
          Cliquez ici pour ajouter des images
        </button> <input type="file" #fileUpload id="fileUpload" name="fileUpload" multiple="multiple"
          accept="image/*,.pdf,video/*" style="display:none;" />
      </div>
      <mat-divider></mat-divider>
      <mat-toolbar>
        <span>Mes fichiers dans "{{cont}}":</span>
      </mat-toolbar>

      <mat-grid-list cols="6" rowHeight="1:1">
        <mat-grid-tile *ngFor="let image of images">
          <img [src]="getMed(cont!,'_med.'+image.name)" style="max-width:100%;height:auto" [title]="image.name"
            (click)="returnFileUrl(image.name)">
          <mat-grid-tile-footer>{{image.name.substring(0,20)}}<button type="button" mat-icon-button
              (click)="delete(image.name)">
              <mat-icon>clear</mat-icon>
            </button></mat-grid-tile-footer>
        </mat-grid-tile>
      </mat-grid-list>
    </mat-card-content>
  </mat-card>
</div>