import { PLATFORM_ID, Inject, Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { isPlatformBrowser } from '@angular/common';
import { AuthenticationService } from '../services/index';
import { CurrentUser } from 'src/models';

@Injectable()
export class AuthGuard implements CanActivate {
  user!: CurrentUser;
  userRole!:string;
  testBrowser: boolean;
  login = false;
  constructor(
    @Inject(PLATFORM_ID) platformId: string,
    private router: Router,
    private http: HttpClient,
    private authenticationService: AuthenticationService) { this.testBrowser = isPlatformBrowser(platformId); }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    if ((this.testBrowser) && localStorage.getItem('currentUser')) {

      this.user = JSON.parse(localStorage.getItem('currentUser')!);
      return !!this.authenticationService.verifUser().subscribe(
        data => {
            return data;
        },
        error => {
          console.log(error);
          this.authenticationService.reset();
          this.router.navigate(['/login'], { queryParams: { returnUrl: state.url } });
          return false;
        });


    } else {
      this.router.navigate(['/login'], { queryParams: { returnUrl: state.url } });
      return false;
    }


  }

}
