import {
  Component,
  Inject,
  OnDestroy,
  OnInit,
  PLATFORM_ID,
} from '@angular/core';
import { Location } from '@angular/common';
import { Meta, Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
import {
  ConfigBoutique,
  Produit,
  Categorie,
  ConfigPartial as ConfigSite,
  Tva,
  PageWithRelations,
} from 'src/api/models';
import {
  PagesControllerService,
  ConfigControllerService,
  ProduitControllerService,
  TvaControllerService,
  CategoriesControllerService,
  ConfigBoutiqueControllerService,
} from 'src/api/services';
import { ProduitAff } from 'src/models';
import { Config, CONFIG } from '../../../../config';
interface produitCat {
  cat: String;
  description: string | undefined;
  produits: Produit[];
}
@Component({
  selector: 'app-catalogue',
  templateUrl: './catalogue.component.html',
  styleUrls: ['../styles.scss', './catalogue.component.scss'],
})
export class CatalogueComponent implements OnInit, OnDestroy {
  configBoutique: ConfigBoutique = {
    genNbrProduitCat: 10,
    genNbrProduitStar: 10,
    d4: 'd4'
  };
  produits: ProduitAff[] = [];
  produitCat: produitCat[] = [];
  categories: Categorie[] = [];
  tvas: Tva[] = [];
  stars: any[any] = [];
  public configSite!: ConfigSite;
  config: Config = CONFIG;
  subConfigBoutique!: Subscription;
  subProduits!: Subscription;
  subCategories!: Subscription;
  subProduitsStars!: Subscription;
  subPage!: Subscription;
  page!: PageWithRelations;
  subConfig!: Subscription;
  subCat!: Subscription;
  cats!: Categorie[];
  subSsCat!: Subscription;
  menuCat: any[] = [];
  tempmenuCat: any[] = [];
  tabCat: any[any] = [];
  subTva!: Subscription;
  tabTva: any[any] = [];
  produitsLimit: ProduitAff[] = [];
  nbrProduits: number = 0;
  paramsSub!: Subscription;
  nbr: number = 1;

  constructor(
    @Inject(PLATFORM_ID) platformId: string,
    private readonly meta: Meta,
    private titre: Title,
    private pagesService: PagesControllerService,
    private configBoutiqueService: ConfigBoutiqueControllerService,
    private configService: ConfigControllerService,
    private produitService: ProduitControllerService,
    private catService: CategoriesControllerService,
    private tvaService: TvaControllerService,
    private router: Router,
    private route: ActivatedRoute,
    private location: Location
  ) {}

  ngOnInit(): void {
    this.subConfigBoutique = this.configBoutiqueService
      .findd4()
      .subscribe((confBoutique) => {
        /*  if (!confBoutique.ligne!) {
          this.router.navigate(['/']);
        } */
      });
    this.subConfig = this.configService.findd4().subscribe((config) => {
      this.configSite = config;
      this.subPage = this.pagesService
        .find({ filter: { filter: `{"where": { "type": "CATALOGUE" } }` } })
        .subscribe((page) => {
          this.page = page[0];
          //lazy-loading
          this.page.html = this.page.html
            ? this.page.html
                .replace(/<img /gi, '<img class="lazyload" ')
                .replace(/(<img[^>]*)src=/gi, '$1data-src=')
            : '';
          // metas
          const Tags = [
            "name='description'",
            "property= 'og:url'",
            "property='og:title'",
            "property='og:description'",
            "property='og:image'",
            "property='og:locale'",
            "property='og:type'",
            "property='og:site_name'",
            "name='twitter:card'",
            "name='twitter:description'",
            "name='twitter:title'",
            "name='twitter:image'",
          ];
          Tags.map((e) => this.meta.removeTag(e));
          const title = this.page.titre + ' - ' + this.configSite.title!;
          const description = this.page.description || '';
          this.titre.setTitle(title);
          let ogimage = this.page.ogimage || this.config.imageUrl || '';
          this.meta.addTags([
            { name: 'description', content: description },
            { property: 'og:url', content: this.config.applicationUrl },
            { property: 'og:title', content: title },
            { property: 'og:description', content: description },
            { property: 'og:image', content: ogimage },
            { property: 'og:locale', content: 'fr_FR' },
            { property: 'og:type', content: 'website' },
            {
              property: 'og:site_name',
              content: this.configSite.title!,
            },
            { name: 'twitter:card', content: 'summary' },
            { name: 'twitter:description', content: description },
            { name: 'twitter:title', content: title },
            { name: 'twitter:image', content: ogimage },
          ]);
        });
    });
    this.paramsSub = this.route.params
      .pipe(map((params) => params['nbr']))
      .subscribe((nbr) => {
        if (nbr) this.nbr = Math.max(Number(nbr), 1);
        this.subConfigBoutique = this.configBoutiqueService
          .findd4()
          .subscribe((configs) => {
            this.configBoutique = configs;
            this.nbrProduits = this.configBoutique.genNbrProduitCat * this.nbr;
            this.subCat = this.catService
              .find({
                filter: {
                  filter: `{"order":["parent ASC","ordre ASC"]}`,
                },
              })
              .subscribe((cats) => {
                this.cats = cats;
                this.cats.map((cat) => {
                  this.tabCat[cat.id!] = {
                    etiquette: cat.etiquette,
                    parent: cat.parent,
                  };
                });
                this.subTva = this.tvaService.find().subscribe((tva) => {
                  this.tvas = tva;
                  tva.map((restva) => {
                    this.tabTva[restva.id!] = restva.taux;
                  });

                  this.subProduits = this.produitService
                    .find({
                      filter: {
                        filter: `{"order": "nom ASC","where":{"and":[{"actif":true},{"stock":{"gt":0}}]}}`,
                      },
                    })
                    .subscribe((produits) => {
                      produits.map((p) => {
                        const prixTTC =
                          p.prixHT + (p.prixHT * this.tabTva[p.tvaId]) / 100;
                        const prixBarreTTC =
                          p.prixbarre! > 0
                            ? p.prixbarre! +
                              (p.prixbarre! * this.tabTva[p.tvaId]) / 100
                            : 0;
                        this.produits.push(
                          Object.assign(p, {
                            prixTTC: prixTTC,
                            prixBarreTTC: prixBarreTTC,
                            categorieEtiquette: this.affichCat(p.categorieId!),
                          })
                        );
                      });
                      this.produitsLimit = this.produits.slice(
                        0,
                        this.nbrProduits
                      );
                      //console.log(this.produits);
                    });
                });
              });
          });
      });
    this.getCatsChild('')
      .then((out) => {
        this.tempmenuCat = out;
      })
      .finally(() => {
        this.menuCat = this.tempmenuCat;
      });
  }
  plus() {
    this.nbrProduits += this.configBoutique.genNbrProduitCat;
    this.produitsLimit = this.produits.slice(0, this.nbrProduits);
    this.nbr++;
    this.location.replaceState('catalogue/page/' + this.nbr, '', true);
  }

  affichCat(id: string[]) {
    let strOut: string[] = [];
    id.map((id) => {
      strOut.push(this.tabCat[id]?.etiquette);
    });
    return strOut.join(' ');
  }
  ngOnDestroy(): void {
    this.unscribe(this.subConfigBoutique);
    this.unscribe(this.subCategories);
    this.unscribe(this.subProduits);
    this.unscribe(this.subConfigBoutique);
  }

  unscribe(sub: Subscription): void {
    if (sub) sub.unsubscribe();
  }

  async getCatsChild(id: string): Promise<any[]> {
    let tabCats: any[] = [];
    const res = await this.catService
      .find({
        filter: {
          filter: `{"where":{"and":[{"parent":"${id}"},{"visible":{"neq":false}}]},"order":["ordre ASC"]}`,
        },
      })
      .toPromise();
    res.map(async (cat) => {
      const enfants = await this.getCatsChild(cat.id!).then((out) => {
        return out;
      });
      tabCats.push({
        id: cat.id,
        ordre: cat.ordre,
        menu: cat.menu,
        etiquette: cat.etiquette,
        enfants: enfants,
      });
    });
    return tabCats
      .sort((a, b) => {
        return this.compare(a.ordre, b.ordre, true);
      })
      .sort((a, b) => {
        return this.compare(a.parent, b.parent, true);
      });
  }
  compare(a: number | string, b: number | string, isAsc: boolean) {
    return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
  }
}
