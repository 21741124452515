<div class="cont"  *ngIf="page?.html">

  <h1>{{page?.h1}}</h1>


  <div *ngIf="produitsStars.length" class="produitsStars">
    <h4>{{starsEtiquette}}</h4>
    <div fxLayout="row wrap" fxLayoutGap="5px"
    fxLayoutAlign="center stretch" ><!-- fxLayout.lt-sm="column" -->  
      <mat-card class="produit" *ngFor="let star of produitsStars" [routerLink]="['/',config.lienSpec.CATALOGUE,'fiche',star.ref,star.url]">
        <mat-card-header>
          <mat-card-title>{{star?.nom}}</mat-card-title>
        </mat-card-header>
        <mat-card-content  [matTooltip]="star?.resume!"
        matTooltipPosition="below">
          <figure *ngIf="star?.images!.length" class="imgproduit"><img class="mat-elevation-z2 lazyload"
            data-src="{{star?.images![0].replace('_max.', '_med.')}}" [alt]="star?.nom"></figure>
            <div class="resume">{{star?.resume}}</div>
        </mat-card-content>
        <mat-card-actions class="prodAction" [title]="star?.resume">
          <div fxLayout="row" fxLayoutGap="5px" fxLayoutAlign="center center">
            <div [class]="star?.prixbarre! >0?'barre':'prix'">
                {{star.prixTTC| currency}} / {{star?.unite}}
            </div>
            <div class="prix" *ngIf="star?.prixbarre! >0">{{star.prixBarreTTC| currency}} / {{star?.unite}}
            </div>
        </div>
          <a mat-icon-button title="Voir la fiche {{star?.nom}}" [routerLink]="['/',config.lienSpec.CATALOGUE,'fiche',star.ref,star.url]"><mat-icon>launch</mat-icon></a>
        </mat-card-actions>
      </mat-card>
      
    </div>

    <div class="action">
      <a [routerLink]="['/',config.lienSpec.CATALOGUE]">
        <mat-icon>storefront</mat-icon> Voir le catalogue
      </a>
    </div>
  </div>


  <div fxLayout="row wrap" fxLayout.lt-md="column-reverse" fxLayoutWrap fxLayoutGap="10px" fxLayoutAlign="center start"
    fxLayoutAlign.lt-md="center center">

    <div id="page" class="dyn" [innerHtml]="page?.html | unsafe:'html'" fxFlex> </div>

    <div id="modules" *ngIf="agendaTest || journalTest" fxFlex.gt-md="2 2 20%">


      <!-- <div class="agenda mat-elevation-z2" *ngIf="agendaTest">
        <h4>Agenda</h4>
        <div *ngFor="let date of agenda">
          <h5>{{date.titre}}</h5>
          <h6>
            {{date.dateau > date.datedu ? "du "+(date.datedu | date:'medium')+" au "+(date.dateau | date:'medium') : "Le "+(date.datedu  | date:'medium')}}
          </h6>
          <p>{{date.description}}</p>
          <div class="action"><a mat-button [routerLink]="['/','date',date.datedu,date.url]"
              *ngIf="date.html.length>10">
              Lire la suite <mat-icon>chevron_right</mat-icon></a></div>
        </div>
      </div> -->
      <div class="journal mat-elevation-z5" *ngIf="journalTest">
        <h4>À la Une</h4>
        <div *ngFor="let article of journal">
          <h5>{{article.titre}}</h5>
          <figure  class="imgarticle"><img *ngIf="article?.ogimage" class="mat-elevation-z5" [src]="article?.ogimage?.replace('/media/blog/', '/media/blog/_med.')" [alt]="article.titre">
          <figcaption  [innerHtml]="article.description | nl2br"></figcaption></figure>
          <div class="action">
            <a [routerLink]="['/','article',article.url]">
              <mat-icon>launch</mat-icon> Lire "{{article.titre}}"
            </a>
          </div>
        </div>
        <div class="action">
          <a [routerLink]="['/','articles']">
            <mat-icon>list</mat-icon> Voir tous les Articles
          </a>
        </div>
      </div>
    </div>
  </div>

</div>