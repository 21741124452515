import { Component, Inject, OnInit } from '@angular/core';
import { EmailValidator, FormBuilder, FormControl, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

export interface DialogData {
  mail: string;
}

@Component({
  selector: 'app-recup-pass',
  templateUrl: './dialog-recup-pass.component.html',
  styleUrls: ['../../styles.scss','./dialog-recup-pass.component.scss'],
})
export class DialogRecupPassComponent implements OnInit {
  mailform= new FormControl('',[Validators.required, Validators.email]);
  constructor(
    public dialogRef: MatDialogRef<DialogRecupPassComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
  ) {}

  ngOnInit(): void {
  }
}
