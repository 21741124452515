<div class="cont">

  <h1> {{page?.titre}} </h1>
  <div id="page" class="dyn" [innerHtml]="page?.html | unsafe:'html'"> </div>

  <table mat-table *ngIf="panier.produits.length" [dataSource]="panier.produits" class="mat-elevation-z3 panier-table">
    <ng-container matColumnDef="ref">
      <th mat-header-cell *matHeaderCellDef> Ref. </th>
      <td mat-cell *matCellDef="let element"> {{element.ref}} </td>

    </ng-container>

    <ng-container matColumnDef="produit">
      <th mat-header-cell *matHeaderCellDef> Produit </th>
      <td mat-cell *matCellDef="let element"> <a
          [routerLink]="['/',config.lienSpec.CATALOGUE, 'fiche',element.ref,element.url]">{{element.nom}} </a></td>

    </ng-container>



    <ng-container matColumnDef="puttc">
      <th mat-header-cell *matHeaderCellDef> P.U </th>
      <td mat-cell *matCellDef="let element"> {{element.prixTTC |currency}} </td>

    </ng-container>
    <ng-container matColumnDef="attributs">
      <th mat-header-cell *matHeaderCellDef> Choix </th>
      <td mat-cell *matCellDef="let element">
        <div *ngFor="let attribut of element.attributs">{{attribut.choix}}<span class="plusvalue"
            *ngIf="attribut.plusvalue>0">+ {{attribut.plusvalue |currency}}</span></div>
      </td>

    </ng-container>
    <ng-container matColumnDef="nbr">
      <th mat-header-cell *matHeaderCellDef> Nbr. </th>
      <td mat-cell *matCellDef="let element" fxLayoutAlign="center center" fxLayout="column-reverse"
        style="height:100%"><button mat-icon-button color="primary"
          [matTooltip]="'Retirer '+element.min+' '+element.unite+' de '+element.nom+' à votre commande'"
          aria-label="Retirer 1 exemplaire à votre commande" (click)="addPanier(element.id,-element.min,element.attributs)">
          <mat-icon>remove</mat-icon>
        </button>
        <div>{{element.nbr}} <span class="unite">({{element.unite}})</span></div><button mat-icon-button color="primary"
          [matTooltip]="'Ajouter '+element.min+' '+element.unite+' de '+element.nom+' à votre commande'"
          aria-label="Ajouter 1 exemplaire à votre commande" (click)="addPanier(element.id,element.min,element.attributs)"
          [disabled]="!(element.nbrHorsAttributs<element.stock)">
          <mat-icon>add</mat-icon>
        </button>
      </td>

    </ng-container>
    <ng-container matColumnDef="pttc">
      <th mat-header-cell *matHeaderCellDef> Total </th>
      <td mat-cell *matCellDef="let element"> {{element.prixTotalTTC |currency}} </td>

    </ng-container>
    <ng-container matColumnDef="disclaimer">
      <td mat-footer-cell *matFooterCellDef colspan="6" class="total">
        Total Commande : {{getTotalCost() | currency}}
      </td>
      <td mat-footer-cell *matFooterCellDef> </td>
    </ng-container>


    <tr mat-header-row *matHeaderRowDef="displayedColumns" class="monheader"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;" class="mestd"></tr>
    <tr mat-footer-row *matFooterRowDef="['disclaimer']"></tr>
  </table>
  <div class="actionPanier"><button mat-raised-button color="alert"
      [routerLink]="['/',config.lienSpec.CATALOGUE]">{{panier.produits.length?'':'Votre panier est vide, '}}Ajouter des produits</button>
      <button *ngIf="panier.produits.length" mat-raised-button
      color="primary" (click)="valid()">Passer ma commande</button> </div>


</div>