/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { ConfigBoutique } from '../models/config-boutique';
import { ConfigBoutiquePartial } from '../models/config-boutique-partial';
import { ConfigBoutiqueWithRelations } from '../models/config-boutique-with-relations';
import { NewConfigBoutique } from '../models/new-config-boutique';
import { Count as LoopbackCount } from '../models/loopback/count';

@Injectable({
  providedIn: 'root',
})
export class ConfigBoutiqueControllerService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation configBoutiqueControllerCount
   */
  static readonly ConfigBoutiqueControllerCountPath = '/config-boutiques/count';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `count()` instead.
   *
   * This method doesn't expect any request body.
   */
  count$Response(params?: {
    where?: any;
  }): Observable<StrictHttpResponse<LoopbackCount>> {

    const rb = new RequestBuilder(this.rootUrl, ConfigBoutiqueControllerService.ConfigBoutiqueControllerCountPath, 'get');
    if (params) {
      rb.query('where', params.where, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<LoopbackCount>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `count$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  count(params?: {
    where?: any;
  }): Observable<LoopbackCount> {

    return this.count$Response(params).pipe(
      map((r: StrictHttpResponse<LoopbackCount>) => r.body as LoopbackCount)
    );
  }

  /**
   * Path part for operation configBoutiqueControllerFindd4
   */
  static readonly ConfigBoutiqueControllerFindd4Path = '/config-boutiques/d4';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `findd4()` instead.
   *
   * This method doesn't expect any request body.
   */
  findd4$Response(params?: {
  }): Observable<StrictHttpResponse<ConfigBoutiqueWithRelations>> {

    const rb = new RequestBuilder(this.rootUrl, ConfigBoutiqueControllerService.ConfigBoutiqueControllerFindd4Path, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ConfigBoutiqueWithRelations>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `findd4$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  findd4(params?: {
  }): Observable<ConfigBoutiqueWithRelations> {

    return this.findd4$Response(params).pipe(
      map((r: StrictHttpResponse<ConfigBoutiqueWithRelations>) => r.body as ConfigBoutiqueWithRelations)
    );
  }

  /**
   * Path part for operation configBoutiqueControllerDeleteById
   */
  static readonly ConfigBoutiqueControllerDeleteByIdPath = '/config-boutiques/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteById()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteById$Response(params: {
    id: string;
  }): Observable<StrictHttpResponse<any>> {

    const rb = new RequestBuilder(this.rootUrl, ConfigBoutiqueControllerService.ConfigBoutiqueControllerDeleteByIdPath, 'delete');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<any>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `deleteById$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteById(params: {
    id: string;
  }): Observable<any> {

    return this.deleteById$Response(params).pipe(
      map((r: StrictHttpResponse<any>) => r.body as any)
    );
  }

  /**
   * Path part for operation configBoutiqueControllerUpdateById
   */
  static readonly ConfigBoutiqueControllerUpdateByIdPath = '/config-boutiques/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateById()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateById$Response(params: {
    id: string;
    body?: ConfigBoutiquePartial
  }): Observable<StrictHttpResponse<any>> {

    const rb = new RequestBuilder(this.rootUrl, ConfigBoutiqueControllerService.ConfigBoutiqueControllerUpdateByIdPath, 'patch');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<any>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `updateById$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateById(params: {
    id: string;
    body?: ConfigBoutiquePartial
  }): Observable<any> {

    return this.updateById$Response(params).pipe(
      map((r: StrictHttpResponse<any>) => r.body as any)
    );
  }

  /**
   * Path part for operation configBoutiqueControllerCreate
   */
  static readonly ConfigBoutiqueControllerCreatePath = '/config-boutiques';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `create()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  create$Response(params?: {
    body?: NewConfigBoutique
  }): Observable<StrictHttpResponse<ConfigBoutique>> {

    const rb = new RequestBuilder(this.rootUrl, ConfigBoutiqueControllerService.ConfigBoutiqueControllerCreatePath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ConfigBoutique>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `create$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  create(params?: {
    body?: NewConfigBoutique
  }): Observable<ConfigBoutique> {

    return this.create$Response(params).pipe(
      map((r: StrictHttpResponse<ConfigBoutique>) => r.body as ConfigBoutique)
    );
  }

  /**
   * Path part for operation configBoutiqueControllerFindStars
   */
  static readonly ConfigBoutiqueControllerFindStarsPath = '/stars';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `findStars()` instead.
   *
   * This method doesn't expect any request body.
   */
  findStars$Response(params?: {
  }): Observable<StrictHttpResponse<ConfigBoutiqueWithRelations>> {

    const rb = new RequestBuilder(this.rootUrl, ConfigBoutiqueControllerService.ConfigBoutiqueControllerFindStarsPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ConfigBoutiqueWithRelations>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `findStars$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  findStars(params?: {
  }): Observable<ConfigBoutiqueWithRelations> {

    return this.findStars$Response(params).pipe(
      map((r: StrictHttpResponse<ConfigBoutiqueWithRelations>) => r.body as ConfigBoutiqueWithRelations)
    );
  }

}
