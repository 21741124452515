<div class="cont">

  <h1>{{pageTitre}}</h1>
  <mat-card class="contformClient mat-elevation-z8">


    <mat-tab-group #moi class="tabFormClient" dynamicHeight>
      <mat-tab label="Je suis déjà client :">
        <form [formGroup]="loginForm" (ngSubmit)="login();">
          <mat-card class="form-signin">
            <mat-card-header>
              <mat-card-title style="font-size: 24px;">Vous êtes déjà client sur notre site ?<br>Identifiez vous avec
                votre adresse Email et votre mot de passe
              </mat-card-title>
              <mat-card-subtitle (click)="moi.selectedIndex=1" style="cursor: pointer;">Si vous n'avez pas encore de
                compte, cliquez ici pour en créer un</mat-card-subtitle>
            </mat-card-header>
            <mat-card-content>
              <p>
                <mat-form-field class="full-width" appearance="outline">
                  <mat-label>Adresse Email</mat-label>
                  <input matInput id="email" placeholder="Utilisateur" type="text" formControlName="email">
                  <mat-error *ngIf="loginForm.controls.email.invalid">{{getErrorMessage('email')}}</mat-error>
                </mat-form-field>
              </p>
              <p>
                <mat-form-field class="full-width" appearance="outline">
                  <mat-label>Mot de passe</mat-label>
                  <input matInput id="password" placeholder="Mot de passe" [type]="hide ? 'password' : 'text'"
                    formControlName="password" autocomplete="new-password">
                  <mat-icon matSuffix (click)="hide = !hide">{{hide ? 'visibility' : 'visibility_off'}}</mat-icon>
                  <mat-error *ngIf="loginForm.controls.password.invalid">{{getErrorMessage('password')}}</mat-error>
                </mat-form-field>
              </p>
            </mat-card-content>
            <mat-card-actions><button mat-raised-button color="primary" [disabled]="!loginForm.valid"
                type="submit">OK</button><button *ngIf="error" mat-raised-button color="warn" type="button"(click)="razmdp()">J'ai oublié mon mot de passe</button></mat-card-actions>
            <mat-error [hidden]="error == ''" style="text-align: center;">

              {{ error }}<br>
              

            </mat-error>
          </mat-card>

        </form>
      </mat-tab>
      <mat-tab label="Je suis nouveau client :">
        <form [formGroup]="signinForm" (ngSubmit)="newClient();">

          <mat-card class="form-signin">
            <mat-card-header>
              <mat-card-title>Vous êtes nouveau client sur le site</mat-card-title>
              <mat-card-subtitle>Saisissez vos informations ci-dessous pour créer un compte client.<br>Tous les champs
                marqués d'un * sont obligatoires</mat-card-subtitle>
            </mat-card-header>
            <mat-card-content>



              <mat-form-field class="full-width" appearance="outline">
                <mat-label>Nom</mat-label>
                <input matInput id="nom" placeholder="Nom" type="text" formControlName="nom" required>
                <mat-error *ngIf="signinForm.controls.nom.invalid">{{getErrorSigninMessage('nom')}}</mat-error>
              </mat-form-field>

              <mat-form-field class="full-width" appearance="outline">
                <mat-label>Prénom</mat-label>
                <input matInput id="prenom" placeholder="Prénom" type="text" formControlName="prenom" required>
                <mat-error *ngIf="signinForm.controls.prenom.invalid">{{getErrorSigninMessage('prenom')}}</mat-error>
              </mat-form-field>
              <mat-form-field class="full-width" appearance="outline">
                <mat-label>Adresse</mat-label>
                <input matInput id="adresse" placeholder="Adresse" type="text" formControlName="adresse" required>
                <mat-error *ngIf="signinForm.controls.adresse.invalid">{{getErrorSigninMessage('adresse')}}</mat-error>
              </mat-form-field>
              <mat-form-field class="full-width" appearance="outline">
                <mat-label>Complément d'Adresse</mat-label>
                <input matInput id="adresse2" placeholder="Complément d'Adresse" type="text" formControlName="adresse2">
                <mat-error *ngIf="signinForm.controls.adresse2.invalid">{{getErrorSigninMessage('adresse2')}}
                </mat-error>
              </mat-form-field>
              <mat-form-field class="full-width" appearance="outline">
                <mat-label>C.P.</mat-label>
                <input matInput id="cp" placeholder="C.P." type="text" formControlName="cp" required>
                <mat-error *ngIf="signinForm.controls.cp.invalid">{{getErrorSigninMessage('cp')}}</mat-error>
              </mat-form-field>
              <mat-form-field class="full-width" appearance="outline">
                <mat-label>Ville</mat-label>
                <input matInput id="ville" placeholder="Ville" type="text" formControlName="ville" required>
                <mat-error *ngIf="signinForm.controls.ville.invalid">{{getErrorSigninMessage('ville')}}</mat-error>
              </mat-form-field>
              <mat-form-field class="full-width" appearance="outline">
                <mat-label>Tél.</mat-label>
                <input matInput id="tel" placeholder="Tél." type="text" formControlName="tel" required>
                <mat-error *ngIf="signinForm.controls.tel.invalid">{{getErrorSigninMessage('tel')}}</mat-error>
              </mat-form-field>

              <mat-form-field class="full-width" appearance="outline">
                <mat-label>Adresse Email</mat-label>
                <input matInput id="emailsign" placeholder="Utilisateur" type="text" formControlName="emailsign"
                  required>
                <mat-error *ngIf="signinForm.controls.emailsign.invalid">{{getErrorSigninMessage('emailsign')}}
                </mat-error>
              </mat-form-field>
              <mat-form-field class="full-width" appearance="outline">
                <mat-label>Mot de passe</mat-label>
                <input matInput id="passwordsign" placeholder="Mot de passe" [type]="hide ? 'password' : 'text'"
                  formControlName="passwordsign" autocomplete="new-password" required>
                <mat-icon matSuffix (click)="hide = !hide">{{hide ? 'visibility' : 'visibility_off'}}</mat-icon>
                <mat-error *ngIf="signinForm.controls.passwordsign.invalid">{{getErrorSigninMessage('passwordsign')}}
                </mat-error>
              </mat-form-field>


              <mat-form-field class="full-width" appearance="outline">
                <mat-label>Confirmez le mot de passe</mat-label>
                <input matInput placeholder="Confirmez le mot de passe" [type]="hide ? 'password' : 'text'"
                  formControlName="password2" autocomplete="new-password" required>
                <mat-icon matSuffix (click)="hide = !hide">{{hide ? 'visibility' : 'visibility_off'}}</mat-icon>

                <mat-error *ngIf="signinForm.controls.password2.invalid">Les mots de passes ne correspondent pas.
                </mat-error>
              </mat-form-field>

              <mat-checkbox [formControl]="CGV">J'ai lu et j'approuve les <a href="./infos/mentions-legales" target="blank">conditions générales de ventes</a></mat-checkbox>
            </mat-card-content>
            <mat-card-actions>
              <button mat-raised-button color="primary" [disabled]="!(signinForm.valid) && !CGV.value"
                type="submit">Enregistrer</button>
            </mat-card-actions>
            <mat-error [hidden]="errorSignin == ''" style="text-align: center;">

              {{ errorSignin }}

            </mat-error>
          </mat-card>

        </form>

      </mat-tab>
    </mat-tab-group>
  </mat-card>
</div>