import { isPlatformBrowser } from '@angular/common';
import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { Observable } from 'rxjs';
@Injectable()
export class ApiInterceptor implements HttpInterceptor {
  testBrowser: boolean;
  constructor(@Inject(PLATFORM_ID) platformId: string) {
    this.testBrowser = isPlatformBrowser(platformId);
  }
  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    // Apply the headers
    if (this.testBrowser) {
      const TT = JSON.parse(localStorage.getItem('currentUser')!);
      const CT = JSON.parse(localStorage.getItem('clientUser')!);
      if (localStorage.getItem('currentUser') && TT.token) {
        localStorage.removeItem('clientUser');
        req = req.clone({
          setHeaders: {
            Authorization: 'Bearer ' + TT.token,
          },
          setParams: {
            cacheId: Date.now().toString(),
          },
        });
      } else if (localStorage.getItem('clientUser') && CT.token) {
        localStorage.removeItem('currentUser');
        req = req.clone({
          setHeaders: {
            Authorization: 'Bearer ' + CT.token,
          },
          setParams: {
            cacheId: Date.now().toString(),
          },
        });
      }
    }

    return next.handle(req);
  }
}
@Injectable()
export class CacheInterceptor implements HttpInterceptor {
  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    // Apply the headers
    req = req.clone({
      setParams: {
        cacheId: Date.now().toString(),
      },
    });
    return next.handle(req);
  }
}
