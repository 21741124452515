import { Component, OnInit } from '@angular/core';
import { Subscription, Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { MatDialog } from '@angular/material/dialog';
import { Router, ActivatedRoute } from '@angular/router';
import * as moment from 'moment';
import { Config, CONFIG } from '../../../../config';
import { ConfigPartial as ConfigSite } from 'src/api/models';
import { Article, Page } from 'src/api/models';
import { Meta, Title } from '@angular/platform-browser';
import {
  ArticlesControllerService,
  ConfigControllerService,
  PagesControllerService,
} from 'src/api/services';
@Component({
  selector: 'app-journal',
  templateUrl: './journal.component.html',
  styleUrls: ['./journal.component.scss', '../styles.scss'],
})
export class JournalComponent implements OnInit {
  page!: Page;
  config: Config = CONFIG;
  subPage!: Subscription;
  journal!: Article[];
  subJournal!: Subscription;
  displayedColumns!: string[];
  subConfig!: Subscription;
  paramsSub!: Subscription;
  nbrArticleSub!: Subscription;
  urlStr: any;
  configSite!: ConfigSite;
  items!: string[];
  nbrArticle: number = 0;
  nbrArticleParPage!: number;

  constructor(
    private http: HttpClient,
    private readonly meta: Meta,
    private titre: Title,
    public dialog: MatDialog,
    private router: Router,
    private route: ActivatedRoute,
    private configService: ConfigControllerService,
    private pageService: PagesControllerService,
    private pagesService: PagesControllerService,
    private articlService: ArticlesControllerService
  ) {
    moment.locale('fr');
  }

  ngOnInit() {
    if (this.subPage) {
      this.subPage.unsubscribe();
    }
    if (this.subConfig) {
      this.subConfig.unsubscribe();
    }
    this.nbrArticleParPage = this.config.limites.articleJournal;
    this.urlStr = 'BLOG';
    this.subConfig = this.configService.findd4().subscribe((config) => {
      this.configSite = config;
      this.subPage = this.pagesService
        .find({ filter: { filter: `{"where":{"type":"${this.urlStr}"}}` } })
        .subscribe(
          (pageData) => {
            console.log(pageData);
            this.page = pageData[0];
            //lazy-loading
            this.page.html = this.page.html
              ? this.page.html
                  .replace(/<img /gi, '<img class="lazyload" ')
                  .replace(/(<img[^>]*)src=/gi, '$1data-src=')
              : '';
            // metas
            const Tags = [
              "name='description'",
              "property= 'og:url'",
              "property='og:title'",
              "property='og:description'",
              "property='og:image'",
              "property='og:locale'",
              "property='og:type'",
              "property='og:site_name'",
              "name='twitter:card'",
              "name='twitter:description'",
              "name='twitter:title'",
              "name='twitter:image'",
            ];
            Tags.map((e) => this.meta.removeTag(e));
            const title = this.page.titre + ' - ' + this.configSite.title!;
            const description = this.page.description || '';
            this.titre.setTitle(title);
            let ogimage = this.page.ogimage || this.config.imageUrl || '';
            this.meta.addTags(
              [
                { name: 'description', content: description },
                {
                  property: 'og:url',
                  content: this.config.applicationUrl + '/page/' + this.urlStr,
                },
                { property: 'og:title', content: title },
                { property: 'og:description', content: description },
                { property: 'og:image', content: ogimage },
                { property: 'og:locale', content: 'fr_FR' },
                { property: 'og:type', content: 'website' },
                {
                  property: 'og:site_name',
                  content: this.configSite.title!,
                },
                { name: 'twitter:card', content: 'summary' },
                { name: 'twitter:description', content: description },
                { name: 'twitter:title', content: title },
                { name: 'twitter:image', content: ogimage },
              ],
              true
            );
            this.nbrArticleSub = this.articlService.count().subscribe((nbr) => {
              this.nbrArticle = nbr.count || 0;
            });
            this.plusArticle();
          },
          (err) => {}
        );
    });
  }
  plusArticle() {
    if (this.subJournal) {
      this.subJournal.unsubscribe();
    }
    const limit = this.nbrArticleParPage;
    const skip = 0;
    const limitStr = `,"skip":${skip},"limit":${limit}`;
    const maintenant = moment().utc().format();
    this.subJournal = this.articlService
      .find({
        filter: {
          filter: `{"order": "parution DESC" ${limitStr},"where":{"and":[{"parution":{"lte": "${maintenant}"}},{"archive":"false"}]}}`,
        },
      })
      .subscribe((articles) => {
        this.journal = articles;
        this.nbrArticleParPage += this.nbrArticleParPage;
      });
  }
  ngOnDestroy() {
    if (this.subPage) {
      this.subPage.unsubscribe();
    }
    if (this.subJournal) {
      this.subJournal.unsubscribe();
    }
    if (this.paramsSub) {
      this.paramsSub.unsubscribe();
    }
    if (this.subConfig) {
      this.subConfig.unsubscribe();
    }
    if (this.nbrArticleSub) {
      this.nbrArticleSub.unsubscribe();
    }
  }
}
