<div class="cont">

    <h1>{{pageTitre}}</h1>
    
        
          <form *ngIf="ok" [formGroup]="signinForm" (ngSubmit)="modClient();">
  
            <mat-card class="form-signin">
              <mat-card-header>
                <mat-card-title>Modifier vos informations</mat-card-title>
                <mat-card-subtitle>Saisissez vos informations ci-dessous pour modifier mon compte client.<br>Tous les champs
                  marqués d'un * sont obligatoires</mat-card-subtitle>
              </mat-card-header>
              <mat-card-content>
  
  
  
                <mat-form-field class="full-width" appearance="outline">
                  <mat-label>Nom</mat-label>
                  <input matInput id="nom" placeholder="Nom" type="text" formControlName="nom" required>
                  <mat-error *ngIf="signinForm.controls.nom.invalid">{{getErrorSigninMessage('nom')}}</mat-error>
                </mat-form-field>
  
                <mat-form-field class="full-width" appearance="outline">
                  <mat-label>Prénom</mat-label>
                  <input matInput id="prenom" placeholder="Prénom" type="text" formControlName="prenom" required>
                  <mat-error *ngIf="signinForm.controls.prenom.invalid">{{getErrorSigninMessage('prenom')}}</mat-error>
                </mat-form-field>
                <mat-form-field class="full-width" appearance="outline">
                  <mat-label>Adresse</mat-label>
                  <input matInput id="adresse" placeholder="Adresse" type="text" formControlName="adresse" required>
                  <mat-error *ngIf="signinForm.controls.adresse.invalid">{{getErrorSigninMessage('adresse')}}</mat-error>
                </mat-form-field>
                <mat-form-field class="full-width" appearance="outline">
                  <mat-label>Complément d'Adresse</mat-label>
                  <input matInput id="adresse2" placeholder="Complément d'Adresse" type="text" formControlName="adresse2">
                  <mat-error *ngIf="signinForm.controls.adresse2.invalid">{{getErrorSigninMessage('adresse2')}}
                  </mat-error>
                </mat-form-field>
                <mat-form-field class="full-width" appearance="outline">
                  <mat-label>C.P.</mat-label>
                  <input matInput id="cp" placeholder="C.P." type="number" formControlName="cp" required>
                  <mat-error *ngIf="signinForm.controls.cp.invalid">{{getErrorSigninMessage('cp')}}</mat-error>
                </mat-form-field>
                <mat-form-field class="full-width" appearance="outline">
                  <mat-label>Ville</mat-label>
                  <input matInput id="ville" placeholder="Ville" type="text" formControlName="ville" required>
                  <mat-error *ngIf="signinForm.controls.ville.invalid">{{getErrorSigninMessage('ville')}}</mat-error>
                </mat-form-field>
                <mat-form-field class="full-width" appearance="outline">
                  <mat-label>Tél.</mat-label>
                  <input matInput id="tel" placeholder="Tél." type="text" formControlName="tel" required>
                  <mat-error *ngIf="signinForm.controls.tel.invalid">{{getErrorSigninMessage('tel')}}</mat-error>
                </mat-form-field>
  
                <mat-form-field class="full-width" appearance="outline">
                  <mat-label>Adresse Email</mat-label>
                  <input matInput id="emailsign" placeholder="Utilisateur" type="text" formControlName="emailsign"
                    required>
                  <mat-error *ngIf="signinForm.controls.emailsign.invalid">{{getErrorSigninMessage('emailsign')}}
                  </mat-error>
                </mat-form-field>
                
  
  
              </mat-card-content>
              <mat-card-actions>
                <button mat-raised-button color="primary" [disabled]="!signinForm.valid"
                  type="submit">Enregistrer les modifications</button>
              </mat-card-actions>
              <mat-error [hidden]="errorSignin == ''" style="text-align: center;">
  
                {{ errorSignin }}
  
              </mat-error>
            </mat-card>
  
          </form>
  
        
  </div>