import {
  Component,
  OnInit,
  OnDestroy,
  Inject,
  PLATFORM_ID,
  SecurityContext,
} from '@angular/core';
import { Config, CONFIG } from '../../../config';
import { Page } from 'src/api/models';
import { Subscription, Observable } from 'rxjs';

import { ConfigPartial as ConfigSite } from 'src/api/models';
import { Router, ActivatedRoute } from '@angular/router';

/* import {
  AdvancedLayout,
  Image,
  PlainGalleryConfig,
  PlainGalleryStrategy,
} from '@ks89/angular-modal-gallery'; */
import { DomSanitizer, Meta, Title } from '@angular/platform-browser';
import { isPlatformBrowser } from '@angular/common';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map } from 'rxjs/operators';

import {
  PagesControllerService,
  ConfigControllerService,
  MailControllerService,
} from 'src/api/services';

@Component({
  selector: 'app-page-item-view',
  templateUrl: './page-item-view.component.html',
  styleUrls: ['styles.scss'],
})
export class PageItemViewComponent implements OnInit, OnDestroy {
  page!: Page;
  config: Config = CONFIG;
  subPage!: Subscription;
  public configSite!: ConfigSite;
  subConfig!: Subscription;
  paramsSub!: Subscription;
  urlStr: any;
  testBrowser: boolean;
  smSubscribe!: Subscription;
  mailForm!: FormGroup;
  /* images: Image[]=[];
  customPlainGalleryRowConfig: PlainGalleryConfig = {
    strategy: PlainGalleryStrategy.CUSTOM,
    layout: new AdvancedLayout(-1, true)
  }; */
  /* openImageModalRow(image: Image) {
    // console.log('Opening modal gallery from custom plain gallery row and description, with image: ', image);
    const index: number = this.getCurrentIndexCustomLayout(image, this.images);
    this.customPlainGalleryRowConfig = Object.assign({},
      this.customPlainGalleryRowConfig,
      { layout: new AdvancedLayout(index, true) });
  } 
  private getCurrentIndexCustomLayout(image: Image, images: Image[]): number {
    return image ? images.indexOf(image) : -1;
  }*/
  constructor(
    @Inject(PLATFORM_ID) platformId: string,
    private meta: Meta,
    private titre: Title,
    private router: Router,
    private route: ActivatedRoute,
    private http: HttpClient,
    private sanitizer: DomSanitizer,
    private formBuilder: FormBuilder,
    private pagesService: PagesControllerService,
    private configService: ConfigControllerService,
    private mailService: MailControllerService
  ) {
    this.testBrowser = isPlatformBrowser(platformId);
  }

  ngOnInit() {
    const emailRegex = `([a-zA-Z0-9_.]{1}[a-zA-Z0-9_.]*)((@[a-zA-Z]{2}[a-zA-Z]*)[\\\.]([a-zA-Z]{2}|[a-zA-Z]{3}))`;
    const messageRegex = `(^[a-zA-Z0-9áàâäãåçéèêëíìîïñóòôöõúùûüýÿæœÁÀÂÄÃÅÇÉÈÊËÍÌÎÏÑÓÒÔÖÕÚÙÛÜÝŸÆŒ +/.,//#!?$%/^/*;:{}=_~()/"/'\r\n-]+$)`;
    this.mailForm = this.formBuilder.group({
      mail: [
        '',
        Validators.compose([
          Validators.required,
          Validators.pattern(emailRegex),
        ]),
      ],
      message: [
        '',
        Validators.compose([
          Validators.required,
          Validators.pattern(messageRegex),
        ]),
      ],
    });

    if (this.subPage) {
      this.subPage.unsubscribe();
    }
    if (this.subConfig) {
      this.subConfig.unsubscribe();
    }
    this.paramsSub = this.route.params
      .pipe(map((params) => params['url']))
      .subscribe((url) => {
        this.urlStr = url;
        this.subConfig = this.configService.findd4().subscribe((config) => {
          this.configSite = config;
          this.subPage = this.pagesService
            .find({ filter: { filter: `{"where":{"url":"${url}"}}` } })
            .subscribe(
              (page) => {
                this.page = page[0];
                //lazy-loading
                this.page.html = this.page.html
                  ? this.page.html
                      .replace(/<img /gi, '<img class="lazyload" ')
                      .replace(/(<img[^>]*) src=/gi, '$1data-src=')
                  : '';
                // galerie
                /* this.images = [];
                this.page.images.map(im => {
                  this.images = [...this.images, new Image(this.images.length - 1 + 1, { img: '/media/images/' + im, },
                    { img: '/media/images/_med.' + im })];
                }); */
                // metas
                const Tags = [
                  "name='description'",
                  "property= 'og:url'",
                  "property='og:title'",
                  "property='og:description'",
                  "property='og:image'",
                  "property='og:locale'",
                  "property='og:type'",
                  "property='og:site_name'",
                  "name='twitter:card'",
                  "name='twitter:description'",
                  "name='twitter:title'",
                  "name='twitter:image'",
                ];
                Tags.map((e) => this.meta.removeTag(e));
                const title = this.page.titre + ' - ' + this.configSite.title!;
                const description = this.page.description || '';
                this.titre.setTitle(title);
                let ogimage = this.page.ogimage || this.config.imageUrl || '';
                this.meta.addTags(
                  [
                    { name: 'description', content: description },
                    {
                      property: 'og:url',
                      content:
                        this.config.applicationUrl + '/page/' + this.urlStr,
                    },
                    { property: 'og:title', content: title },
                    { property: 'og:description', content: description },
                    { property: 'og:image', content: ogimage },
                    { property: 'og:locale', content: 'fr_FR' },
                    { property: 'og:type', content: 'website' },
                    {
                      property: 'og:site_name',
                      content: this.configSite.title!,
                    },
                    { name: 'twitter:card', content: 'summary' },
                    { name: 'twitter:description', content: description },
                    { name: 'twitter:title', content: title },
                    { name: 'twitter:image', content: ogimage },
                  ],
                  true
                );
              },
              (err) => {}
            );
        });
      });
  }
  ngOnDestroy(): void {
    if (this.subPage) {
      this.subPage.unsubscribe();
    }
    if (this.subConfig) {
      this.subConfig.unsubscribe();
    }
  }

  envoiMail() {
    if (this.mailForm.valid) {
      const formvalue: any = {
        mail:  'noreply@server.digitalatelier.fr',//this.mailForm.value.mail,
        subject: 'Contact depuis votre site',
        message:
          'ATTENTION, NE PAS REPONDRE À CET EMAIL, CRÉEZ UN NOUVEAU MESSAGE !<br><br><b>Email saisi :</b> <br>' +
          this.mailForm.value.mail +
          ' <br><b>Message :</b> <br>' +
          this.mailForm.value.message
            .replace(/<[^>]+>/gi, '')
            .replace(/(\r\n|\n\r|\r|\n)/gm, '<br>'),
        /*  message: 'message=ATTENTION, NE PAS REPONDRE À CET EMAIL, CRÉEZ UN NOUVEAU MESSAGE ! \n\n Email saisi : \n'
          + this.mailForm.value.mail
          + ' \n\nMessage : \n'
          + this.sanitizer.sanitize(SecurityContext.HTML, this.mailForm.value.message) */
      }; // voir sanitize loopback
      this.smSubscribe = this.sendMail(formvalue).subscribe(
        (data) => {
          alert('Votre message a bien été envoyé !');
          this.mailForm.reset();
        },
        (er) => {
          alert(
            "Votre message n'a pas été envoyé ! Veuillez essayer de nouveau dans quelques instant."
          );
          this.mailForm.reset();
        }
      );
    }
  }
  sendMail(data: any): Observable<any> {
    return this.mailService
      .mail({
        body: {
          from: "server@digitalatelier.fr",
          to: this.configSite.email!,
          html: data.message,
          subject: data.subject,
        },
      })
      .pipe(map((res) => true));
    /* const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/x-www-form-urlencoded',
      }),
    };
    const url = this.config.urlApi + '/sm';
    return this.http
      .post(
        url,
        data.mail + '&' + data.subject + '&' + data.message,
        httpOptions
      )
      .pipe(map((res) => res));*/
  }
}
