/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { User } from '../models/user';

@Injectable({
  providedIn: 'root',
})
export class UserControllerService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation userControllerLogin
   */
  static readonly UserControllerLoginPath = '/users/login';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `login()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  login$Response(params: {

    /**
     * User functions
     */
    body: { 'email': string, 'password': string }
  }): Observable<StrictHttpResponse<{ 'token'?: string, 'userProfile'?: { 'role'?: string, 'userName'?: string, 'email'?: string } }>> {

    const rb = new RequestBuilder(this.rootUrl, UserControllerService.UserControllerLoginPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{ 'token'?: string, 'userProfile'?: { 'role'?: string, 'userName'?: string, 'email'?: string } }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `login$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  login(params: {

    /**
     * User functions
     */
    body: { 'email': string, 'password': string }
  }): Observable<{ 'token'?: string, 'userProfile'?: { 'role'?: string, 'userName'?: string, 'email'?: string } }> {

    return this.login$Response(params).pipe(
      map((r: StrictHttpResponse<{ 'token'?: string, 'userProfile'?: { 'role'?: string, 'userName'?: string, 'email'?: string } }>) => r.body as { 'token'?: string, 'userProfile'?: { 'role'?: string, 'userName'?: string, 'email'?: string } })
    );
  }

  /**
   * Path part for operation userControllerPrintCurrentUser
   */
  static readonly UserControllerPrintCurrentUserPath = '/users/moi';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `printCurrentUser()` instead.
   *
   * This method doesn't expect any request body.
   */
  printCurrentUser$Response(params?: {
  }): Observable<StrictHttpResponse<{ 'id': string, 'email'?: string, 'userName'?: string, 'role'?: string }>> {

    const rb = new RequestBuilder(this.rootUrl, UserControllerService.UserControllerPrintCurrentUserPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{ 'id': string, 'email'?: string, 'userName'?: string, 'role'?: string }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `printCurrentUser$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  printCurrentUser(params?: {
  }): Observable<{ 'id': string, 'email'?: string, 'userName'?: string, 'role'?: string }> {

    return this.printCurrentUser$Response(params).pipe(
      map((r: StrictHttpResponse<{ 'id': string, 'email'?: string, 'userName'?: string, 'role'?: string }>) => r.body as { 'id': string, 'email'?: string, 'userName'?: string, 'role'?: string })
    );
  }

  /**
   * Path part for operation userControllerPrintCurrentRoleUser
   */
  static readonly UserControllerPrintCurrentRoleUserPath = '/users/role';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `printCurrentRoleUser()` instead.
   *
   * This method doesn't expect any request body.
   */
  printCurrentRoleUser$Response(params?: {
  }): Observable<StrictHttpResponse<{ 'role'?: string }>> {

    const rb = new RequestBuilder(this.rootUrl, UserControllerService.UserControllerPrintCurrentRoleUserPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{ 'role'?: string }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `printCurrentRoleUser$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  printCurrentRoleUser(params?: {
  }): Observable<{ 'role'?: string }> {

    return this.printCurrentRoleUser$Response(params).pipe(
      map((r: StrictHttpResponse<{ 'role'?: string }>) => r.body as { 'role'?: string })
    );
  }

  /**
   * Path part for operation userControllerCreateAdmin
   */
  static readonly UserControllerCreateAdminPath = '/users/sign-up/admin';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createAdmin()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createAdmin$Response(params: {

    /**
     * User + Role functions
     */
    body: { 'email': string, 'password': string, 'role'?: 'user' | 'admin' | 'd4' }
  }): Observable<StrictHttpResponse<User>> {

    const rb = new RequestBuilder(this.rootUrl, UserControllerService.UserControllerCreateAdminPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<User>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `createAdmin$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createAdmin(params: {

    /**
     * User + Role functions
     */
    body: { 'email': string, 'password': string, 'role'?: 'user' | 'admin' | 'd4' }
  }): Observable<User> {

    return this.createAdmin$Response(params).pipe(
      map((r: StrictHttpResponse<User>) => r.body as User)
    );
  }

  /**
   * Path part for operation userControllerCreate
   */
  static readonly UserControllerCreatePath = '/users/sign-up';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `create()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  create$Response(params: {

    /**
     * User + Role functions
     */
    body: { 'email': string, 'password': string, 'role'?: 'user' | 'admin' | 'd4' }
  }): Observable<StrictHttpResponse<User>> {

    const rb = new RequestBuilder(this.rootUrl, UserControllerService.UserControllerCreatePath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<User>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `create$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  create(params: {

    /**
     * User + Role functions
     */
    body: { 'email': string, 'password': string, 'role'?: 'user' | 'admin' | 'd4' }
  }): Observable<User> {

    return this.create$Response(params).pipe(
      map((r: StrictHttpResponse<User>) => r.body as User)
    );
  }

  /**
   * Path part for operation userControllerDeleteById
   */
  static readonly UserControllerDeleteByIdPath = '/users/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteById()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteById$Response(params: {
    id: string;
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, UserControllerService.UserControllerDeleteByIdPath, 'delete');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `deleteById$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteById(params: {
    id: string;
  }): Observable<void> {

    return this.deleteById$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation userControllerUpdateById
   */
  static readonly UserControllerUpdateByIdPath = '/users/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateById()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateById$Response(params: {
    id: string;

    /**
     * User + Role functions
     */
    body: { 'email': string, 'password': string, 'role'?: 'user' | 'admin' | 'd4' }
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, UserControllerService.UserControllerUpdateByIdPath, 'patch');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `updateById$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateById(params: {
    id: string;

    /**
     * User + Role functions
     */
    body: { 'email': string, 'password': string, 'role'?: 'user' | 'admin' | 'd4' }
  }): Observable<void> {

    return this.updateById$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation userControllerFindById
   */
  static readonly UserControllerFindByIdPath = '/users/{userId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `findById()` instead.
   *
   * This method doesn't expect any request body.
   */
  findById$Response(params: {
    userId: string;
  }): Observable<StrictHttpResponse<User>> {

    const rb = new RequestBuilder(this.rootUrl, UserControllerService.UserControllerFindByIdPath, 'get');
    if (params) {
      rb.path('userId', params.userId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<User>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `findById$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  findById(params: {
    userId: string;
  }): Observable<User> {

    return this.findById$Response(params).pipe(
      map((r: StrictHttpResponse<User>) => r.body as User)
    );
  }

  /**
   * Path part for operation userControllerFind
   */
  static readonly UserControllerFindPath = '/users';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `find()` instead.
   *
   * This method doesn't expect any request body.
   */
  find$Response(params?: {
    filter?: any;
  }): Observable<StrictHttpResponse<Array<User>>> {

    const rb = new RequestBuilder(this.rootUrl, UserControllerService.UserControllerFindPath, 'get');
    if (params) {
      rb.query('filter', params.filter, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<User>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `find$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  find(params?: {
    filter?: any;
  }): Observable<Array<User>> {

    return this.find$Response(params).pipe(
      map((r: StrictHttpResponse<Array<User>>) => r.body as Array<User>)
    );
  }

}
